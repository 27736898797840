export default ({ $axios, app, store }, inject) => {
  if (process.client) {
    // Ensures there is active currency set on client side
    $axios.defaults.headers.common['locale'] = app.i18n.locale
    $axios.defaults.headers.common['x-inspishop-default-locale'] = app.$env.DEFAULT_LOCALE

    // Ensures there is proper domain set on client
    $axios.defaults.headers.common['x-inspishop-domain'] = app.$env.DOMAIN_CODE

    // Ensure there is proper translation variant on client
    $axios.defaults.headers.common['x-inspishop-translation-variant'] = store.state.locale.translationVariant || ''
  }

  // Inject category locale path
  inject('categoryLocalePath', translatedSlug => {
    return app.localePath({
      name: 'all',
      params: [translatedSlug],
    })
  })
}
