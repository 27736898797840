<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    buildStyles(image) {
      // get styles for offsetX offsetY positionX positionY
      const x = image.positionX.includes('right') ? `right:${image.offsetX}px;` : `left:${image.offsetX}px;`
      const y = `top:${image.offsetY}px;`
      // return style string
      return x + y
    },
    buildDividerStyles(divider, block) {
      const height = divider.height > 0 ? `height:${divider.height}px;` : 'height:200px;'
      const width = divider.width > 0 ? `width:${divider.width}px;` : 'width:400px;'
      const rotation =
        divider.rotation !== 0
          ? `transform:rotate(${divider.rotation}deg) translate(0px,${-20 + block.padding_top * -1}px);`
          : `translate(0px,${-20 + block.padding_top * -1}px);`
      return height + width + rotation
    },
    getBlockStyles(block) {
      let bgImage = block.images.find(image => image.isWallpaper)
      if (bgImage && bgImage.image && bgImage.image.length > 0) {
        bgImage = `background-image:url("https://cdn.nobilis.cz/image/original/${
          bgImage.image[0].path
        }");background-repeat:repeat;`
      } else {
        bgImage = ''
      }
      const bgColor = `background-color: ${
        block.background_color && block.background_color.length > 0 ? block.background_color : '#fff'
      };`
      const paddingTop = block.padding_top && block.padding_top > 0 ? `padding-top: ${block.padding_top}px;` : ''
      const paddingBottom =
        block.padding_bottom && block.padding_bottom > 0 ? `padding-bottom: ${block.padding_bottom}px;` : ''
      return paddingBottom + paddingTop + bgColor + bgImage
    },
  },
}
</script>
