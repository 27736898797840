import { errorFromResponse } from '~/app/notifications'
import _ from 'lodash'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

export const actions = {
  async FETCH_USER({ commit, state, dispatch }, { forceReload = false }) {
    if (!state.isLoaded || forceReload) {
      commit('SET_LOADING', true)
      try {
        const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/user', {})
        commit('SET_USER_DATA', response.data)
        this.$bugsnag.setUser(
          state.userData.id,
          state.userData.email,
          [state.userData.firstName, state.userData.lastName].join(' '),
        )
        eventBus.$emit(eventDefinitions.USER.LOADED, { user: state.userData })
        await dispatch('auth/SET_VARIANT', state.userData.variantHash, { root: true })
        await dispatch('auth/SET_USER_GROUPS', state.userData.groups, { root: true })
        dispatch('FETCH_WISHLIST')
        dispatch('FETCH_USER_SHOPPING_LISTS')
      } catch (e) {
        if (e.response && e.response.status === 401) {
          dispatch('auth/REMOVE_AUTH', null, { root: true })
          dispatch('auth/SET_AXIOS_TOKEN', null, { root: true })
        } else {
          console.error(e)
        }
      }
      commit('SET_LOADING', false)
    }
  },
  async FETCH_USER_CREDITS({ commit, rootState }) {
    if (rootState.globals.settings.functions.loyalty_credits) {
      try {
        const url = `${this.$env.STORE_URL}/api/v1/user/credits/amount`
        const response = await this.$axios.$get(url)
        commit('SET_USER_CREDITS', response.data.amount)
      } catch (e) {
        console.error(e)
      }
    } else {
      commit('SET_USER_CREDITS', 0)
    }
  },
  RESET_USER({ commit }) {
    commit('RESET_USER')
  },
  async LOAD_USER_FROM_ARES({ commit, dispatch }, { tin }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/ares/ico`
      const response = await this.$axios.$get(url, {
        params: {
          ico: tin,
        },
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  async REQUEST_NEW_PASSWORD({ commit, dispatch }, { email, callbackUrl }) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/forgot-password', {
        callbackUrl: callbackUrl,
        email: email,
      })
      return response
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async SET_NEW_PASSWORD({ commit, dispatch }, { old_password, password, password_confirmation }) {
    try {
      const response = await this.$axios.$patch(this.$env.STORE_URL + '/api/v1/user/password', {
        old_password: old_password,
        password: password,
        password_confirmation: password_confirmation,
      })
      return response
    } catch (e) {
      return e.response
    }
  },
  async RESET_PASSWORD({ commit, dispatch }, { token, email, password, password_confirmation }) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/reset-password', {
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        token: token,
      })
      return response
    } catch (e) {
      return e.response
    }
  },
  async REGISTER({ commit, dispatch }, data) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/register', data)
      eventBus.$emit(eventDefinitions.USER.REGISTERED, { user: response.data })
      return response
    } catch (e) {
      return e.response
    }
  },
  async UPDATE_USER({ commit, dispatch }, data) {
    try {
      const response = await this.$axios.$patch(this.$env.STORE_URL + '/api/v1/user', data)
      commit('SET_USER_DATA', response.data)
      return response
    } catch (e) {
      return e.response
    }
  },
  async LOAD_USER_SHOPPING_LISTS({ dispatch, rootState }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists`
      const response = await this.$axios.$get(url)
      const shoppingListsData = await Promise.allValues(
        response.data.map(shoppingList => dispatch('cart/UPDATE_DATA_WITH_VARIANTS', shoppingList, { root: true })),
      )
      let shoppingLists = _.values(shoppingListsData)
      if (!this.$contentSettings.functions.wishList && rootState.user.wishList) {
        shoppingLists = shoppingLists.filter(shoppingList => rootState.user.wishList.id !== shoppingList.id)
      }
      shoppingLists.forEach((shoppingList, index) => {
        shoppingLists[index].items = _.orderBy(shoppingList.items, 'product')
      })
      return shoppingLists
    } catch (e) {
      errorFromResponse(e.response)
      return []
    }
  },
  async FETCH_USER_SHOPPING_LISTS({ commit, dispatch }) {
    const shoppingLists = await dispatch('LOAD_USER_SHOPPING_LISTS')
    commit('SET_FIELD', { field: 'shoppingLists', value: shoppingLists })
  },
  async LOAD_USER_SHOPPING_LIST({ commit, dispatch }, { id, code, fetchProductData = true }) {
    try {
      let url
      if (code) {
        url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists/code/${code}`
      } else {
        url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists/${id}`
      }
      const response = await this.$axios.$get(url)
      let shoppingList = response.data
      if (fetchProductData) {
        shoppingList = await dispatch('cart/UPDATE_DATA_WITH_VARIANTS', response.data, { root: true })
        shoppingList.items = _.orderBy(shoppingList.items, 'product')
      }
      return shoppingList
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async DELETE_USER_SHOPPING_LIST({ commit }, id) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists/${id}`
      const response = await this.$axios.$delete(url)
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async ADD_ITEM_TO_SHOPPING_LIST({ state, commit }, { id, variant, custom = {} }) {
    try {
      const listId = id >= 0 ? id : (state.wishList || {}).id >= 0 ? state.wishList.id : 'code/wishlist' // Use 'code/wishlist' as fallback because first list not created yet
      const url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists/${listId}/items`
      const response = await this.$axios.$post(url, {
        id: variant.id,
        amount: 1,
        custom,
      })
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async REMOVE_ITEM_FROM_SHOPPING_LIST({ state, dispatch }, { id, shoppingListItem }) {
    try {
      const listId = id >= 0 ? id : state.wishList.id
      const url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists/${listId}/items/${shoppingListItem.id}`
      await this.$axios.$delete(url)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async FETCH_WISHLIST({ commit, dispatch }) {
    const wishList = await dispatch('LOAD_USER_SHOPPING_LIST', {
      code: 'wishlist',
      fetchProductData: this.$contentSettings.functions.favourites,
    })

    if (wishList) {
      wishList.variantIds = wishList.items.map(item => item.variantId)
      commit('SET_FIELD', { field: 'wishList', value: wishList })
    }
  },
  async LOAD_USER_ORDERS({ commit, dispatch }, { page, perPage }) {
    try {
      let response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/user/orders', {
        params: { page, perPage },
      })
      const orders = await Promise.allValues(
        response.data.map(order => dispatch('cart/UPDATE_DATA_WITH_VARIANTS', order, { root: true })),
      )
      return { orders: _.values(orders), total: response.meta.pagination.total }
    } catch (e) {
      console.log(e)
    }
  },
  async LOAD_USER_ORDER({ commit, dispatch }, { code, id }) {
    try {
      let response
      if (code) {
        try {
          response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/user/orders/code/' + code)
        } catch (e) {}
        if (!response) {
          response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/user/orders/' + id)
        }
      }
      return await dispatch('cart/UPDATE_DATA_WITH_VARIANTS', response.data, { root: true })
    } catch (e) {}
  },
  async SUBSCRIBE_NEWSLETTER({ commit, dispatch }, { email, callbackUrl, source }) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/newsletter/subscribe', {
        email,
        callbackUrl,
        ...(source && { source: source }),
        terms: true,
      })
      return response
    } catch (e) {
      return e.response
    }
  },
  async CONTACT_FORM({ commit, dispatch }, data) {
    try {
      return await this.$axios.$post(this.$env.STORE_URL + '/api/v1/contact-form', data)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async FETCH_USER_ADDRESSES({}, { type, country_code }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/addresses`
      const response = await this.$axios.$get(url, {
        params: {
          type: type,
          country_code: country_code,
        },
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  async SAVE_USER_ADDRESS({}, data) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/addresses`
      return await this.$axios.$post(url, data)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async UPDATE_USER_ADDRESS({}, { data, id }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/addresses/${id}`
      return await this.$axios.$patch(url, data)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async DELETE_USER_ADDRESS({}, id) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/addresses/${id}`
      return await this.$axios.$delete(url)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async GET_USER_ORDER_CSV({}, { id }) {
    try {
      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/user/orders/${id}/items/export`, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response], { type: 'text/csv' }) // Create blob object with data from the response
      const data = window.URL.createObjectURL(blob) // Create DOMString representing created blob object
      return data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async GET_USER_ORDER_IMAGES({}, { id }) {
    try {
      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/user/orders/${id}/items/images/export`, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response], { type: 'application/octet-stream' }) // Create blob object with data from the response
      const data = window.URL.createObjectURL(blob) // Create DOMString representing created blob object
      return data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
}

export const state = () => ({
  sessionUuid: null,
  shoppingLists: null,
  isLoaded: false,
  isLoading: false,
  userFetched: false,
  userCredits: 0,
  userCreditsLoaded: false,
  userData: null,
  wishList: null,
})

export const mutations = {
  FETCH_SESSION_UUID(state) {
    if (!state.sessionUuid) {
      state.sessionUuid = (
        Math.random()
          .toString(16)
          .substr(2) +
        Math.random()
          .toString(16)
          .substr(2)
      ).substr(0, 24)
    }
  },
  RESET_USER(state) {
    state.userData = null
    state.isLoaded = false
  },
  SET_FIELD(state, { field, value }) {
    state[field] = value
  },
  SET_USER_DATA(state, data) {
    if (data) {
      state.userData = data
      state.isLoaded = true
    }
  },
  SET_USER_CREDITS(state, data) {
    if (data) {
      state.userCredits = data
    } else {
      state.userCredits = 0
    }
    state.userCreditsLoaded = true
  },
  SET_LOADING(state, data) {
    state.isLoading = data
  },
  SET_USER_FETCHED(state, data) {
    state.userFetched = data
  },
}

export const getters = {
  userIsLoggedIn: state => {
    return state.isLoaded && state.userData && Object.keys(state.userData).length > 0
  },
}
