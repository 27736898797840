import jsCookie from 'js-cookie'

export const actions = {
  async CHANGE_CURRENCY({ commit, state }, currency) {
    jsCookie.set('X-Inspishop-Currency', currency.code)
    location.reload(true)
  },
  CHANGE_DISPLAYED_VAT({ commit, state }, vat) {
    jsCookie.set('X-Inspishop-DisplayVAT', vat)
    commit('SET_DISPLAY_VAT', vat)
  },
  CHANGE_DISPLAY_BOTH_PRICES({ commit, state }, prices) {
    jsCookie.set('X-Inspishop-DisplayBothPrices', prices)
    commit('SET_DISPLAY_BOTH_PRICES', prices)
  },
  async FETCH_CURRENCIES({ commit, state }) {
    try {
      if (!state.currencies.length) {
        commit('SET_CURRENCIES_LOADING', true)
        const request = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/currencies')
        commit('SET_CURRENCIES', request.data)
        commit('SET_CURRENCIES_LOADING', false)
      }
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  activeCurrency: null,
  currencies: [],
  currenciesLoading: false,
  displayVAT: false,
  displayBothPrices: true,
})

export const mutations = {
  SET_ACTIVE_CURRENCY(state, data) {
    state.activeCurrency = data
  },
  SET_DISPLAY_VAT(state, data) {
    state.displayVAT = data
  },
  SET_DISPLAY_BOTH_PRICES(state, data) {
    state.displayBothPrices = data
  },
  SET_CURRENCIES(state, data) {
    state.currencies = data
  },
  SET_CURRENCIES_LOADING(state, data) {
    state.currenciesLoading = data
  },
}
