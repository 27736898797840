import AppInputMixin from '@/mixins/AppInputMixin'
import InjectValidator from '~/app/InjectValidator'

export default {
  mixins: [AppInputMixin, InjectValidator],
  props: {
    errorsInParent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputListeners: function() {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit('input', event.target.value)
          },
        },
      )
    },
  },
}
