export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PROMOTION.CLICK, ({ promotion }) => {
    window.dataLayer.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: promotion.name,
            },
          ],
        },
      },
    })
  })
}
