<template>
  <td
    :width="content.attrs.colwidth"
    :rowspan="content.attrs.rowspan"
    :colspan="content.attrs.colspan">
    <slot/>
  </td>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
}
</script>
