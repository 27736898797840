<template>
  <ul><slot/></ul>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
}
</script>
