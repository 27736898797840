<template>
  <WysiwygMarks
    v-if="content.type"
    :content="content">
    <template v-if="content.type === 'text'">{{ content.text | prepositions }}</template>
    <component
      v-else
      :is="content.type + 'Node'"
      :content="content">
      <template v-if="content.content && content.content.length">
        <WysiwygNode
          v-for="(node, index) in content.content"
          :content="node"
          :key="index"/>
      </template>
    </component>
  </WysiwygMarks>
</template>

<script>
import Blockquote from '@theme/components/utils/Wysiwyg/node/Blockquote'
import BulletList from '@theme/components/utils/Wysiwyg/node/BulletList'
import Button from '@theme/components/utils/Wysiwyg/node/Button'
import Clearer from '@theme/components/utils/Wysiwyg/node/Clearer'
import Doc from '@theme/components/utils/Wysiwyg/node/Doc'
import HardBreak from '@theme/components/utils/Wysiwyg/node/HardBreak'
import Heading from '@theme/components/utils/Wysiwyg/node/Heading'
import Image from '@theme/components/utils/Wysiwyg/node/Image'
import ListItem from '@theme/components/utils/Wysiwyg/node/ListItem'
import OrderedList from '@theme/components/utils/Wysiwyg/node/OrderedList'
import Paragraph from '@theme/components/utils/Wysiwyg/node/Paragraph'
import Table from '@theme/components/utils/Wysiwyg/node/Table'
import TableCell from '@theme/components/utils/Wysiwyg/node/TableCell'
import TableRow from '@theme/components/utils/Wysiwyg/node/TableRow'
import WysiwygMarks from '@theme/components/utils/Wysiwyg/helpers/WysiwygMarks'

export default {
  name: 'WysiwygNode',
  components: {
    blockquoteNode: Blockquote,
    bullet_listNode: BulletList,
    buttonNode: Button,
    clearerNode: Clearer,
    docNode: Doc,
    hard_breakNode: HardBreak,
    headingNode: Heading,
    imageNode: Image,
    list_itemNode: ListItem,
    ordered_listNode: OrderedList,
    paragraphNode: Paragraph,
    tableNode: Table,
    table_cellNode: TableCell,
    table_rowNode: TableRow,
    WysiwygMarks,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
}
</script>
