<template>
  <AppImage
    :alt="attrs.alt || null"
    :title="attrs.title || null"
    :crop="attrs.crop || null"
    :height="attrs.height || null"
    :src="src"
    :width="attrs.width || null"
    :image-class="imageClasses"
  />
</template>

<script>
import AppImage from '@theme/components/atom/AppImage'
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  components: {
    AppImage,
  },
  mixins: [WysiwygNodeMixin],
  computed: {
    attrs() {
      return this.content.attrs
    },
    imageClasses() {
      const classes = ['img-responsive']
      if (this.attrs.float && this.attrs.float.includes('left')) {
        classes.push('WysiwygImage--floatLeft')
      }
      if (this.attrs.float && this.attrs.float.includes('right')) {
        classes.push('WysiwygImage--floatRight')
      }
      return classes.join(' ')
    },
    src() {
      return this.attrs.image && this.attrs.image[0] && this.attrs.image[0].fileKey
    },
  },
  mounted() {
    if (this.$parent._name === '<LinkMark>') {
      this.$parent.hasImage = true
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.WysiwygImage--floatLeft,
.WysiwygImage--floatRight {
  margin: 1rem 0;
}

@include media-breakpoint-up(md) {
  .WysiwygImage--floatLeft,
  .WysiwygImage--floatRight {
    margin: 0 0 0.25rem;
  }
  .WysiwygImage--floatLeft {
    float: left;
    margin-right: map-get($spacers, 4);
  }

  .WysiwygImage--floatRight {
    float: right;
    margin-left: map-get($spacers, 4);
  }
}
</style>
