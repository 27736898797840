import _ from 'lodash'
import branchFragments from '~/queries/fragments/branch'
import gql from 'graphql-tag'

export const actions = {
  async LOAD_BRANCH_BY_SLUG({}, { slug }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.all}
          query branch($slug: String!) {
            branch(filters: { page_meta: { slug: [$slug] } }) {
              entities {
                ...branchFragmentsAll
              }
            }
          }
        `,
        variables: {
          slug,
        },
      })
      const branch = result.data.branch.entities[0]
      if (branch) {
        const storeBranch = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/branches?keys=' + branch.store_id)
        branch.storeBranch = storeBranch.data[0]
        return branch
      }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCH_BY_ID({}, { id }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.all}
          query branch($id: String!) {
            branch(filters: { _id: [$id] }) {
              entities {
                ...branchFragmentsAll
              }
            }
          }
        `,
        variables: {
          id,
        },
      })
      const branch = result.data.branch.entities[0]
      const storeBranch = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/branches?keys=' + branch.store_id)
      branch.storeBranch = storeBranch.data[0]
      return branch
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCH_CONTENT_BY_IDS({}, { ids }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.all}
          query branch($ids: [Float]) {
            branch(filters: { store_id: $ids }) {
              entities {
                ...branchFragmentsAll
              }
            }
          }
        `,
        variables: {
          ids,
        },
      })
      return result.data.branch.entities
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCH_PRIMARY() {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.all}
          query {
            branch(filters: { branch_is_primary: true }) {
              entities {
                ...branchFragmentsAll
              }
            }
          }
        `,
      })
      const branch = result.data.branch.entities[0]
      const storeBranch = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/branches?keys=' + branch.store_id)
      branch.storeBranch = storeBranch.data[0]
      return branch
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCHES({ dispatch }, { page, perPage }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.all}
          query branch($skip: Int!, $limit: Int!) {
            branch(skip: $skip, limit: $limit, sort: [{ weight: DESC }]) {
              entities {
                ...branchFragmentsAll
              }
              total
            }
          }
        `,
        variables: {
          skip: page * perPage,
          limit: perPage,
        },
      })
      result.data.branch.entities = await dispatch('LOAD_BRANCHES_BY_IDS', { branches: result.data.branch.entities })
      return result.data.branch
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCHES_BY_IDS({}, { branches }) {
    try {
      const storeIds = branches.filter(branch => branch.store_id).map(branch => branch.store_id)
      const storeBranches = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/branches?keys=' + storeIds.join(','))
      branches.forEach(branch => {
        branch.storeBranch = _.find(storeBranches.data, storeBranch => storeBranch.id === branch.store_id)
      })
      return branches
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_BRANCHES_MAP_DATA() {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${branchFragments.mapdata}
          query branch {
            branch(skip: 0, limit: 500) {
              entities {
                ...branchFragmentsMapData
              }
              total
            }
          }
        `,
      })
      return result.data.branch
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
