<script>
export default {
  mounted() {
    // We immediately load the component if
    // `IntersectionObserver` is not supported.
    if (!('IntersectionObserver' in window)) {
      this.$nextTick(() => {
        this.$emit('scrolledTo')
      })
      return
    }

    const observer = new IntersectionObserver(entries => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (entries[0].intersectionRatio <= 0) return

      // Cleanup the observer when it's not
      // needed anymore.
      observer.unobserve(this.$el)
      // The `componentFactory()` resolves
      // to the result of a dynamic `import()`
      // which is passed to the `resolveComponent()`
      // function.
      this.$emit('scrolledTo')
    })
    // We observe the root `$el` of the
    // mounted loading component to detect
    // when it becomes visible.
    setTimeout(() => {
      this.$nextTick(() => {
        observer.observe(this.$el)
      })
    }, 50)
  },
}
</script>
