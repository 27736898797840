import ProductBox from '~/themes/base/components/product/ProductBox'

export default {
  extends: ProductBox,
  computed: {
    showDateOfStorage() {
      return this.$themeSettings.components.ProductBox.showDateOfStorage && this.product.availability.delayDays < 21
    },
  },
}
