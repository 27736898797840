import { actions, state as parentState } from '~/sites/shop/store/saimon'
import { merge } from 'lodash'

const state = () =>
  merge(
    (parentState(),
    {
      apiKey: '',
      placements: {
        ventilatoryshopcz: {
          category__forYou: '61f915b995ee5cf686fec9da',
          checkout__cartItems: '61f915ca95ee5cf686fec9db',
          homepage__forYou: '61f911e895ee5cf686fec9d9',
        },
        ventilatorprofisde: {
          category__forYou: '61f915b995ee5cf686fec9da',
          checkout__cartItems: '61f915ca95ee5cf686fec9db',
          homepage__forYou: '61f911e895ee5cf686fec9d9',
        },
      },
    }),
  )

export { actions, state }
