import gql from 'graphql-tag'

export default {
  all: gql`
    fragment branchFragmentsAll on branch {
      _id
      branch_360_iframe
      branch_map_description
      branch_map_iframe
      branch_map_images {
        path
      }
      car_accessibility
      description
      gps_latitude
      gps_longitude
      google_map_link
      opening_hours
      images {
        path
      }
      lastModified
      main_image {
        path
        alt
      }
      page_meta {
        seo_description
        seo_keywords
        seo_title
        meta_nofollow
        meta_noindex
        slug
        share_image
      }
      pay_with_card
      public_transportation_accessibility
      review_link
      store_id
      title
      employees {
        entity {
          ... on employee {
            _id
            title
            position
            email
            phone
            photo {
              title
              alt
              path
            }
          }
        }
      }
    }
  `,
  mapdata: gql`
    fragment branchFragmentsMapData on branch {
      _id
      store_id
      branch_map_description
      branch_map_images {
        path
      }
      page_meta {
        seo_description
        seo_keywords
        seo_title
        meta_nofollow
        meta_noindex
        slug
      }
      gps_latitude
      gps_longitude
      opening_hours
      region
      services
      title
    }
  `,
}
