import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import itemTransformer from './transformers/item.transformer'

eventBus.$on(eventDefinitions.CART.ITEM.REMOVE, ({ item, removedAmount }) => {
  if (item.shoppable && item.product) {
    window.dataLayer.push({
      event: eventDefinitions.CART.ITEM.REMOVE,
      currencyCode: item.prices.currencyCode,
      products: [itemTransformer(item, removedAmount)],
    })
  }
})
