const registeredHooks = {}

const hookRegister = (name, callback) => {
  registeredHooks[name] = registeredHooks[name] || []
  registeredHooks[name].push(callback)
}

const hookDispatch = async (name, payload) => {
  if (registeredHooks[name]) {
    for (let callback of registeredHooks[name]) {
      await callback(payload)
    }
  }
}

export { registeredHooks, hookRegister, hookDispatch }
