import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'big_title',
    title: {
      cs: 'Text',
    },
    fields: [
      {
        name: 'text',
        title: {
          cs: '',
        },
        type: 'longtext',
        options: {
          wysiwyg: true,
        },
      },
    ],
  },
  query() {
    return `
      ... on block_big_title {
        type
        uuid
        title
        text
        background_color
        text_color
      }
    `
  },
}
