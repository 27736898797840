import _ from 'lodash'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

const sessionParts = {
  page: false,
  user: false,
  cart: false,
}

eventBus.$on(eventDefinitions.SITE.PAGE_VIEW, () => {
  sessionParts.page = true
  sessionChanged()
})

eventBus.$on(eventDefinitions.CART.LOADED, () => {
  sessionParts.cart = true
  sessionChanged()
})

eventBus.$on(eventDefinitions.USER.LOADED, () => {
  sessionParts.user = true
  sessionChanged()
})

function sessionChanged() {
  if (_.values(sessionParts).reduce((acc, val) => acc && val, true)) {
    eventBus.$emit(eventDefinitions.SITE.SESSION_LOADED)
  }
}

let sessionLoaded = false
eventBus.$on(eventDefinitions.SITE.SESSION_LOADED, () => {
  if (!sessionLoaded) {
    sessionLoaded = true
    window.dataLayer.push({
      event: eventDefinitions.SITE.SESSION_LOADED,
    })
  }
})
