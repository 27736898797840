import _ from 'lodash'

export default async function processContentBuilder(contentField, dispatch) {
  if (!contentField || !Array.isArray(contentField)) return contentField // Because of Apollo caching we already computed this in some cases

  const ids = {
    menuItems: [],
    products: [],
  }

  function pushRecursive(cF) {
    cF.forEach(block => {
      if (block.type === 'productBoxes' && block.value.products) {
        block.value.products.forEach(reference => {
          ids.products.push(reference.entityId)
        })
      }
      if (
        block.type === 'categoryBoxes' &&
        (block.value.category_reference || block.value.virtual_category_reference)
      ) {
        block.value.category_reference.forEach(reference => {
          ids.menuItems.push(reference.entityId)
        })
        block.value.virtual_category_reference.forEach(reference => {
          ids.menuItems.push(reference.entityId)
        })
      }
      if (block.type === 'row') {
        pushRecursive(block.value)
      }
    })
  }

  pushRecursive(contentField)

  const promises = {
    menuItems: dispatch('menu/FIND_MENU_ITEMS', { ids: ids.menuItems, menu: 'mainMenu' }, { root: true }),
    products: dispatch('product/LOAD_PRODUCTS', { ids: _.uniq(ids.products) }, { root: true }),
  }
  const data = await Promise.allValues(promises)

  return {
    blocks: contentField,
    loadedEntities: {
      menuItems: _.reduce(data.menuItems, (acc, menuItem) => ({ ...acc, [menuItem.id]: menuItem }), {}),
      products: _.reduce(data.products, (acc, product) => ({ ...acc, [product.id]: product }), {}),
    },
  }
}
