import UniqueSellingPoint from '@theme/components/shop/UniqueSellingPoint'

export default {
  components: {
    UniqueSellingPoint,
  },
  data() {
    return {
      uniqueSellingPoints:
        this.$themeSettings.components.UniqueSellingPoints.enable === true &&
        typeof this.$t('globals.UniqueSellingPoints') === 'object' &&
        this.$t('globals.UniqueSellingPoints').length > 0
          ? this.$t('globals.UniqueSellingPoints')
          : [],
      pointsIcons: {
        'delivery-truck': require('@icon/delivery-truck.svg?raw'),
        package: require('@icon/package.svg?raw'),
        'shield-o': require('@icon/shield-o.svg?raw'),
        success: require('@icon/success.svg?raw'),
      },
    }
  },
}
