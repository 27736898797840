export default ({ $axios, store, app }, inject) => {
  // Ensures there is active currency set on client side
  if (process.client) {
    $axios.defaults.headers.common['x-inspishop-currency'] = store.state.currency.activeCurrency.code
  }

  // Inject currency format to context
  inject('currencyFormat', (value, { decimalPlaces, currency, showFreeText = false, showSymbol = true } = {}) => {
    if (!currency) {
      currency = store.state.currency.activeCurrency
    }
    if (showFreeText && value === 0) {
      return app.i18n.t('globals.currencyFormatFreeText')
    }
    let output = ''
    if (showSymbol && currency.symbolPlacement === 'left') {
      output += currency.symbol + ' '
    }
    output += currencyFormatValue(
      value,
      typeof decimalPlaces === 'number' ? decimalPlaces : currency.decimalPlaces,
      currency.decimalPoint,
      currency.millesimalPoint,
    )
    if (showSymbol && currency.symbolPlacement === 'right') {
      output += ' ' + currency.symbol
    }
    return output.replace(' ', ' ')
  })
}

export function currencyFormatValue(price, decimalPlaces, decimalPoint, thousandsSeparator) {
  let s = price < 0 ? '-' : ''
  let i = parseInt((price = Math.abs(+price || 0).toFixed(decimalPlaces))) + ''
  let j = i.length
  j = j > 3 ? j % 3 : 0
  return (
    s +
    (j ? i.substr(0, j) + thousandsSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandsSeparator) +
    (decimalPlaces
      ? decimalPoint +
        Math.abs(price - i)
          .toFixed(decimalPlaces)
          .slice(2)
      : '')
  )
}
