<script>
import ProductMixin from '~/mixins/ProductMixin'
import _ from 'lodash'

export default {
  mixins: [ProductMixin],
  props: {
    initVariantCode: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    const variantFromInitCode =
      this.initVariantCode &&
      this.product.hasVariants &&
      this.product.variants.find(variant => variant.catalogCode === this.initVariantCode)
    return {
      activeVariantId: variantFromInitCode ? variantFromInitCode.id : this.product.defaultVariantId,
    }
  },
  computed: {
    productPath() {
      let query = {}
      if (this.activeVariantId && this.activeVariantId !== this.product.defaultVariantId) {
        query.variant_id = this.activeVariantId
      }
      if (this.isForCredits) {
        query.for_credits = true
      }

      return this.getProductPath(this.product, query)
    },
    activeVariant() {
      let active

      // Checks if selected variant is in stock and picks a different one if it isn't
      if (this.product.hasVariants) {
        active = _.find(this.product.variants, v => v.id === this.activeVariantId)
        if (active && active.availability.stock === 0 && !active.availability.isAvailable) {
          active = _.find(this.product.variants, v => v.availability.stock > 0)
        }
      }
      return this.product.hasVariants ? active || this.product.variants[0] : this.product
    },
  },
  methods: {
    selectVariant(variant) {
      this.activeVariantId = variant.id
    },
  },
}
</script>
