<script>
const DOMParser = process.browser ? window.DOMParser : require('xmldom').DOMParser
const parser = new DOMParser()

export default {
  functional: true,
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Object],
    },
  },
  render(h, ctx) {
    try {
      const icon = ctx.props.icon
      if (!icon) {
        return null
      }
      const isString = typeof icon === 'string'
      const isSVG = isString && icon.startsWith('<svg')
      const isIMG = isString && icon.endsWith('.svg')
      const isData = isString && icon.startsWith('data:')
      const isFunctional = !isString && icon.functional
      const isSprite = !isString && icon.default

      const listeners = ctx.listeners
      const fill = ctx.props.fill

      let data = ctx.data
      data.staticClass = [
        ...new Set([...(data.staticClass || '').split(' '), 'svg-icon', ...(fill ? ['svg-fill'] : [])]),
      ].join(' ')

      let attrs = {
        ...data.attrs,
      }
      let innerHTML = ''
      if (isSVG) {
        const result = parser.parseFromString(icon, 'text/xml')
        const svg = result.getElementsByTagName('svg')[0]
        if (!svg) {
          return null
        }
        if (svg.attributes) {
          Array.prototype.forEach.call(svg.attributes, attribute => {
            attrs[attribute.nodeName] = attribute.nodeValue
          })
        }
        innerHTML = process.browser ? svg.innerHTML : svg.childNodes
        return h('svg', {
          ...data,
          on: listeners,
          attrs,
          domProps: {
            innerHTML,
          },
        })
      } else if (isIMG || isData) {
        attrs.src = icon
        return h('img', {
          ...data,
          on: listeners,
          attrs,
        })
      } else if (isSprite) {
        // TODO: Add sprite SVG
        // attrs.viewBox = isSprite.viewBox
        // const svg = h('svg', {
        //   ...data,
        //   on: listeners,
        //   attrs,
        //   domProps: {
        //     innerHTML: `<use :xlink:href="#${isSprite.id}"></use>`,
        //   },
        // })
        // const svgDifs = h('svg', {
        //   attrs: {
        //     xmlns: 'http://www.w3.org/2000/svg',
        //     width: 0,
        //     height: 0,
        //   },
        //   domProps: {
        //     innerHTML: `<defs>${isSprite.content}</defs>`,
        //   },
        // })
        // return h('div', {}, [svgDifs, svg])
      } else if (isFunctional) {
        // TODO: make functional render
        return null
      }
    } catch (e) {
      console.log(e)
      return null
    }
  },
}
</script>
