import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  events: {
    heureka: {
      conversions: {
        enable: true,
      },
      shopPurchase: {
        enable: true,
      },
    },
    quarticon: {
      enable: true,
    },
  },
  functions: {
    compare: true,
    ssrPropertyPicker: true,
  },
}

export default merge(contentSettings, overloadSettings)
