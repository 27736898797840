import { hydrateWhenVisible } from 'vue-lazy-hydration'

const HeaderMain = () => import('@theme/components/shop/HeaderMain')
import JavascriptRequired from '@theme/components/shop/JavascriptRequired'
import LayoutBaseAboveHeaderHook from '@theme/components/shop/LayoutBaseAboveHeaderHook'
import LayoutGridOverview from '@theme/components/utils/LayoutGridOverview'
const MainMenu = () => import('@theme/components/shop/MainMenu')

const BadgeWidgets = () => import('@theme/components/shop/BadgeWidgets')
const FooterBottom = () => import('@theme/components/shop/FooterBottom')
const FooterMain = () => import('@theme/components/shop/FooterMain')
const HeaderTop = () => import('@theme/components/shop/HeaderTop')
const Newsletter = () => import('@theme/components/shop/Newsletter')

export default {
  components: {
    BadgeWidgets: hydrateWhenVisible(BadgeWidgets),
    FooterBottom: hydrateWhenVisible(FooterBottom),
    FooterMain: hydrateWhenVisible(FooterMain),
    HeaderMain: hydrateWhenVisible(HeaderMain),
    HeaderTop: hydrateWhenVisible(HeaderTop),
    JavascriptRequired,
    LanguageBanner: () => import('@theme/components/shop/LanguageBanner'),
    LayoutBaseAboveHeaderHook,
    LayoutGridOverview,
    MainMenu: hydrateWhenVisible(MainMenu),
    Newsletter: hydrateWhenVisible(Newsletter),
    Sticky: () => import('@theme/components/utils/Sticky'),
    TopBanner: () => import('@theme/components/content/TopBanner'),
  },
  props: {
    contentClass: {
      type: String,
    },
    contentRole: {
      type: String,
    },
    layout: {
      type: String,
      default: 'base',
    },
    showFooterBadgeWidgets: {
      type: Boolean,
      default() {
        return !!this.$themeSettings.layouts.LayoutBase.footerBadgeWidgets.enableOn.length
      },
    },
    showNewsletter: {
      type: Boolean,
      default() {
        return this.$themeSettings.layouts.LayoutBase.showNewsletter
      },
    },
  },
  data() {
    return {
      cookieConsentComponent: null,
      layoutBaseOverlay: false,
    }
  },
  beforeMount() {
    this.$nuxt.$on('layoutBaseOverlayShow', this.layoutBaseOverlayShow)
    this.$nuxt.$on('layoutBaseOverlayHide', this.layoutBaseOverlayHide)
  },
  beforeDestroy() {
    this.$nuxt.$off('layoutBaseOverlayShow', this.layoutBaseOverlayShow)
    this.$nuxt.$off('layoutBaseOverlayHide', this.layoutBaseOverlayHide)
  },
  mounted() {
    this.loadCookieConsentComponent()
  },
  computed: {
    showTopBanner() {
      return (
        this.$store.state.globals.topBanner &&
        this.$store.state.globals.topBanner.enabled &&
        this.$store.state.globals.topBanner.domain_enabled.includes(this.$store.state.globals.domain.code)
      )
    },
  },
  methods: {
    loadCookieConsentComponent() {
      if (!this.$store.state.globals.cookieConsentClosed) {
        import('@theme/components/shop/CookieConsent').then(component => {
          this.cookieConsentComponent = component.default
        })
      }
    },
    layoutBaseOverlayHide() {
      this.layoutBaseOverlay = false
    },
    layoutBaseOverlayShow() {
      this.layoutBaseOverlay = true
    },
  },
}
