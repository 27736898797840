import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import productTransformer from './transformers/product.transformer'
import variantTransformer from './transformers/variant.transformer'

eventBus.$on(eventDefinitions.PRODUCT.PAGE_VIEW, ({ product, variant }) => {
  window.dataLayer.push({
    event: eventDefinitions.PRODUCT.PAGE_VIEW,
    product: productTransformer(product),
    variant: variantTransformer(variant),
  })
})
