import { findIndex } from 'lodash'

export const mutations = {
  SET_PRODUCTS(state, { products }) {
    state.productIds = [...products]
  },
  ADD_PRODUCT(state, { product }) {
    if (!state.productIds.includes(product.id)) {
      state.productIds.push(product.id)
    }
  },
  REMOVE_PRODUCT(state, { product }) {
    const index = findIndex(state.productIds, id => id === product.id)
    state.productIds.splice(index, 1)
  },
}

export const state = () => ({
  productIds: [],
})

export const getters = {
  productIds: state => {
    return state.productIds
  },
  hasProduct: state => product => {
    return state.productIds.includes(product.id)
  },
}
