import { isEmpty, set } from 'lodash'
const getQueryFilter = ({ checkPermissions, options }) => {
  const stringifier = obj => {
    return JSON.stringify(obj)
      .replace(/\\\"/g, '^')
      .replace(/\"/g, '')
      .replace(/\^/g, '"')
      .slice(1, -1)
  }
  let filter = {
    ...(checkPermissions ? { checkPermissions } : {}),
  }
  if (options && options.length) {
    for (const option of options) {
      const [key, value] = option
      if (typeof value !== 'undefined' && key && !(typeof value === 'string' && !value.length)) {
        set(filter, key, value)
      }
    }
  }
  return !isEmpty(filter) ? `(${stringifier(filter)})` : ''
}

export { getQueryFilter }
