<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'NewsletterFormLazy',
      componentFactory: () => import('./NewsletterForm.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.NewsletterFormLazy {
  height: 100%;
}

.NewsletterFormLazy > .PlaceholderBox {
  min-height: 247px;

  @include media-breakpoint-up(lg) {
    min-height: 135px;
  }
}
</style>
