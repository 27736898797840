export default {
  props: {
    product: Object,
    showIcon: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      action: null,
      CompareModal: null,
    }
  },
  computed: {
    inComparison() {
      return this.$store.getters['compare/hasProduct'](this.product)
    },
    text() {
      return this.inComparison
        ? this.$options.filters.prepositions(this.$t('CompareButton.remove'))
        : this.$options.filters.prepositions(this.$t('CompareButton.add'))
    },
  },
  methods: {
    addToCompare(product) {
      this.$store.commit('compare/ADD_PRODUCT', { product })
      this.openModal()
      this.action = 'add'
    },
    removeFromCompare(product) {
      this.$store.commit('compare/REMOVE_PRODUCT', { product })
      this.openModal()
      this.action = 'remove'
    },
    openModal() {
      import('@theme/components/product/CompareModal').then(component => {
        this.CompareModal = component.default
        this.$nextTick(() => {
          this.$refs.CompareModal.open()
        })
      })
    },
  },
}
