import purchaseView from './purchase.view'

export default {
  register(eventContext) {
    if (eventContext.context.app.$contentSettings.events.heureka.shopCertification.enable) {
      ;[purchaseView].forEach(callback => {
        callback(eventContext)
      })
    }
  },
}
