import { notifications } from '~/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppNumber from '@theme/components/atom/AppNumber'
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import LoadingMixin from '~/mixins/LoadingMixin'
import ProductImpressionMixin from '~/mixins/ProductImpressionMixin'
import ProductLinkMixin from '~/mixins/ProductLinkMixin'

export default {
  mixins: [BusinessUserMixin, LoadingMixin, ProductImpressionMixin, ProductLinkMixin],
  props: {
    activeVariantId: Number,
    product: {
      type: Object,
      required: true,
    },
    useLink: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AppButton,
    AppNumber,
  },
  data() {
    return {
      keySuffix: Math.random(),
      variants: this.$themeSettings.components.ProductBoxVariants.enableInactiveVariants
        ? this.product.variants
        : this.product.variants.filter(v => v.availability.isAvailable),
      variantsTotalSelected: 0,
    }
  },
  methods: {
    changeVariant(variant) {
      this.$emit('changeVariant', variant)
      this.clicked()
    },
    async addToCart() {
      if (this.$store.state.globals.cartConfiguration.shopping_disabled) {
        notifications.warning(this.$store.state.globals.cartConfiguration.shopping_disabled_text)
        return false
      }
      this.startLoading()
      if (this.variantsTotalSelected) {
        const products = this.variants.map(this.transformProductToCartItem).filter(i => i.id && i.amount)
        const response = await this.$store.dispatch('cart/ADD_BATCH_TO_CART', products)
        if (response) {
          await notifications.success(this.$t('ProductBoxVariants.notifications.success'))
          this.resetSelectedVariants()
        }
      }
      this.stopLoading()
      return false
    },
    updateTotal() {
      this.variantsTotalSelected = this.variants.reduce((acc, item) => acc + (item.quantity || 0), 0)
    },
    transformProductToCartItem(item) {
      return {
        id: item.id,
        type: 'Inspishop\\Shop\\Model\\Entities\\Product',
        amount: item.quantity || 0,
        parent_id: null,
        newLine: false,
        credits: 0,
        forceAdd: true,
      }
    },
    resetSelectedVariants() {
      this.variants.forEach(v => (v.quantity = 0))
      this.variantsTotalSelected = 0
      this.keySuffix = Math.random()
    },
  },
}
