import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import productTransformer from './transformers/product.transformer'
import variantTransformer from './transformers/variant.transformer'

eventBus.$on(eventDefinitions.PRODUCT.IMPRESSION, ({ product }) => {
  window.dataLayer.push({
    event: eventDefinitions.PRODUCT.IMPRESSION,
    product: productTransformer(product),
    variant: variantTransformer(product.variants.filter(variant => variant.id === product.defaultVariantId)[0]),
  })
})
