import LoadingMixin from '~/mixins/LoadingMixin'
import PlaceholderBox from '@theme/components/utils/PlaceholderBox'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'

export default {
  components: {
    PlaceholderBox,
  },
  mixins: [ScrollToEventMixin, LoadingMixin],
  inheritAttrs: false,
  props: {
    wrapperClass: {
      type: String,
      required: true,
    },
    componentFactory: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loadedComponent: null,
    }
  },
  mounted() {
    this.$on('scrolledTo', async () => {
      this.startLoading()
      this.loadedComponent = (await this.componentFactory()).default
      this.$nextTick(() => {
        const component = this.$refs.loadedComponent
        // Check if component is already loaded or does not have loading at all.
        // Otherwise, listen to emitted loaded event.
        if (typeof component?.componentLoading === 'undefined' || component?.componentLoading === false) {
          this.stopLoading()
        } else {
          this.$refs.loadedComponent.$on('componentLoaded', () => {
            this.stopLoading()
          })
        }
      })
    })
  },
}
