<template>
  <div>
    <client-only>
      <notifications
        :duration="5000"
        :max="4"
        position="top center"
        classes="notification" />
      <component :is="entryModalComponent"/>
      <component :is="adminPanelComponent"/>
      <component :is="redirectModalComponent"/>
      <portal-target
        name="modal"
        multiple/>
      <portal-target
        name="fullscreenPresenter"/>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'UtilsLayout',
  data() {
    return {
      adminPanelComponent: null,
      entryModalComponent: null,
      redirectModalComponent: null,
    }
  },
  mounted() {
    if (this.$themeSettings.components.EntryModal.enable) {
      import('@theme/components/shop/EntryModal').then(component => {
        this.entryModalComponent = component.default
      })
    }
    if (this.$env.ENVIRONMENT_OVERRIDE_ALLOWED) {
      import('@theme/components/shop/AdminPanel').then(component => {
        this.adminPanelComponent = component.default
      })
    }
    if (this.$themeSettings.components.RedirectModal.enable) {
      import('@theme/components/shop/RedirectModal').then(component => {
        this.redirectModalComponent = component.default
      })
    }
  },
}
</script>
