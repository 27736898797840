import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'quote',
  },
  query() {
    return `
      ... on block_quote {
        type
        uuid
        text
      }
    `
  },
}
