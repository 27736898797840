import discountTransformer from './transformers/discount.transformer'
import productTransformer from './transformers/product.transformer'

export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const couponItems = order.items.filter(item => item.attributes && item.attributes.discount)
    const settings = context.app.$contentSettings.events.enhancedEcommerce.purchase

    let revenueArray = [order.prices[`items${settings.unitsWithTax ? 'With' : 'Without'}Tax`]]
    if (settings.revenueIncludeShipperPrice) {
      revenueArray.push(order.prices[`shipperPrice${settings.unitsWithTax ? 'With' : 'Without'}Tax`])
    }
    if (settings.revenueIncludePaymentPrice) {
      revenueArray.push(order.prices[`paymentPrice${settings.unitsWithTax ? 'With' : 'Without'}Tax`])
    }
    const revenue = revenueArray.reduce((a, b) => a + b, 0)

    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order.code,
            affiliation: 'Online Store',
            revenue: revenue,
            tax: order.prices.totalWithTax - order.prices.totalWithoutTax,
            shipping: settings.unitsWithTax ? order.prices.shipperPriceWithTax : order.prices.shipperPriceWithoutTax,
            coupon: couponItems.length > 0 ? discountTransformer(couponItems[0]) : '',
            currency: order.currencyCode,
          },
          products: order.items
            .filter(item => !(item.attributes && item.attributes.discount) && item.shoppable && item.product)
            .map(item => productTransformer(item, settings.unitsWithTax)),
        },
      },
    })
  })
}
