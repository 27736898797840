import { getQueryFilter } from '~/queries/plugins'
import { orderBy } from 'lodash'
import articleFragments from '~/queries/fragments/article'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

export const actions = {
  async LOAD_ARTICLE({ dispatch }, { checkPermissions, slug }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }

      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        checkPermissions,
        options: [['filters.page_meta.slug[0]', `"${slug}"`], ['filters.site_enabled', siteCode]],
      })
      const result = await client.query({
        query: gql`
          ${articleFragments.all}
          {
            article${filter} {
              entities {
                ...articleFragmentsAll
              }
              error {
                statusCode
              }
            }
          }
        `,
      })
      if (result.data.article.error) {
        return { error: result.data.article.error }
      }
      const article = ((result.data.article || {}).entities || [])[0]
      const { content } = article || {}
      if (content) {
        article.content = await processContentBuilder(content, dispatch)
      }
      return article
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_ARTICLES({}, { page, perPage }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }

      let client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [
          ['skip', page * perPage],
          ['limit', perPage],
          ['sort[0].date_published', 'DESC'],
          ['filters.site_enabled', siteCode],
        ],
      })
      const result = await client.query({
        query: gql`
          ${articleFragments.box}
          {
            article${filter} {
              total
              entities {
                ...articleFragmentsBox
              }
            }
          }
        `,
      })
      return result.data.article
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_CATEGORY_ARTICLES({}, { categoryId, page, perPage }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }

      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [
          ['skip', page * perPage],
          ['limit', perPage],
          ['sort[0].date_published', 'DESC'],
          ['filters.site_enabled', siteCode],
          ['filters.article_category_reference.entity_id[0]', `"${categoryId}"`],
        ],
      })
      const result = await client.query({
        query: gql`
          ${articleFragments.box}
          query {
            article_category: article${filter} {
              total
              entities {
                ...articleFragmentsBox
              }
            }
          }
        `,
      })
      const { entities } = result.data.article_category
      // Sort out articles with publish date in the future
      const articles = (entities || []).filter(
        article => Date.parse(article.date_published ? article.date_published : 0) < Date.now(),
      )
      return { articles, ...result.data.article_category }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_CATEGORY({}, { categorySlug }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode], ['filters.page_meta.slug[0]', `"${categorySlug}"`]],
      })
      const result = await client.query({
        query: gql`
          query {
            article_category${filter} {
              entities {
                _id
                content
                image {
                  path
                  alt
                }
                page_meta {
                  seo_description
                  seo_keywords
                  seo_title
                  meta_nofollow
                  meta_noindex
                  slug
                  share_image
                  canonical_slugs {
                    language
                    slug
                  }
                }
                perex
                title
                master_category {
                  entity {
                    ... on article_category {
                      _id
                      title
                      perex
                      page_meta {
                        slug
                        canonical_slugs {
                          language
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      return result.data.article_category.entities[0]
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_TAG_ARTICLES({}, { tagId, page, perPage }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [
          ['skip', page * perPage],
          ['limit', perPage],
          ['sort[0].date_published', 'DESC'],
          ['filters.site_enabled', siteCode],
          ['filters.article_tag_reference.entity_id[0]', `"${tagId}"`],
        ],
      })
      const result = await client.query({
        query: gql`
          ${articleFragments.box}
          {
            article_tag: article${filter} {
              total
              entities {
                ...articleFragmentsBox
              }
            }
          }
        `,
      })
      const { entities } = result.data.article_tag
      // Sort out articles with publish date in the future
      const articles = (entities || []).filter(
        article => Date.parse(article.date_published ? article.date_published : 0) < Date.now(),
      )
      return { articles, ...result.data.article_tag }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_TAG({}, { tagSlug }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode], ['filters.page_meta.slug[0]', `"${tagSlug}"`]],
      })
      const result = await client.query({
        query: gql`
          {
            article_tag${filter} {
              entities {
                _id
                content
                image {
                  path
                  alt
                }
                page_meta {
                  seo_description
                  seo_keywords
                  seo_title
                  meta_nofollow
                  meta_noindex
                  slug
                  share_image
                  canonical_slugs {
                    language
                    slug
                  }
                }
                perex
                title
              }
            }
          }
        `,
      })
      return result.data.article_tag.entities[0]
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_AUTHOR({ dispatch }, { authorSlug }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode], ['filters.page_meta.slug[0]', `"${authorSlug}"`]],
      })
      const result = await client.query({
        query: gql`
          {
            author${filter} {
              entities {
                _id
                title
                perex
                image {
                  title
                  alt
                  path
                }
                content
                page_meta {
                  seo_description
                  seo_keywords
                  seo_title
                  meta_nofollow
                  meta_noindex
                  slug
                  share_image
                  canonical_slugs {
                    language
                    slug
                  }
                }
              }
            }
          }
        `,
      })
      const author = (result.data.author.entities || [])[0] || {}
      const { content } = author
      if (content) {
        author.content = await processContentBuilder(content, dispatch)
      }
      return author
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_ARTICLES_BY_AUTHOR({}, { authorId, page, perPage }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [
          ['skip', page * perPage],
          ['limit', perPage],
          ['sort[0].date_published', 'DESC'],
          ['filters.site_enabled', siteCode],
          ['filters.article_author_reference.entity_id[0]', `"${authorId}"`],
        ],
      })
      const result = await client.query({
        query: gql`
          ${articleFragments.box}
          {
            article_author: article${filter} {
              total
              entities {
                ...articleFragmentsBox
              }
            }
          }
        `,
      })
      const { entities, total } = result.data.article_author || {}
      return { entities, total }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_HEADER_CONFIGURATION({ commit }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            configuration_article_list {
              description
              image {
                alt
                path
              }
              title
              perex
            }
          }
        `,
      })
      const { configuration_article_list } = query.data || {}
      commit('SET', { key: 'configuration_article_list', value: configuration_article_list })
      return configuration_article_list
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_CATEGORIES({ commit }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['sort[0].weight', 'DESC'], ['filters.site_enabled', siteCode]],
      })
      const result = await client.query({
        query: gql`
          {
            article_category${filter} {
              entities {
                ... on article_category {
                  _id
                  title
                  menu_title
                  weight
                  perex
                  page_meta {
                    slug
                  }
                  site_enabled
                  master_category {
                    entity {
                      ... on article_category {
                        _id
                        title
                        perex
                        page_meta {
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      let { entities } = result.data.article_category || {}
      entities = entities.filter(e => !this.$contentSettings.masterCategories[e._id])
      commit('SET', { key: 'categories', value: orderBy(entities, 'title') || [] })
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_TAGS({ commit }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode]],
      })
      const result = await client.query({
        query: gql`
          {
            article_tag${filter} {
              entities {
                ... on article_tag {
                  _id
                  title
                  perex
                  page_meta {
                    slug
                  }
                  site_enabled
                }
              }
            }
          }
        `,
      })
      let { entities } = result.data.article_tag || {}
      commit('SET', { key: 'tags', value: orderBy(entities, 'title') || [] })
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  configuration_article_list: null,
  categories: [],
  tags: [],
})

export const mutations = {
  SET(state, { key, value }) {
    state[key] = value
  },
}
