import gql from 'graphql-tag'

export default {
  all: gql`
    fragment catalogSettingsFragmentAll on FieldCatalogSettings {
      parameters {
        entityId
        importantFilter
        closedFilter
      }
    }
  `,
}
