<script>
import FoxentryInputMixin from '~/mixins/FoxentryInputMixin'

export default {
  mixins: [FoxentryInputMixin],
  inheritAttrs: false,
  props: {
    value: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    maxLength: {
      type: [Number, Object],
      default: null,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    showValidIndicator: {
      type: Boolean,
      default: true,
    },
    validation: {
      // TODO: Add option to turn validation off
      type: [String, Object],
      default: '',
    },
    inputClass: {
      type: [Array, String],
      default: '',
    },
  },
}
</script>
