import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.CHECKOUT.STEP_OPTION, ({ number, option }) => {
  window.dataLayer.push({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: number + 1,
          option: option,
        },
      },
    },
  })
})
