import { render, staticRenderFns } from "./AddToCart.vue?vue&type=template&id=31ee4eee&"
import script from "./AddToCart.js?vue&type=script&lang=js&"
export * from "./AddToCart.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AddToCart.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fbase%2Fcomponents%2Fcart%2FAddToCart%2FAddToCart.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports