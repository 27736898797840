import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.CHECKOUT.STEP_LOAD, ({ cartState }) => {
  window.dataLayer.push({
    event: 'trackrmkt',
    google_tag_params: {
      ecomm_prodid: cartState.cartData.items.map(item => item.product?.marketingCode),
      ecomm_pagetype: 'cart',
      ecomm_totalvalue: cartState.cartData.items.reduce((acc, item) => acc + item.prices.unitWithTax, 0),
    },
  })
})

eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
  window.dataLayer.push({
    event: 'trackrmkt',
    google_tag_params: {
      ecomm_prodid: order.items
        .filter(item => !(item.attributes && item.attributes.discount))
        .map(item => item.product?.marketingCode),
      ecomm_pagetype: 'purchase',
      ecomm_totalvalue: order.items.reduce((acc, item) => acc + item.prices.unitWithTax, 0),
    },
  })
})

eventBus.$on(eventDefinitions.PRODUCT.PAGE_VIEW, ({ product }) => {
  window.dataLayer.push({
    event: 'trackrmkt',
    google_tag_params: {
      ecomm_prodid: product.marketingCode,
      ecomm_pagetype: 'product',
      ecomm_totalvalue: product.prices.unitWithTax,
    },
  })
})

eventBus.$on(eventDefinitions.SITE.PAGE_VIEW, ({ page_route_name, page_url }) => {
  if (
    page_route_name.includes('index__') ||
    page_route_name.includes('search__') ||
    page_route_name.includes('all__')
  ) {
    window.dataLayer.push({
      event: 'trackrmkt',
      google_tag_params: {
        ecomm_pagetype: page_route_name.includes('index__')
          ? 'home'
          : page_route_name.includes('search__')
            ? 'searchresults'
            : page_route_name.includes('all__')
              ? 'category'
              : null,
      },
    })
  }
})
