import ProductColorButton from '@theme/components/product/ProductColorButton'

export default {
  components: {
    ProductColorButton,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alternativeProducts: [],
      inited: false,
    }
  },
  computed: {
    hasAlternativeProducts() {
      return this.alternativeProducts.length
    },
  },
  methods: {
    async loadAlternativeProducts() {
      this.alternativeProducts = await this.$store.dispatch('product/LOAD_PRODUCTS', { ids: this.product.alternatives })
    },
  },
  // Component is mounted unconditionally but loads alternative products only once on first hover
  watch: {
    active() {
      if (!this.inited && this.active) {
        this.loadAlternativeProducts()
        this.inited = true
      }
    },
  },
}
