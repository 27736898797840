<script>
/**
 * imageUrl method returns url supported by backend (BE supported parameters follows.)
 * Output example: `custom-w1000-h1000-1x2-crop-upscale`
 *
 * upscale (boolean): returns w/h sized enlarged even if the original is smaller.
 * crop (boolean, width & height required): crops center part of an image, returns white background.
 *
 * Sizes calculations:
 *
 * w1000 -> width 1000px, height is calculated by image aspect ratio
 * h1000 -> height 1000px, width is calculated by image aspect ratio
 *
 * Supported but not implemented to frontend yet:
 *
 * h200-1x2 -> height 200px, width 100px
 * h200-2x1 -> height 200px, width 400px
 * w200-1x2 -> height 400px, width 200px
 * w200-2x1 -> height 100px, width 200px
 * 1x2 -> keep shorter side, enlarge longer side by given ratio
 *
 *
 * Croping image i.e. width 1200px, height 800px (and disabled upscale):
 *
 * If wanted image is larger than original:
 *
 * w1600 -> wanted width 1600px, calculated by aspect ratio
 * h1600 -> calculated by aspect ratio, wanted height 1600px
 * w1600-crop -> original image sizes (width 1200px, height 800px)
 * h1600-crop -> original image sizes (width 1200px, height 800px)
 *
 * If wanted image is smaller than original:
 *
 * w800 -> wanted width 800px, calculated by aspect ratio
 * h800 -> calculated by aspect ratio, wanted height 800px
 * w800-crop -> crop has no effect
 * h800-crop -> crop has no effect
 */
export default {
  methods: {
    imageUrl({
      src,
      width = null,
      height = null,
      crop = false,
      imageStyle = 'custom',
      pixelRatio = 1,
      webP = false,
      upscale = false,
    }) {
      if (!src) return ''
      if (width === null || height === null) crop = true

      const w = width ? width * pixelRatio : null
      const h = height ? height * pixelRatio : null

      const url = [
        this.$env.CDN_URL || this.$env.STORE_URL,
        'image',
        imageStyle === 'original'
          ? imageStyle
          : [imageStyle, w ? 'w' + w : null, h ? 'h' + h : null, crop ? 'crop' : null, upscale ? 'upscale' : null]
              .filter(o => o)
              .join('-'),
        src,
      ].join('/')

      if (webP) {
        return url.replace(/(.*)\.(jpg|png)/, '$1.webp?original=$2')
      }

      return url
    },
  },
}
</script>
