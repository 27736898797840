import { find, first, last } from 'lodash'

const GET_URLS = payload => {
  try {
    const { domains, paths, slugs, childs } = payload || {}
    let urls = []
    for (let i = 0; i < domains.length; i++) {
      const { domain, language } = domains[i]
      let path = paths[language]
      if (path) {
        const slug = (slugs || []).length ? find(slugs, { language }).slug : false
        const child = (childs || []).length ? find(childs, { language }).slug : false
        path = path.replace('/:child', child ? `/${child}` : '').replace('/:slug', slug ? `/${slug}` : '')
        urls.push({
          language: language,
          url: `${domain}${path}`.replace(/([^:])[\/]{2,}/g, '$1/'), // replace more then one slash with one slash (expt. http(s)://)
        })
      }
    }
    return urls
  } catch (e) {
    console.error(e)
    return []
  }
}

export const mutations = {
  SET_ALTERNATIVE_PATHS(state, paths) {
    state.alternativePaths = paths || {}
  },
  SET_CANONICALS(state, payload) {
    const { canonicalSlugs, childSlugs, nuxtI18nPaths } = payload || {}
    const nuxtI18nRoutes = this.app.router.options.routes.reduce((acc, r) => {
      const name = first(this.app.context.route.name.split('___')) // get actual route name without language suffix
      // for route translations (ex.: route-name___en)
      if (r.name.startsWith(name)) {
        const language = last(r.name.split('___')) // get filtered route language
        return {
          ...acc,
          [language]: r.path
            .replace(`/${language}`, '/') // replace lang prefix (ex. /en/slug => /slug)
            .replace('//', '/') // replace double slash
            .replace('/*', '/:slug'), // replace * placeholder with :slug placeholder (see below on canonicalSlugs)
        } // inject as { lang: path }
      }
      return { ...acc }
    }, {})
    const alternativeUrls = GET_URLS({
      domains: state.alternativeDomains,
      paths: state.alternativePaths,
      slugs: canonicalSlugs,
      childs: childSlugs,
    })
    const canonicalUrls = GET_URLS({
      domains: state.canonicalDomains,
      paths: nuxtI18nPaths || nuxtI18nRoutes,
      slugs: canonicalSlugs,
      childs: childSlugs,
    })
    state.alternativeUrls = alternativeUrls
    state.canonicalUrls = canonicalUrls
  },
  SET_TRANSLATION_VARIANT(state, translationVariant) {
    state.translationVariant = translationVariant
    state.contentLocale = this.app.i18n.locale + (translationVariant ? `-${translationVariant}` : '')
  },
}

/* For alternativeDomains and canonicalDomains states
 * - Array of objects { language: 'cs', domain: '...' }
 * - also specify local translations as absolute domain urls. Ex.:  https://.../en, https://.../de etc...
 * --- alternativeDomains appears as html 'alternate' meta tags
 * --- canonicalDomains appears as html 'canonical' meta tags
 */
export const state = () => ({
  alternativeDomains: [],
  alternativePaths: {},
  alternativeUrls: [],
  canonicalDomains: [],
  canonicalUrls: [],
  sameDomain: false,
  translationVariant: null,
  contentLocale: null,
})
