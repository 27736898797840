import productTransformer from './transformers/product.transformer'

export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.CHECKOUT.STEP_LOAD, ({ cartState }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    const sendProductsInFirstCheckoutStepOnly = context.app.$contentSettings.events.sendProductsInFirstCheckoutStepOnly
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: cartState.checkout.currentStep + 1,
          },
          ...((!sendProductsInFirstCheckoutStepOnly || cartState.checkout.currentStep === 0) && {
            products: cartState.cartData.items
              .filter(item => item.shoppable && item.product)
              .map(cartItem => productTransformer(cartItem, unitsWithTax)),
          }),
        },
        currencyCode: cartState.cartData.currencyCode,
      },
    })
  })
}
