import AddToCart from '@theme/components/cart/AddToCart'
import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Badge from '@theme/components/atom/Badge'
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import CompareButton from '@theme/components/product/CompareButton'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import HeadingLevel from '~/mixins/HeadingLevel'
import PricesMixin from '~/mixins/PricesMixin'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductBoxColorVariants from '@theme/components/product/ProductBoxColorVariants'
import ProductBoxMixin from '~/mixins/ProductBoxMixin'
import ProductBoxVariants from '@theme/components/product/ProductBoxVariants'
import ProductDateOfStorage from '@theme/components/product/ProductDateOfStorage'
import ProductImpressionMixin from '~/mixins/ProductImpressionMixin'
import StarRating from '@theme/components/utils/StarRating'
import Tag from '@theme/components/atom/Tag'
import TitleHighlightMixin from '~/mixins/TitleHighlightMixin'
import WishListIcon from '@theme/components/product/WishListIcon'
import _ from 'lodash'

export default {
  mixins: [BusinessUserMixin, HeadingLevel, PricesMixin, ProductBoxMixin, ProductImpressionMixin, TitleHighlightMixin],
  components: {
    AddToCart,
    AppImage,
    AppTitle,
    Badge,
    CompareButton,
    DeliveryDate,
    ProductAvailability,
    ProductBoxColorVariants,
    ProductBoxVariants,
    ProductDateOfStorage,
    StarRating,
    Tag,
    WishListIcon,
  },
  data() {
    return {
      hover: false,
    }
  },
  props: {
    selectedParameterValues: {
      type: Array,
      default() {
        return []
      },
    },
    addToCartButtonText: {
      type: String,
    },
  },
  created() {
    if (this.selectedParameterValues && this.selectedParameterValues.length > 0) {
      this.selectBestMatchingVariant()
    }
  },
  watch: {
    selectedParameterValues() {
      this.selectBestMatchingVariant()
    },
  },
  computed: {
    itemFormattedPrices() {
      return {
        ...this.getFormattedPrices({ prices: this.activeVariant.prices }),
        original: this.getOriginalPrice({
          prices: this.activeVariant.prices,
          originalWithKey: 'originalWithTax',
          originalWithoutKey: 'originalWithoutTax',
        }),
      }
    },
    name() {
      return this.$themeSettings.components.ProductBox.showVariantFullName
        ? this.activeVariant.fullname
        : this.product.name
    },
    productBoxHoverImage() {
      return this.product?.content?.product_box_hover_image &&
        this.product?.content?.product_box_hover_image?.length > 0
        ? this.product.content.product_box_hover_image[0].fileKey
        : null
    },
    showB2CPriceForB2BPartner() {
      return (
        this.$themeSettings.components.ProductBox.showB2CPriceForB2BPartner &&
        this.isBusinessUser &&
        this.activeVariant.defaultPrices.originalWithTax > this.activeVariant.prices.unitWithTax
      )
    },
    showDateOfStorage() {
      return this.$themeSettings.components.ProductBox.showDateOfStorage && !this.product.availability.isAvailable
    },
  },
  methods: {
    selectBestMatchingVariant() {
      const selectedValueIds = this.selectedParameterValues.map(value => value.id)
      const variantParameterValuesScores = this.product.variants.map(variant => {
        const variantParameterValues =
          variant.content &&
          variant.content.parameters &&
          variant.content.parameters.length &&
          variant.content.parameters.reduce((acc, parameter) => {
            return [...acc, ...(Array.isArray(parameter.values) ? parameter.values : [])]
          }, [])
        const intersectionValues = _.intersection(selectedValueIds, variantParameterValues)
        return intersectionValues.length
      })

      const bestMatchingVariantIndex = variantParameterValuesScores.reduce((a, b, i) => (a[0] < b ? [b, i] : a), [
        Number.MIN_VALUE,
        -1,
      ])[1]
      const bestMatchingVariant = this.product.variants[bestMatchingVariantIndex]
      if (bestMatchingVariant) {
        this.selectVariant(bestMatchingVariant)
      } else {
        this.selectVariant(this.product.variants.find(variant => variant.id === this.product.defaultVariantId))
      }
    },
  },
}
