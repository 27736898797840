import AppButton from '@theme/components/atom/AppButton'
import AppLink from '@theme/components/atom/AppLink'
import BigTitleBlock from '@theme/blocks/BigTitleBlock'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  mixins: [ImageUrlMixin, ContentBuilderBlock],
  components: {
    AppButton,
    AppLink,
    BigTitleBlock,
    Wysiwyg,
  },
  cmsSettings: {
    type: 'text_boxes',
  },
  computed: {
    backgroundImage() {
      if (this.block.background_image && this.block.background_image[0]) {
        return this.imageUrl({ src: this.block.background_image[0].path })
      }
    },
    backgroundBannerImage() {
      if (this.block.background_banner_image && this.block.background_banner_image[0]) {
        return this.imageUrl({ src: this.block.background_banner_image[0].path })
      }
    },
    titleBlock() {
      return {
        background_color: this.block.block_title_background_color,
        text_color: this.block.block_title_text_color,
        text: this.block.block_title,
      }
    },
  },
  query() {
    return `
      ... on block_text_boxes {
        uuid
        type
        block_title
        block_title_background_color
        block_title_text_color
        background_image { path }
        background_banner_image { path }
        background_color
        text_boxes {
          ... on text_box {
            text
            background_color
            text_color
            box_styles
          }
        }
        button {
          ... on button_embedded {
            text
            button_link {
              ... on link_embedded {
                title
                link_type
                custom_link
                category_link {
                  entity {
                    ... on StoreCategory {
                      data
                    }
                  }
                }
                virtual_category_link {
                  entity {
                    ... on virtual_category {
                      title
                      page_meta {
                        slug
                      }
                    }
                  }
                }
              }
            }
            background_color
            text_color
            style
          }
        }
      }
    `
  },
}
