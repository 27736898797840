export function isNotAlreadySent(key, id) {
  const storedIds = getSentIds(key)
  return storedIds.indexOf(id) === -1
}

export function logSent(key, id) {
  const storedIds = getSentIds(key)
  localStorage.setItem(key, [...storedIds, id].join(','))
}

function getSentIds(key) {
  const sentIds = localStorage.getItem(key) || ''
  const ordersList = sentIds
    .split(',')
    .filter(e => e)
    .map(i => parseInt(i, 10))
  return ordersList
}
