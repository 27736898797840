import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'

export default {
  components: {
    UniqueSellingPoints,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'usp',
  },
  query() {
    return `
      ... on block_usp {
        type
        uuid
      }
    `
  },
}
