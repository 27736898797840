import { getQueryFilter } from '~/queries/plugins'
import contentBlocksQuery from '~/app/contentBlocksQuery'
import gql from 'graphql-tag'
import pageFragments from '~/queries/fragments/page'
import processContentBuilder from '~/app/processContentBuilder'

export const actions = {
  async LOAD_CONTENT_PAGE({ commit, dispatch }, { slug, checkPermissions }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        checkPermissions,
        options: [['filters.page_meta.slug[0]', `"${slug}"`]],
      })
      const result = await client.query({
        query: gql`
          {
            content_page${filter} {
              entities {
                _id
                title
                pagination
                blocks_per_page
                content_blocks {
                  ${contentBlocksQuery()}
                }
                background_image {
                  path
                }
                page_meta {
                  canonical_slugs {
                    language
                    slug
                  }
                  meta_nofollow
                  meta_noindex
                  seo_description
                  seo_keywords
                  seo_title
                  slug
                  share_image
                }
              }
              error {
                statusCode
              }
            }
          }
        `,
      })
      if (result.data.content_page.error) {
        return { error: result.data.content_page.error }
      }
      const page = result.data.content_page.entities[0]
      return page
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_PAGE({ commit, dispatch }, { slug, checkPermissions }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        checkPermissions,
        options: [['filters.page_meta.slug[0]', `"${slug}"`]],
      })
      const result = await client.query({
        query: gql`
          ${pageFragments.all}
          {
            page${filter} {
              entities {
                ...pageFragmentsAll
                content_blocks {
                  ${contentBlocksQuery()}
                }
              }
              error {
                statusCode
              }
            }
          }
        `,
      })
      if (result.data.page.error) {
        return { error: result.data.page.error }
      }
      const page = result.data.page.entities[0]
      if (page && page.content) {
        page.content = await processContentBuilder(page.content, dispatch)
      }
      return page
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_ALL_PAGES() {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${pageFragments.menu}
          query page {
            page {
              entities {
                ...pageFragmentsMenu
              }
            }
          }
        `,
      })
      return result.data.page.entities.filter(p => p.page_meta)
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
