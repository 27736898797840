<script>
import FoxentryInputsParentMixin from '~/mixins/FoxentryInputsParentMixin'
// Mapping => foxentry key : storefront key
const foxentryMapping = {
  email: 'email',
  nameSurname: 'full_name',
  phoneNumber: 'phone_number',
  registrationNumber: 'tin',
  street: 'street_only',
  'address.street': 'street_only',
  streetNumber: 'house_number',
  'address.streetNumber': 'house_number',
  zip: 'postcode',
  'address.zip': 'postcode',
}
export default {
  mixins: [FoxentryInputsParentMixin],
  computed: {
    foxentryInputValidation() {
      const { dataType } = this.$store.state.foxentry.inputs[this.inputName] || {}
      return dataType || false
    },
    inputName() {
      if (this.foxentryEnabled && !this.$attrs.disabled) {
        return this.foxentryInputName || this.$store.getters['foxentry/GET_ROUTE'] + '_' + (this.name || '')
      } else {
        return this.name
      }
    },
    inputType() {
      return this.foxentryEnabled && this.foxentryInputName && this.$attrs.type === 'email' ? 'text' : this.$attrs.type
    },
    inputValidation() {
      // Do not map if input is disabled or Foxentry validation roles not loaded yet
      if (!this.foxentryEnabled || this.$attrs.disabled || !this.foxentryInputName) {
        return this.validation
      }
      // If Foxentry validation roles are loaded
      if (this.validation) {
        let validation = this.validation
        if ((this.foxentryInputValidation || []).length) {
          for (let i = 0; i < this.foxentryInputValidation.length; i++) {
            const mapping = foxentryMapping[this.foxentryInputValidation[i]] // Get mapped role
            // Remove role if is mapped
            if (mapping) {
              // validation props can be string or object
              if (typeof validation === 'string') {
                validation = validation
                  .split('|')
                  .filter(i => i !== mapping && i.indexOf(mapping + ':') === -1)
                  .join('|') // Return filtered validation roles for vee-validate
              } else if (validation[mapping]) {
                delete validation[mapping] // Remove validation key from object
              }
            }
          }
        }
        return validation
      }
      // Fallback
      return false
    },
    foxentryCountryCode: {
      get() {
        return this.foxentryGetCountryCode(this.val)
      },
      set(value) {
        this.val = this.foxentrySetCountryCode(value)
      },
    },
  },
  methods: {
    foxentryGetCountryCode(code) {
      const codes = { CZE: 'cz', SVK: 'sk' }
      return codes[code] || null
    },
    foxentrySetCountryCode(code) {
      const codes = { cz: 'CZE', sk: 'SVK' }
      return codes[code] || null
    },
  },
}
</script>
