import { get } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import Divider from '@theme/components/molecule/Divider'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  mixins: [HeadingLevel],
  components: {
    AppImage,
    AppLink,
    AppTitle,
    Divider,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    headingClass: {
      type: String,
      default() {
        return this.$themeSettings.components.TopCategory.headingClass
      },
    },
  },
  methods: {
    getCategoryImage() {
      const path =
        get(this.category, 'top_category_image[0].path') ||
        get(
          this.category,
          `top_categories[0].entity.data.content.top_category_image[${this.$i18n.locale}][0].fileKey`,
        ) ||
        false
      const alt = get(this.category, 'top_category_image[0].alt') || this.content.name || false
      return {
        alt,
        path,
      }
    },
  },
  computed: {
    content() {
      // It's store category
      if (this.category.top_category_item_type.indexOf('category') === 0) {
        const entity =
          this.category.top_categories &&
          this.category.top_categories[0] &&
          this.category.top_categories[0].entity &&
          this.category.top_categories[0].entity.data
        return (
          entity && {
            name: entity.name,
            slug: entity.slug,
          }
        )
      }
      // It's virtual cms category
      if (this.category.top_category_item_type.indexOf('virtual_category') === 0) {
        const entity =
          this.category.top_virtual_categories &&
          this.category.top_virtual_categories[0] &&
          this.category.top_virtual_categories[0].entity

        return (
          entity && {
            name: entity.title,
            slug: entity.page_meta && entity.page_meta.slug,
          }
        )
      }
    },
    links() {
      return this.category.top_category_links
        ? this.category.top_category_links.map(item => {
            const link = {
              title: item.title,
            }
            if (
              item.link_type.includes('category') &&
              item.category_link &&
              item.category_link.length > 0 &&
              item.category_link[0].entity &&
              item.category_link[0].entity.data
            ) {
              link.path = this.$categoryLocalePath(item.category_link[0].entity.data.slug)
            }
            if (
              item.link_type.includes('virtual_category') &&
              item.virtual_category_link &&
              item.virtual_category_link.length > 0 &&
              item.virtual_category_link[0] &&
              item.virtual_category_link[0].entity &&
              item.virtual_category_link[0].entity.page_meta
            ) {
              link.path = this.$categoryLocalePath(item.virtual_category_link[0].entity.page_meta.slug)
            }
            if (item.link_type.includes('custom_link')) {
              link.path = item.custom_link
            }
            return link
          })
        : []
    },
  },
}
