import { get } from 'lodash'
import { getters, mutations, actions as parentActions, state } from '~/sites/shop/store/cart'
import { hookDefinitions, hookDispatch } from '@/hooks'

const actions = {
  ...parentActions,
  async PLACE_ORDER(actionContext) {
    const { commit, state, dispatch, rootState, rootGetters, getters } = actionContext
    try {
      const name = this.$themeSettings.global.userFullName
        ? state.input.fullName
        : state.input.firstName + ' ' + state.input.lastName
      const shippingName = this.$themeSettings.global.userFullName
        ? state.input.shippingFullName
        : state.input.shippingFirstName + ' ' + state.input.shippingLastName

      const isRegistration = getters.isRegistration
      const isCustomOrderNumberEnabled = this.$themeSettings.checkout.enableCustomOrderNumber
      const data = {
        name,
        firstName: state.input.firstName,
        lastName: state.input.lastName,
        email: state.input.email,
        phone: state.input.phone,
        billing_address: {
          street: state.input.billingHouseNumber
            ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
            : state.input.billingStreet,
          city: state.input.billingCity,
          postcode: state.input.billingPostcode,
          country_code: state.input.billingCountry,
        },
        business: state.input.isBusiness
          ? {
              business_name: state.input.businessName,
              TIN: state.input.tin,
              VATIN: state.input.vatin,
            }
          : null,
        shipping: state.input.shipToShippingAddress
          ? {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.houseNumber
                  ? `${state.input.street} ${state.input.houseNumber}`
                  : state.input.street,
                city: state.input.city,
                postcode: state.input.postcode,
                country_code: state.input.country,
              },
            }
          : {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.billingHouseNumber
                  ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
                  : state.input.billingStreet,
                city: state.input.billingCity,
                postcode: state.input.billingPostcode,
                country_code: state.input.billingCountry,
              },
            },
        custom: {
          ...state.input.custom,
          ...(isCustomOrderNumberEnabled && { custom_order_number: state.input.customOrderNumber }),
        },
        comment: state.input.note,
        shipper_comment: state.input.note_for_shipper,
        payment_id: state.input.payment ? state.input.payment.id : null,
        shipper_id: state.input.shipper ? state.input.shipper.id : null,
        branch_id: state.input.shipperBranch ? state.input.shipperBranch.id : null,
        terms: true,
        cardRequest: state.input.cardRequest,
        order_gifts: state.selectedGifts.map(g => g.id),
        newsletter: state.input.newsletter,
        heureka_allow: state.input.complianceHeureka,
        zbozicz_allow: state.input.complianceZbozi,
        magazine: state.input.complianceMagazine,
        phone_notification: state.input.complianceSmsMarketing,
        delivery_time_id: state.input.deliveryTimeSlot ? state.input.deliveryTimeSlot.id : null,
        ...(isRegistration
          ? {
              password: state.input.password,
              password_confirmation: state.input.passwordConfirmation,
              registration: true,
            }
          : {}),
      }
      const getDefaultAddress = (key, fallbackAddress) => {
        const searchKey = key !== 'country_code' ? key : 'countryCode'
        return state.input.shipperBranch
          ? state.input.shipperBranch.address[searchKey]
          : fallbackAddress
            ? fallbackAddress[searchKey]
            : rootState.globals.contact.address[searchKey]
      }
      const setShipperAddress = (addressData, fallbackAddress) => {
        for (const val of ['street', 'city', 'postcode', 'country_code']) {
          addressData[val] = getDefaultAddress(val, fallbackAddress)
        }
      }

      if (state.input.shippingBusinessName && state.input.shippingBusinessName.replace(/\s/g, '').length > 0) {
        if (
          state.input.isBusiness
            ? !!this.$themeSettings.components.PageCheckoutInfo.input.shippingBusinessName.enableWithBusiness
            : true
        ) {
          data.shipping.business_name = state.input.shippingBusinessName
        }
      }
      if (
        state.input.shipToShippingAddress &&
        state.input.shippingPhone &&
        state.input.shippingPhone.replace(/\s/g, '').length > 0
      ) {
        data.shipping.phone = state.input.shippingPhone
      }
      if (state.input.formalTitle) {
        data.custom.formalTitle = state.input.formalTitle
      }
      if (state.input.vatinsvk) {
        data.custom.ICDPH = state.input.vatinsvk
      }
      if (state.input.dateOfBirth) {
        data.custom.dateOfBirth = state.input.dateOfBirth
      }
      if (state.input.companyName) {
        data.custom.companyName = state.input.companyName
      }

      // Fill shipper branch address if custom billing address is disabled
      if (!state.input.billingInfo) {
        setShipperAddress(data.billing_address, {
          ...data.shipping.address,
          countryCode: data.shipping.address.country_code,
        }) // Set default billing address
        if (data.business) {
          data.business = null // Reset business data
        }
      }

      // Fill shipper branch address if address is not required
      if (state.input.shipper && state.input.shipper.requiredAddress === false) {
        setShipperAddress(data.shipping.address) // Set default shipping address
        if (data.shipping.business_name) {
          delete data.shipping.business_name // Remove shipping company name
        }
        if (data.shipping.phone) {
          delete data.shipping.phone // Remove shipping phone number
        }
      }

      if (rootGetters['utm/getUtmParams']) {
        data.utm = rootGetters['utm/getUtmParams']
      }

      await hookDispatch(hookDefinitions.CART.PLACE_ORDER.DATA_PROCESS, { actionContext, data })
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/place', data)
      const orderData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
      commit('SET_PLACED_ORDER', orderData)
      return response
    } catch (e) {
      console.log('PLACE_ORDER error:', e)
      return e.response
    }
  },
  async SET_CART_INPUT_DEFAULT({ commit, dispatch, state, rootState, rootGetters }) {
    commit('SET_CART_INPUT_FIELD', {
      field: 'formalTitle',
      value: get(rootState, 'user.userData.attributes.custom.formalTitle', null),
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'dateOfBirth',
      value: get(rootState, 'user.userData.attributes.custom.dateOfBirth', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'fullName', value: state.cartData.customer.name })
    commit('SET_CART_INPUT_FIELD', { field: 'firstName', value: state.cartData.customer.firstName })
    commit('SET_CART_INPUT_FIELD', { field: 'lastName', value: state.cartData.customer.lastName })
    commit('SET_CART_INPUT_FIELD', { field: 'companyName', value: '' })
    commit('SET_CART_INPUT_FIELD', { field: 'email', value: state.cartData.customer.email })
    commit('SET_CART_INPUT_FIELD', { field: 'phone', value: state.cartData.customer.phone })
    commit('SET_CART_INPUT_FIELD', {
      field: 'isBusiness',
      value: this.$themeSettings.global.billingInputsAlwaysRequired ? true : !!state.cartData.customer.tin,
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'newsletter',
      value:
        get(rootState, 'user.isLoaded', null) && get(state, 'cartData.customer.name', null) !== ''
          ? state.cartData.customer.newsletter
          : this.$themeSettings.components.PageCheckoutInfo.newsletter.defaultValue,
    })
    if (state.cartData.customer.businessName) {
      commit('SET_CART_INPUT_FIELD', { field: 'businessName', value: state.cartData.customer.businessName })
    }
    commit('SET_CART_INPUT_FIELD', { field: 'businessTerms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'terms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'customOrderNumber', value: '' })
    commit('SET_CART_INPUT_FIELD', { field: 'tin', value: state.cartData.customer.tin })
    commit('SET_CART_INPUT_FIELD', { field: 'vatin', value: state.cartData.customer.vatin })
    commit('SET_CART_INPUT_FIELD', {
      field: 'vatinsvk',
      value: get(rootState, 'user.userData.attributes.custom.ICDPH', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'street', value: state.cartData.shippingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'city', value: state.cartData.shippingAddress.city })
    if (rootGetters['user/userIsLoggedIn']) {
      commit('SET_CART_INPUT_FIELD', {
        field: 'country',
        value: get(rootState, 'user.userData.address.countryCode', null),
      })
    } else {
      const countryCodes = rootState.globals.countries.map(country => country.alpha3)

      if (!countryCodes.includes(state.input.country)) {
        const countryCode = rootState.globals.countries.find(
          country =>
            country.languageCode ===
            rootState.globals.languages.find(language => language.code === this.$i18n.locale).code,
        )
        if (countryCode) {
          commit('SET_CART_INPUT_FIELD', {
            field: 'country',
            value: countryCode.alpha3,
          })
        }
      }
    }
    commit('SET_CART_INPUT_FIELD', { field: 'postcode', value: state.cartData.shippingAddress.postcode })
    commit('SET_CART_INPUT_FIELD', { field: 'differentAddress', value: state.cartData.hasCustomShippingAddress })
    commit('SET_CART_INPUT_FIELD', { field: 'note', value: state.cartData.comment })
    commit('SET_CART_INPUT_FIELD', {
      field: 'shippingBusinessName',
      value: state.cartData.customer.shippingBusinessName,
    })
    commit('SET_CART_INPUT_FIELD', { field: 'billingStreet', value: state.cartData.billingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'billingCity', value: state.cartData.billingAddress.city })
    commit('SET_CART_INPUT_FIELD', { field: 'billingPostcode', value: state.cartData.billingAddress.postcode })
    commit('SET_CART_INPUT_FIELD', { field: 'billingCountry', value: state.cartData.billingAddress.countryCode })

    commit('SET_CART_INPUT_FIELD', { field: 'shippingFullName', value: state.cartData.customer.shippingName })
    commit('SET_CART_INPUT_FIELD', { field: 'shippingFirstName', value: state.cartData.customer.shippingFirstName })
    commit('SET_CART_INPUT_FIELD', { field: 'shippingLastName', value: state.cartData.customer.shippingLastName })

    if (state.cartData.hasCustomShippingAddress) {
      commit('SET_CART_INPUT_FIELD', { field: 'shipToShippingAddress', value: true })
    }

    dispatch('CHECK_FILLED_BILLING_DATA')
  },
}

export { actions, getters, mutations, state }
