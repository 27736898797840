<template>
  <div class="Wysiwyg">
    <slot/>
  </div>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
}
</script>
