export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    tag: {
      type: String,
      default: 'button',
    },
  },
}
