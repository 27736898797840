export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PRODUCT.CLICK, ({ product, positionInList, impressionList }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [
            {
              name: product.name,
              id: product.id,
              code: product.catalogCode,
              price: unitsWithTax ? product.prices.unitWithTax : product.prices.unitWithoutTax,
              category: product.categoryFullname,
              variant: product.defaultVariantId,
              list: impressionList,
              position: positionInList,
            },
          ],
        },
      },
    })
  })
}
