import Vue from 'vue'
import VueTruncate from 'vue-truncate-filter'

Vue.filter('prepositions', value => {
  if (typeof value !== 'string') return ''
  return value.replace(/(\s|^)(a|i|k|o|s|u|v|z)(\s(a|i|k|o|s|u|v|z))?(\s+)/gim, '$1$2$3\u00A0')
})

Vue.filter('capitalize', (value, shouldCapitalize = true) => {
  if (typeof value !== 'string') return ''
  if (!shouldCapitalize) return value
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('dateFormat', (date, lang, format) => {
  if (date) {
    return new Intl.DateTimeFormat(lang, format).format(typeof date === 'string' ? Date.parse(date) : date)
  }
  return ''
})

Vue.filter('phoneLink', value => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/ /g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
})

Vue.use(VueTruncate)
