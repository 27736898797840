import gql from 'graphql-tag'

export default {
  all: gql`
    fragment brandFragmentsAll on brand {
      _id
      image {
        alt
        path
        title
      }
      title
      link {
        ... on link_embedded {
          title
          link_type
          custom_link
          category_link {
            entity {
              ... on StoreCategory {
                data
              }
            }
          }
        }
      }
      linked_virtual_category {
        entity {
          ... on virtual_category {
            page_meta {
              slug
            }
          }
        }
      }
    }
  `,
}
