import gql from 'graphql-tag'

export default {
  all: gql`
    fragment articleFragmentsAll on article {
      _id
      date_published
      article_author_reference {
        entity {
          ... on author {
            _id
            author_has_page
            image {
              path
              alt
            }
            page_meta {
              slug
            }
            perex
            title
          }
        }
      }
      article_category_reference {
        entity {
          ... on article_category {
            _id
            page_meta {
              slug
            }
            title
            master_category {
              entity {
                ... on article_category {
                  _id
                  title
                  perex
                  page_meta {
                    slug
                  }
                }
              }
            }
          }
        }
      }
      article_tag_reference {
        entity {
          ... on article_tag {
            _id
            page_meta {
              slug
            }
            title
          }
        }
      }
      created
      content
      image {
        path
        alt
      }
      page_meta {
        seo_description
        seo_keywords
        seo_title
        meta_nofollow
        meta_noindex
        slug
        share_image
      }
      perex
      title
      short_title
    }
  `,
  box: gql`
    fragment articleFragmentsBox on article {
      _id
      date_published
      article_author_reference {
        entity {
          ... on author {
            _id
            author_has_page
            image {
              path
              alt
            }
            page_meta {
              slug
            }
            perex
            title
          }
        }
      }
      article_category_reference {
        entity {
          ... on article_category {
            _id
            page_meta {
              slug
            }
            title
            master_category {
              entity {
                ... on article_category {
                  _id
                  title
                  perex
                  page_meta {
                    slug
                  }
                }
              }
            }
          }
        }
      }
      article_tag_reference {
        entity {
          ... on article_tag {
            _id
            page_meta {
              slug
            }
            title
          }
        }
      }
      title
      short_title
      created
      perex
      image {
        path
        alt
      }
      page_meta {
        seo_description
        seo_keywords
        seo_title
        meta_nofollow
        meta_noindex
        slug
        share_image
      }
    }
  `,
}
