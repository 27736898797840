<template>
  <LazyComponent
    v-if="!disabled"
    v-bind="$attrs"
    :component-factory="componentFactory"
    :wrapper-class="wrapperClass"
    v-on="$listeners"/>
  <div v-else/>
</template>

<script>
import LazyComponent from '@theme/components/utils/LazyComponent'

export default {
  components: {
    LazyComponent,
  },
  inheritAttrs: false,
  data() {
    return {
      disabled: false,
    }
  },
}
</script>
