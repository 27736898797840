<script>
import ProductLinkMixin from '~/mixins/ProductLinkMixin'
import filter from 'lodash/filter'

export default {
  mixins: [ProductLinkMixin],
  props: {
    product: {
      type: Object,
      default: () => [],
    },
    isContentPage: Boolean,
  },
  computed: {
    productTags() {
      let tags = [...this.product.tags]

      if (this.quantityDiscount) {
        tags.push({
          color: this.$themeSettings.product.tagQuantityDiscountColor,
          text: this.$t('ProductMixin.tagQuantityDiscount', { discount: this.quantityDiscount }),
        })
      }

      if (!this.product.visibility.saleable) {
        tags.push({
          color: this.$themeSettings.product.tagNotSaleableColor,
          text: this.$t('ProductMixin.tagNotSaleable'),
        })
      }

      if (this.product.gifts.filter(gift => gift.availability.isAvailable).length > 0) {
        tags.push({
          color: this.$themeSettings.product.tagHasGift,
          text: this.$t('ProductMixin.tagHasGift'),
        })
      }

      if (
        this.product.freeShippers.length > 0 ||
        (this.$store.state.globals.configuration_products &&
          this.$store.state.globals.configuration_products.show_free_delivery_tag_by_price &&
          this.$store.getters['cart/freeShippingAt'] < this.product.prices.unitWithTax)
      ) {
        tags.push({
          color: this.$themeSettings.product.tagFreeShippers,
          text: this.$t('ProductMixin.tagFreeShippers'),
        })
      }

      if (this.$themeSettings.product.tagMultipleVisibilityOptions) {
        tags = filter(tags, tag => {
          let visible = true

          if ('visibility' in tag) {
            visible = this.isContentPage ? tag.visibility.content : tag.visibility.catalog
          }

          return visible
        })
      }

      return tags
    },
    productImageAlternateText() {
      return ({ variant = null }) => {
        const parts = []
        if (this.product.categoryName) {
          parts.push(this.product.categoryName)
        }
        parts.push(this.product.name)

        if (variant) {
          if (variant.marketingCode) {
            parts.push(variant.marketingCode)
          }
          if (variant.name && variant.name !== this.product.name) {
            parts.push(variant.name)
          }
        }
        return parts.join(' - ')
      }
    },
    variantsAvailabilityInWarehouses() {
      const warehouses = {}
      const defaultWarehouseShipper = this.$themeSettings.product.defaultWarehouseShipperCode
        ? this.$store.state.globals.shippers.find(
            shipper => shipper.code === this.$themeSettings.product.defaultWarehouseShipperCode,
          )
        : null
      this.product.variants.forEach(variant => {
        variant.warehouses.forEach(warehouse => {
          warehouse.shipper = defaultWarehouseShipper
        })
        warehouses[variant.id] = {
          online: variant.warehouses ? variant.warehouses.filter(w => w.virtual === false && w.stock > 0) : [],
          virtual: variant.warehouses ? variant.warehouses.filter(w => w.virtual === true && w.stock > 0) : [],
          allSorted: variant.warehouses
            ? this.mergeDuplicateWarehouses(variant.warehouses).sort((a, b) => {
                return b.stock - a.stock
              })
            : [],
        }
      })
      return warehouses
    },
    quantityDiscount() {
      let quantityDiscount = null
      this.product.discounts.forEach(discount => {
        if (discount.isNPlusOne && discount.active) {
          quantityDiscount = discount.name
        }
      })
      return quantityDiscount
    },
  },
  methods: {
    mergeDuplicateWarehouses(warehouses) {
      const warehouseIds = []
      let newWarehouses = []
      const reducedStocks = []
      let checkForDuplicateWarehouses = false
      warehouses.forEach(warehouse => {
        if (!warehouseIds.includes(warehouse.id)) {
          warehouseIds.push(warehouse.id)
          newWarehouses.push(warehouse)
        } else {
          checkForDuplicateWarehouses = true
        }
      })
      if (!checkForDuplicateWarehouses) {
        return warehouses
      }
      warehouseIds.forEach(wName => {
        const wSameStock = []
        warehouses.forEach(warehouse => {
          if (warehouse.id === wName) {
            wSameStock.push(warehouse.stock)
          }
        })
        reducedStocks.push(wSameStock.reduce((a, b) => a + b))
      })
      newWarehouses.forEach((nW, i) => {
        nW.stock = reducedStocks[i]
      })
      return newWarehouses
    },
    parameterValue(parameterEntityId) {
      const parameters =
        this.product &&
        this.product.content &&
        this.product.content.parameters &&
        this.product.content.parameters.length &&
        this.product.content.parameters.filter(p => p.entityId && p.entityId === parameterEntityId)
      return (
        parameters &&
        parameters.length &&
        parameters[0].valueEntities &&
        parameters[0].valueEntities.length &&
        parameters[0].valueEntities[0] &&
        parameters[0].valueEntities[0].title
      )
    },
  },
}
</script>
