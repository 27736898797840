<template lang="html">
  <div
    v-if="loading"
    class="LoadingBar"/>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.LoadingBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 0;
  height: 2px;
  background: rgba(0, 0, 0, 0.4);
  animation-name: LoadingBar-moving;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes LoadingBar-moving {
  0% {
    width: 0;
    margin-left: 0;
  }
  25% {
    width: 100%;
    margin-left: 0;
  }
  50% {
    width: 0;
    margin-left: 100%;
  }
  75% {
    width: 100%;
    margin-left: 0;
  }
  100% {
    width: 0;
    margin-left: 0;
  }
}
</style>
