<template>
  <span :id="mark.attrs.id"><slot/></span>
</template>

<script>
import WysiwygMarkMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygMarkMixin'

export default {
  mixins: [WysiwygMarkMixin],
}
</script>
