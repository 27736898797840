<template>
  <strong><slot/></strong>
</template>

<script>
import WysiwygMarkMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygMarkMixin'

export default {
  mixins: [WysiwygMarkMixin],
}
</script>
