<script>
export default {
  props: {
    foxentryInputName: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    foxentryConfigMode() {
      return this.$store.getters['foxentry/GET_IS_CONFIG_MODE']
    },
    foxentryEnabled() {
      return this.$store.getters['foxentry/GET_IS_ENABLE']
    },
  },
}
</script>
