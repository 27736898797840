import cartItemAdd from './cart.item.add'
import cartItemRemove from './cart.item.remove'
import checkoutStepLoad from './checkout.step_load'
import productClick from './product.click'
import productImpression from './product.impression'
import productPageView from './product.page_view'
import promotionClick from './promotion.click'
import promotionImpression from './promotion.impression'
import purchaseView from './purchase.view'

export default {
  register(eventContext) {
    if (eventContext.context.app.$contentSettings.events.enhancedEcommerce.enable) {
      ;[
        cartItemAdd,
        cartItemRemove,
        checkoutStepLoad,
        productClick,
        productImpression,
        productPageView,
        promotionClick,
        promotionImpression,
        purchaseView,
      ].forEach(callback => {
        callback(eventContext)
      })
    }
  },
}
