import { hookDefinitions, hookDispatch } from '~/hooks'
import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  const paths = [
    'cart.bulkOrder',
    'cart.checkout.currentStep',
    'cart.input.country',
    'cart.input.billingCountry',
    'cart.input.deliveryTimeSlot',
    'cart.input.payment',
    'cart.input.shipper',
    'cart.input.shipperBranch',
    'cart.placedOrder',
    'cart.selectedGifts',
    'compare.productIds',
    'globals.cookieConsentClosed',
    'globals.languageBanner',
    'globals.flashMessages',
    'globals.entryModalsClosed',
    'globals.redirectModalOpen',
    'globals.topBannerLastModifiedClosed',
    'product.lastVisitedIds',
    'user.sessionUuid',
    'utm.params',
  ]

  hookDispatch(hookDefinitions.PERSISTENT_STORAGE.PATHS_PROCESS, { paths })

  createPersistedState({
    key: 'storefront',
    paths,
  })(store)
}
