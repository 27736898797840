import { escape } from 'lodash'
import Vue from 'vue'
import articleFragments from '~/queries/fragments/article'
import gql from 'graphql-tag'

export const actions = {
  async CHANGE_QUERY({ commit, state, dispatch }, { location, query, page }) {
    try {
      if (this.$env.SAIMON_GATEWAY_URL && this.$env.SAIMON_SEARCH && this.$env.SAIMON_PROJECT) {
        if (query && query.length > 0) {
          try {
            const response = await this.$axios.post(
              `${this.$env.SAIMON_GATEWAY_URL}/${this.$env.SAIMON_PROJECT}/recommender/search/query`,
              {
                returnAllIds: true,
                context: {
                  projectId: this.$env.SAIMON_PROJECT,
                  userId: 'not-needed',
                  sessionId: 'not-needed',
                },
              },
              {
                timeout: 2000,
                params: {
                  queryString: query,
                },
              },
            )
            commit('SET_SAIMON_RESULTS', response.data)
          } catch (e) {
            console.log(e)
            // Do nothing
          }
        } else {
          commit('SET_SAIMON_RESULTS', null)
        }
      }
      commit('SET_QUERY', { location, query })
      if (query && query.length >= 3) {
        commit('START_LOADING', { location })
        const promises = []
        if (this.$themeSettings.global.searchAddsVirtualCategoriesToCategoryResults) {
          await dispatch('FETCH_CONTENT', { location })
        } else {
          promises.push(dispatch('FETCH_CONTENT', { location }))
        }
        if (location === 'catalog') {
          promises.push(dispatch('FETCH_MENU_ITEMS', { location }))
        }
        if (location === 'dropdown') {
          promises.push(dispatch('FETCH_PRODUCTS', { location }))
          promises.push(dispatch('FETCH_MENU_ITEMS', { location }))
        }
        await Promise.all(promises)
        commit('STOP_LOADING', { location })
      } else {
        commit('EMPTY_RESULTS', { location })
      }
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_MENU_ITEMS({ commit, state, dispatch }, { location }) {
    let ids = []
    if (state.saimonSearchResults && state.saimonSearchResults.results) {
      ids = state.saimonSearchResults.results
        .map(r => r.data && r.data.context && r.data.context.main_category_id)
        .filter(r => r)
    }
    const response = await dispatch(
      'menu/SEARCH_ITEMS',
      { queryParts: state[location].queryParts, location, ids },
      { root: true },
    )
    commit('SET_RESULTS', {
      location,
      type: 'menuItems',
      data: {
        entities: response,
        total: response.length,
      },
    })
  },
  async FETCH_PRODUCTS({ commit, state }, { location }) {
    try {
      const response = await this.$axios.$get(this.$env.CMS_URL + '/search', {
        params: {
          q: state[location].query,
          limit: 10,
        },
      })
      commit('SET_RESULTS', {
        location,
        type: 'products',
        data: {
          entities: response.entities,
          total: response.total,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_CONTENT({ commit, state }, { location, page }) {
    const search = state[location].query
    const limit = state[location].perPage || 10
    const skip = (page || 0) * limit
    try {
      const { siteCode } = this.$themeSettings || {}
      let client = this.app.apolloProvider.defaultClient
      const entitiesFields = {
        article: '',
        faq_question: '',
        virtual_category: `
          entities {
            _id
            title
            page_meta {
              slug
            }
          }
        `,
      }
      if (location === 'catalog') {
        entitiesFields.article = `entities {
          ...articleFragmentsBox
        }`
        entitiesFields.faq_question = `entities {
          _id
          title
        }`
      }
      let result
      if (state.saimonSearchResults && state.saimonSearchResults.content_ids) {
        result = await client.query({
          query: gql`
          ${location === 'catalog' ? articleFragments.box : ''}
          query ($skip: Int!, $limit: Int!, $search: String!, $ids: [String]!${
            siteCode ? ', $siteCode: String!' : ''
          }) {
            article(skip: $skip, limit: $limit, filters: { _id: $ids${siteCode ? ', site_enabled: [$siteCode]' : ''}}) {
              total
              ${entitiesFields.article}
            }
            faq_question(filters: { title: { search: $search } }) {
              total
              ${entitiesFields.faq_question}
            }
            virtual_category(filters: { title: { search: $search } }) {
              total
              ${entitiesFields.virtual_category}
            }
          }
        `,
          variables: {
            ids: state.saimonSearchResults.content_ids,
            search,
            skip,
            limit,
            ...(siteCode ? { siteCode } : {}),
          },
        })
      } else {
        result = await client.query({
          query: gql`
          ${location === 'catalog' ? articleFragments.box : ''}
          query ($skip: Int!, $limit: Int!, $search: String!${siteCode ? ', $siteCode: String!' : ''}) {
            article(skip: $skip, limit: $limit, filters: { title: { search: $search }${
              siteCode ? ', site_enabled: [$siteCode]' : ''
            }}) {
              total
              ${entitiesFields.article}
            }
            faq_question(filters: { title: { search: $search } }) {
              total
              ${entitiesFields.faq_question}
            }
            virtual_category(filters: { title: { search: $search } }) {
              total
              ${entitiesFields.virtual_category}
            }
          }
        `,
          variables: {
            search,
            skip,
            limit,
            ...(siteCode ? { siteCode } : {}),
          },
        })
      }

      commit('SET_RESULTS', {
        location,
        type: 'articles',
        data: {
          entities: result.data.article.entities || [],
          total: result.data.article.total,
        },
      })
      commit('SET_RESULTS', {
        location,
        type: 'faq_questions',
        data: {
          entities: result.data.faq_question.entities || [],
          total: result.data.faq_question.total,
        },
      })
      commit('SET_RESULTS', {
        location,
        type: 'virtual_categories',
        data: {
          entities: result.data.virtual_category.entities || [],
          total: result.data.virtual_category.total,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  saimonSearchResults: null,
  catalog: {
    loading: false,
    perPage: 10,
    query: '',
    queryParts: [],
    results: {
      articles: {
        entities: [],
        total: 0,
      },
      virtual_categories: {
        entities: [],
        total: 0,
      },
      faq_questions: {
        entities: [],
        total: 0,
      },
      menuItems: {
        entities: [],
        total: 0,
      },
    },
  },
  dropdown: {
    loading: false,
    perPage: 10,
    query: '',
    queryParts: [],
    results: {
      articles: {
        entities: [],
        total: 0,
      },
      virtual_categories: {
        entities: [],
        total: 0,
      },
      faq_questions: {
        entities: [],
        total: 0,
      },
      menuItems: {
        entities: [],
        total: 0,
      },
      products: {
        entities: [],
        total: 0,
      },
    },
  },
  headerSearchInputOpen: false,
})

export const mutations = {
  OPEN_HEADER_SEARCH_INPUT(state) {
    state.headerSearchInputOpen = true
  },
  CLOSE_HEADER_SEARCH_INPUT(state) {
    state.headerSearchInputOpen = false
  },
  START_LOADING(state, { location }) {
    state[location].loading = true
  },
  STOP_LOADING(state, { location }) {
    state[location].loading = false
  },
  SET_PER_PAGE(state, { location, perPage }) {
    state[location].perPage = perPage
  },
  SET_QUERY(state, { location, query }) {
    const cleanQuery = escape(query)
    state[location].query = cleanQuery
    state[location].queryParts = cleanQuery
      ? cleanQuery
          .replace(/\s+/g, '+')
          .split('+')
          .filter(s => s.length >= 3)
      : null
  },
  SET_RESULTS(state, { location, type, data }) {
    Vue.set(state[location].results[type], 'entities', data.entities)
    state[location].results[type].total = data.total
  },
  SET_SAIMON_RESULTS(state, data) {
    state.saimonSearchResults = data
  },
  EMPTY_RESULTS(state, { location }) {
    if (state[location].results.products) {
      state[location].results.products = {
        entities: [],
        total: 0,
      }
    }
    state[location].results.menuItems = {
      entities: [],
      total: 0,
    }
  },
}
