export default {
  APP_ROUTE_MIXIN: {
    BEFORE_MOUNT: 'APP_ROUTE_MIXIN.BEFORE_MOUNT',
  },
  CART: {
    PLACE_ORDER: {
      DATA_PROCESS: 'CART.PLACE_ORDER.DATA_PROCESS',
    },
  },
  PAGE: {
    PRODUCT: {
      DETAIL: {
        ASYNC_DATA_PROMISES: 'PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROMISES',
        ASYNC_DATA_PREPROCESS: 'PAGE.PRODUCT.DETAIL.ASYNC_DATA_PREPROCESS',
        ASYNC_DATA_PROCESS: 'PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROCESS',
      },
    },
  },
  PERSISTENT_STORAGE: {
    PATHS_PROCESS: 'PERSISTENT_STORAGE.PATHS_PROCESS',
  },
}
