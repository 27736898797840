<script>
import { get } from 'lodash'

export default {
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    foxentryConfigMode() {
      const { hash } = this.$route
      return !!((hash && hash.startsWith('#foxentryconfig')) || this.$cookies.get('foxentry-config'))
    },
    foxentryEnabled() {
      return this.$store.getters['foxentry/GET_IS_ENABLE']
    },
    foxentryId() {
      return (this.$contentSettings.foxentry || {}).id || false // Check if Foxentry ID is defined in settings
    },
    foxentryRouteEnabled() {
      const routes = get(this.$contentSettings, 'foxentry.routes')
      return routes.includes(this.$options.name) // Check if this route is enabled in settings
    },
  },
  created() {
    if (this.foxentryId) {
      this.$store.commit('foxentry/SET_IS_ENABLE', this.foxentryRouteEnabled || this.foxentryConfigMode) // Set Foxentry store state for this route
      this.$store.commit('foxentry/SET_IS_CONFIG_MODE', this.foxentryConfigMode) // Set Foxentry config mode
      this.$store.commit('foxentry/SET_ROUTE', this.$options.name) // Set Foxentry route
    }
  },
  beforeMount() {
    if (this.foxentryEnabled && !this.foxentryConfigMode) {
      // Register Foxentry on project load callback
      // See Foxentry manual for more information
      window.onFoxentryProjectLoad = () => {
        this.$store.dispatch('foxentry/FETCH_CONFIG') // Fetch external Foxentry configuration
      }
    }
  },
  methods: {
    foxentryInit() {
      const FOXENTRY_ID = this.foxentryId
      if (FOXENTRY_ID && (this.foxentryEnabled || this.foxentryConfigMode)) {
        if (this.foxentryConfigMode) {
          this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'isBusiness', value: true }) // Open business forms
          this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'shipToShippingAddress', value: true }) // Open shipping forms
        }
        try {
          let e = document.querySelector('head'),
            s = document.createElement('script')
          s.setAttribute('type', 'text/javascript')
          s.setAttribute('async', 'true')
          s.setAttribute('src', 'https://cdn.foxentry.cz/lib')
          e.parentNode.appendChild(s)
          s.onload = function() {
            window.Foxentry = new window.FoxentryBase(FOXENTRY_ID)
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
}
</script>
