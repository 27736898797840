<script>
import { get } from 'lodash'

export default {
  data() {
    return {
      defaultUnitPriceKeys: {
        withKey: 'unitWithTax',
        withoutKey: 'unitWithoutTax',
      },
      defaultTotalPriceKeys: {
        withKey: 'totalWithTax',
        withoutKey: 'totalWithoutTax',
      },
    }
  },
  methods: {
    getPriceSettings(type) {
      return get(this.$themeSettings, 'product.price.' + type, {})
    },
    getDiscountPricePercentage({ prices }) {
      const percentageDiscount = get(prices, 'percentageDiscount', 0)
      return percentageDiscount && Math.round(percentageDiscount)
    },
    getFormattedPrice({ amount = 1, currency, prices, type, withKey = 'unitWithTax', withoutKey = 'unitWithoutTax' }) {
      const { show } = this.getPriceSettings(type)
      const price = this.getNonformattedPrice({ amount, prices, type, withKey, withoutKey })
      return show && price && this.$currencyFormat(price, { ...(currency ? { currency } : {}) })
    },
    getNonformattedPrice({ amount = 1, prices, type, withKey = 'unitWithTax', withoutKey = 'unitWithoutTax' }) {
      const { withTax } = this.getPriceSettings(type)
      return get(prices, withTax ? withKey : withoutKey, 0) * amount
    },
    getFormattedPrices({ amount = 1, currency, prices }) {
      return {
        discountPercentage: this.getDiscountPricePercentage({ amount, currency, prices }),
        original: this.getOriginalPrice({ amount, currency, prices }),
        primary: this.getPrimaryPrice({ amount, currency, prices }),
        secondary: this.getSecondaryPrice({ amount, currency, prices }),
        totalPrimary: this.getTotalPrimaryPrice({ amount, currency, prices }),
        totalSecondary: this.getTotalSecondaryPrice({ amount, currency, prices }),
      }
    },
    getOriginalPrice({
      amount = 1,
      currency,
      prices,
      withKey = 'unitWithTax',
      withoutKey = 'unitWithoutTax',
      originalWithKey = 'unitOriginalWithTax',
      originalWithoutKey = 'unitOriginalWithoutTax',
    }) {
      const { withTax } = this.getPriceSettings('original')
      const unitOriginalPrice = get(prices, withTax ? originalWithKey : originalWithoutKey, 0)
      const unitPrice = get(prices, withTax ? withKey : withoutKey, 0)
      if (unitOriginalPrice && unitOriginalPrice > unitPrice) {
        return this.$currencyFormat(unitOriginalPrice * amount, {
          ...(currency ? { currency } : {}),
        })
      }
      return false
    },
    getPrimaryPrice({ amount, currency, prices }) {
      return this.getFormattedPrice({ amount, currency, prices, type: 'primary' })
    },
    getSecondaryPrice({ amount, currency, prices }) {
      return this.getFormattedPrice({ amount, currency, prices, type: 'secondary' })
    },
    getTotalPrimaryPrice({ amount, currency, prices }) {
      return this.getFormattedPrice({
        amount,
        currency,
        prices,
        type: 'totalPrimary',
        ...this.defaultTotalPriceKeys,
      })
    },
    getTotalSecondaryPrice({ amount, currency, prices }) {
      return this.getFormattedPrice({
        amount,
        currency,
        prices,
        type: 'totalSecondary',
        ...this.defaultTotalPriceKeys,
      })
    },
  },
}
</script>
