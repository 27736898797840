<template>
  <component
    v-if="href"
    :is="(isExternal || target) ? 'a' : 'nuxt-link'"
    :class="classes"
    :href="href"
    :rel="isExternal ? 'noopener noreferrer' : null"
    :target="target ? target : null"
    :to="!(isExternal || target) ? href : null"
  ><svgicon
    v-if="iconLeft"
    :icon="iconLeft"
    :alt="iconLeftAlt"
    class="svg-icon-offset-right"
  />{{ title }}<svgicon
    v-if="iconRight"
    :icon="iconRight"
    :alt="iconRightAlt"
    class="svg-icon-offset-left"
  /></component>
</template>

<script>
import { get } from 'lodash'
import AppButton from '@theme/components/atom/AppButton'
import FileMixin from '~/mixins/FileMixin'
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  components: {
    AppButton,
  },
  mixins: [FileMixin, WysiwygNodeMixin],
  data() {
    return {
      icons: {
        left: require('@icon/left.svg'),
        right: require('@icon/right.svg'),
        cart: require('@icon/cart.svg'),
        search: require('@icon/search.svg'),
      },
      iconsAlt: {
        left: 'Šipka - stránkování',
        right: 'Šipka - stránkování',
        cart: 'Nákupní košík',
        search: 'Hledání',
      },
    }
  },
  computed: {
    classes() {
      const classes = ['btn']
      classes.push(`btn-${this.color}`)
      if (this.size === 'large') {
        classes.push(`btn-lg`)
      }
      if (this.size === 'small') {
        classes.push(`btn-sm`)
      }
      if (this.float === 'block') {
        classes.push(`btn-block`)
      }
      if (this.float === 'left') {
        classes.push(`WysiwygButton--floatLeft`)
      }
      if (this.float === 'right') {
        classes.push(`WysiwygButton--floatRight`)
      }
      return classes
    },
    color() {
      return this.content.attrs && this.content.attrs.color && this.content.attrs.color.length
        ? this.content.attrs.color[0]
        : 'primary'
    },
    float() {
      return (
        this.content.attrs && this.content.attrs.float && this.content.attrs.float.length && this.content.attrs.float[0]
      )
    },
    href() {
      if (this.content.attrs.file && this.content.attrs.file[0]) {
        return this.cmsFileUrl(this.content.attrs.file[0].fileKey)
      }
      return this.content.attrs ? this.content.attrs.href[this.$store.state.locale.contentLocale] : ''
    },
    iconLeft() {
      //Because of backwards compatibility icons can be either Array containing a string value or string value
      const name =
        this.content.attrs &&
        this.content.attrs.icon_left &&
        this.content.attrs.icon_left.length &&
        this.transformIcon(
          Array.isArray(this.content.attrs.icon_left) ? this.content.attrs.icon_left[0] : this.content.attrs.icon_left,
        )
      return get(this.icons, name, false)
    },
    iconLeftAlt() {
      const name =
        this.content.attrs &&
        this.content.attrs.icon_left &&
        this.content.attrs.icon_left.length &&
        this.transformIcon(
          Array.isArray(this.content.attrs.icon_left) ? this.content.attrs.icon_left[0] : this.content.attrs.icon_left,
        )
      return get(this.iconsAlt, name, false)
    },
    iconRight() {
      //Because of backwards compatibility icons can be either Array containing a string value or string value
      const name =
        this.content.attrs &&
        this.content.attrs.icon_right &&
        this.content.attrs.icon_right.length &&
        this.transformIcon(
          Array.isArray(this.content.attrs.icon_right)
            ? this.content.attrs.icon_right[0]
            : this.content.attrs.icon_right,
        )
      return get(this.icons, name, false)
    },
    iconRightAlt() {
      //Because of backwards compatibility icons can be either Array containing a string value or string value
      const name =
        this.content.attrs &&
        this.content.attrs.icon_right &&
        this.content.attrs.icon_right.length &&
        this.transformIcon(
          Array.isArray(this.content.attrs.icon_right)
            ? this.content.attrs.icon_right[0]
            : this.content.attrs.icon_right,
        )
      return get(this.iconsAlt, name, false)
    },
    size() {
      return (
        this.content.attrs && this.content.attrs.size && this.content.attrs.size.length && this.content.attrs.size[0]
      )
    },
    isExternal() {
      return this.href.includes('://')
    },
    target() {
      return this.content.attrs && this.content.attrs.link_target_blank ? '_blank' : null
    },
    title() {
      return this.content.attrs ? this.content.attrs.title : null
    },
  },
  methods: {
    transformIcon(iconName) {
      switch (iconName) {
        case 'arrow-left':
          return 'left'
        case 'arrow-right':
          return 'right'
        case 'goods':
          return 'cart'
        case 'search':
          return 'search'
      }
      return iconName
    },
  },
}
</script>

<style lang="scss">
@import '~variables';

.WysiwygButton--floatLeft,
.WysiwygButton--floatRight {
  margin: 1rem 0;
}

.WysiwygButton--floatLeft,
.WysiwygButton--floatRight {
  margin: 0.25rem 0;
}
.WysiwygButton--floatLeft {
  float: left;
  margin-right: map-get($spacers, 3);
}

.WysiwygButton--floatRight {
  float: right;
  margin-left: map-get($spacers, 3);
}
</style>
