export default {
  props: {
    link: Object,
  },
  computed: {
    linkType() {
      const link = this.link
      let linkType = 'basicLink'
      if (link.link_type) {
        if (link.link_type.indexOf('category') === 0 && link.category_link && link.category_link.length === 1) {
          linkType = 'categoryLink'
        } else if (
          link.link_type.indexOf('article_category') === 0 &&
          link.article_category_link &&
          link.article_category_link.length === 1
        ) {
          linkType = 'articleCategoryLink'
        } else if (
          link.link_type.indexOf('article_tag') === 0 &&
          link.article_tag_link &&
          link.article_tag_link.length === 1
        ) {
          linkType = 'articleTagLink'
        } else if (
          link.link_type.indexOf('virtual_category') === 0 &&
          link.virtual_category_link &&
          link.virtual_category_link.length === 1
        ) {
          linkType = 'virtualCategoryLink'
        } else if (link.link_type.indexOf('custom_link') === 0 && link.custom_link) {
          linkType = 'customLink'
        }
      }
      return linkType
    },
    linkEntity() {
      let linkEntity = this.link
      switch (this.linkType) {
        case 'categoryLink':
          linkEntity = this.link.category_link[0].entity
          break
        case 'articleCategoryLink':
          linkEntity = this.link.article_category_link[0].entity
          break
        case 'articleTagLink':
          linkEntity = this.link.article_tag_link[0].entity
          break
        case 'virtualCategoryLink':
          linkEntity = this.link.virtual_category_link[0].entity
      }
      return linkEntity
    },
    href() {
      if (this.linkEntity.urlRewrite && this.linkEntity.urlRewrite[this.$i18n.locale]) {
        return this.linkEntity.urlRewrite[this.$i18n.locale]
      }
      let href = null
      switch (this.linkType) {
        case 'categoryLink':
          href = this.linkEntity.data ? this.$categoryLocalePath(this.linkEntity.data.slug) : '/'
          break
        case 'articleCategoryLink':
          href = this.linkEntity.page_meta
            ? this.localePath({
                ...(this.linkEntity.master_category && this.linkEntity.master_category.length
                  ? {
                      name: 'articleList-slug-child',
                      params: {
                        slug: this.linkEntity.master_category[0].entity.page_meta.slug,
                        child: this.linkEntity.page_meta.slug,
                      },
                    }
                  : {
                      name: 'articleList-slug',
                      params: { slug: this.linkEntity.page_meta.slug },
                    }),
              })
            : '/'
          break
        case 'articleTagLink':
          href = this.linkEntity.page_meta
            ? this.localePath({ name: 'articleTag-slug', params: { slug: this.linkEntity.page_meta.slug } })
            : '/'
          break
        case 'virtualCategoryLink':
          const slug = this.linkEntity.page_meta.slug
          href = this.$categoryLocalePath(slug)
          break
        case 'customLink':
          href = this.linkEntity.custom_link.replace(this.$env.STOREFRONT_URL, '')
          break
        case 'basicLink':
          href = this.linkEntity.path
      }
      return this.$pathTransform(href)
    },
    title() {
      let title = null
      if (this.link && this.link.link_title) {
        return this.link.link_title
      }
      switch (this.linkType) {
        case 'categoryLink':
          title = this.linkEntity.data ? this.linkEntity.data.seo_title : ''
          break
        case 'articleCategoryLink':
        case 'articleTagLink':
        case 'virtualCategoryLink':
        case 'basicLink':
          title = this.linkEntity.title
      }
      return title
    },
    isExternal() {
      return this.href.match(/((mailto|tel|sms|geo|market):)|:\/\//)
    },
    target() {
      return this.link.link_target_blank ? '_blank' : null
    },
  },
  methods: {
    clicked(e) {
      // Reset catalog if we are going to the same route
      if (this.$route.name.indexOf('all___') === 0) {
        if (!e.metaKey) {
          if (this.$route && this.href === this.$route.path) {
            this.$store.dispatch('catalog/RESET_CATALOG')
          }
        }
      }
    },
  },
}
