<script>
export default {
  methods: {
    formatUserName(value, type, anonymous) {
      if (type === 'short') {
        const nameArray = value.split(' ')
        if (nameArray.length > 1) {
          return `${nameArray[0]} ${nameArray[1].slice(0, 1)}.`
        }
        if (anonymous) {
          return this.$t(anonymous)
        }
      }
      return value
    },
    transformRatingToStars(value) {
      return (Number(value) / 100) * Number(this.$themeSettings.components.StarRating.starsCount)
    },
    transformStarsToRating(value) {
      return (Number(value) * 100) / Number(this.$themeSettings.components.StarRating.starsCount)
    },
  },
}
</script>
