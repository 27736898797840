import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.NOT_FOUND.PAGE_VIEW, () => {
  window.dataLayer.push({
    event: eventDefinitions.NOT_FOUND.PAGE_VIEW,
    not_found_url: location.href,
    not_found_referrer: document.referrer,
  })
})
