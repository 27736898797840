import { notifications } from '~/app/notifications'
import LoadingMixin from '~/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleLike(e) {
      if (e) {
        e.preventDefault()
      }
      if (this.variant) {
        if (this.disabled) {
          notifications.danger(this.$t('WishListIcon.notifications.loginFirst'))
        } else {
          if (!this.isLoading) {
            if (this.shoppingListItem) {
              this.removeFromWishList()
            } else {
              this.addToWishList()
            }
          }
        }
      } else {
        notifications.danger(this.$t('WishListIcon.notifications.error'))
      }
      return false
    },
    async addToWishList() {
      this.startLoading()
      await this.$store.dispatch('user/ADD_ITEM_TO_SHOPPING_LIST', { variant: this.variant })
      await this.$store.dispatch('user/FETCH_WISHLIST')
      this.stopLoading()
    },
    async removeFromWishList() {
      this.startLoading()
      if (this.shoppingListItem) {
        await this.$store.dispatch('user/REMOVE_ITEM_FROM_SHOPPING_LIST', { shoppingListItem: this.shoppingListItem })
        await this.$store.dispatch('user/FETCH_WISHLIST')
      }
      this.stopLoading()
    },
  },
  computed: {
    shoppingListItem() {
      return (
        this.variant &&
        this.$store.state.user.wishList &&
        this.$store.state.user.wishList.items.find(item => item.variantId === this.variant.id)
      )
    },
  },
}
