import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.USER.LOADED, ({ user }) => {
  if (user) {
    window.dataLayer.push({
      event: eventDefinitions.USER.LOADED,
      user_id: user.id,
      user_groups: user.groups,
    })
  }
})
