export default ({ app, error, route, redirect }) => {
  app.$pageNotFound = async ({ type, slug, path }) => {
    const response = await app.$axios.$get(`${app.$env.CMS_URL}/redirect`, {
      params: {
        route: route.path,
        path: path || route.path,
        slug,
        type,
      },
      validateStatus: false,
    })
    if (response && response.redirectPath && response.redirectPath !== (path || route.path)) {
      return redirect(301, response.redirectPath)
    } else {
      return error({ statusCode: 404 })
    }
  }
}
