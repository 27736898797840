<script>
export default {
  inject: {
    parentValidator: {
      default() {
        return null
      },
    },
  },
  props: {
    injectValidator: {
      type: Object,
      default() {
        return null
      },
    },
  },
  created() {
    const validator = this.injectValidator || this.parentValidator
    if (validator) {
      this.$validator = validator
    } else {
      console.error('No validator provided while injecting.')
    }
  },
}
</script>
