import { mutations, state as parentState } from '~/sites/cvb/store/locale'

const state = () => ({
  ...parentState(),
  canonicalDomains: [
    { language: 'cs', domain: 'https://ventilator-profis.de' },
    { language: 'de', domain: 'https://ventilator-profis.de' },
  ],
})

export { mutations, state }
