import { original_location } from '~/events/dataLayerBasic/global/original_location'

export default () => {
  window.dataLayer = window.dataLayer || []
  original_location()
  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  })
}
