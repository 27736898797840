import { get } from 'lodash'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  mixins: [HeadingLevel],
  props: {
    styles: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      return this.headingLevel ? `h${this.headingLevel}` : 'div'
    },
  },
  created() {
    // Trim whitespace from content of title
    if (get(this.$slots, 'default[0].text')) {
      this.$slots.default[0].text = this.$slots.default[0].text.trim()
    }
  },
}
