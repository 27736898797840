import { mutations, state as parentState } from '~/sites/shop/store/locale'

const state = () => ({
  ...parentState(),
  canonicalDomains: [
    { language: 'cs', domain: 'https://ventilatory-shop.cz' },
    { language: 'en', domain: 'https://ventilatory-shop.cz' },
  ],
})

export { mutations, state }
