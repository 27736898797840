export default {
  props: {
    branch: Object,
    product: Object,
    shipper: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deliveryDate() {
      return this.getDeliveryDate(this.product)
    },
    isToday() {
      const now = new Date()
      return this.isSameDate(now, this.deliveryDate)
    },
    isTomorrow() {
      const now = new Date()
      const tomorrow = now.setDate(now.getDate() + 1)
      return this.isSameDate(tomorrow, this.deliveryDate)
    },
    isPersonal() {
      return this.shipper?.type === 'PERSONAL'
    },
    cartDeliveryDate() {
      let deliveryDate = new Date()
      const items = this.$store.state.cart.cartData.items
      items.forEach(item => {
        item.shoppable.warehouses.forEach(warehouse => {
          if (warehouse.id === this.branch.warehouseId) {
            const computedDeliveryDate = this.getDeliveryDate(item.variant)
            if (warehouse.stock < item.amount && computedDeliveryDate > deliveryDate) {
              deliveryDate = computedDeliveryDate
            }
          }
        })
      })
      return deliveryDate
    },
    text() {
      let text = ''
      if (this.branch) {
        text = this.$t('DeliveryDate.pickUp', {
          date: this.$options.filters.dateFormat(this.cartDeliveryDate, this.$i18n.locale, {
            day: 'numeric',
            month: 'numeric',
          }),
        })
      } else {
        let date = ''
        if (this.isToday) {
          date = this.isPersonal ? this.$t('DeliveryDate.immediate') : this.$t('DeliveryDate.today')
        } else if (this.isTomorrow) {
          date = this.$t('DeliveryDate.tomorrow')
        } else {
          date = this.$options.filters.dateFormat(this.deliveryDate, this.$i18n.locale, {
            day: 'numeric',
            month: 'numeric',
            weekday: 'long',
          })
        }
        if (this.isPersonal) {
          text = this.$t('DeliveryDate.pickUp', { date: date })
        } else {
          text = this.$t('DeliveryDate.delivery', { date: date })
        }
      }
      return this.$options.filters.capitalize(this.$options.filters.prepositions(text))
    },
  },
  methods: {
    isSameDate(a, b) {
      return new Date(a).toDateString() === new Date(b).toDateString()
    },
    getDeliveryDate(product) {
      const shipperNearestDeliveryDate = this.shipper
        ? new Date(this.shipper?.delivery.nearestDeliveryDate)
        : new Date()
      const productDeliveryDelay = product && product.availability && product.availability.delayDays
      const productDeliveryDate = new Date()

      if (productDeliveryDelay && productDeliveryDelay > 0) {
        productDeliveryDate.setDate(productDeliveryDate.getDate() + productDeliveryDelay)
      }

      return productDeliveryDate > shipperNearestDeliveryDate ? productDeliveryDate : shipperNearestDeliveryDate
    },
  },
}
