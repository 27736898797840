import ProductDateOfStorage from '~/themes/base/components/product/ProductDateOfStorage'

export default {
  extends: ProductDateOfStorage,
  computed: {
    dateOfStorage() {
      return [
        {
          date_of_storage: this.$moment.utc().add(this.product.availability.delayDays, 'day'),
          amount: 1,
          embeddedUuid: 'embeddedUuid',
        },
      ]
    },
    // nearest() {
    //   return this.dateOfStorage[0]
    // },
  },
}
