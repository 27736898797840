import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from '@apollo/client/link/error'
import { setContext } from 'apollo-link-context'
import consola from 'consola'

export default function({ store, app }) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  })
  const cache = new InMemoryCache({ fragmentMatcher })
  const httpLink = createHttpLink({
    uri: `${app.$env.CMS_URL}/graphql`,
  })
  const customHeadersLinkMiddleware = setContext((_, { headers, ...context }) => {
    return {
      headers: {
        ...headers,
        ...{
          locale: app.i18n.locale,
          'x-inspishop-default-locale': app.$env.DEFAULT_LOCALE,
          'x-inspishop-currency': store.state.currency.activeCurrency.code,
          'x-inspishop-domain': app.$env.DOMAIN_CODE,
          'x-inspishop-user-variant': store.state.auth.variant,
          'x-inspishop-user-groups': store.state.auth.groups ? store.state.auth.groups.join(',') : '',
          'x-inspishop-translation-variant': store.state.locale.translationVariant || '',
        },
      },
      ...context,
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        consola.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )
    if (networkError && !graphQLErrors) consola.error(`[Network error]: ${networkError}`)
  })

  const link = ApolloLink.from([errorLink, customHeadersLinkMiddleware, httpLink])

  return {
    cache,
    defaultHttpLink: false,
    link,
    persisting: true,
  }
}
