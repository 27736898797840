import _ from 'lodash'
import catalogDataFragment from '~/queries/fragments/catalogDataFragment'
import categoryFragments from '~/queries/fragments/category'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'
import virtualCategoryFragments from '~/queries/fragments/virtualCategory'

export const actions = {
  async FETCH_TOP_CATEGORIES({ commit, dispatch }) {
    try {
      const cmsResponse = await this.app.apolloProvider.defaultClient.query({
        query: gql`
          query {
            configuration_top_categories {
              top_categories {
                ... on top_category {
                  top_virtual_categories {
                    entityId
                    entity {
                      ... on virtual_category {
                        title
                        page_meta {
                          slug
                        }
                      }
                    }
                  }
                  top_categories {
                    entityId
                    entity {
                      ... on StoreCategory {
                        data
                      }
                    }
                  }
                  title
                  top_category_item_type
                  top_category_image {
                    path
                    alt
                  }
                  top_category_links {
                    ... on link_embedded {
                      title
                      link_type
                      custom_link
                      category_link {
                        entity {
                          ... on StoreCategory {
                            data
                          }
                        }
                      }
                      virtual_category_link {
                        entity {
                          ... on virtual_category {
                            title
                            page_meta {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      commit('SET_TOP_CATEGORIES', cmsResponse.data.configuration_top_categories.top_categories)
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_CATEGORIES({ commit, dispatch }, { slugs, ids, checkPermissions }) {
    try {
      const filter = []
      if (slugs) {
        filter.push(`slugs=${slugs.join(',')}`)
      }
      if (ids) {
        filter.push(`ids=${ids.join(',')}`)
      }
      if (checkPermissions) {
        filter.push(`checkPermissions=true`)
      }
      const request = await this.$axios.$get(this.$env.CMS_URL + '/catalog/category?' + filter.join('&'))
      return request
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_VIRTUAL_CATEGORY({ commit, dispatch }, { slugs }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${virtualCategoryFragments.all}
          query virtual_category($slugs: [String]!) {
            virtual_category(filters: { page_meta: { slug: $slugs } }) {
              entities {
                ...virtualCategoryFragmentAll
              }
            }
          }
        `,
        variables: {
          slugs,
        },
      })
      const data = result.data.virtual_category.entities[0]
      if (data) {
        data.content = await processContentBuilder(data.content, dispatch)
        return data
      }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_CATEGORY_CMS({ dispatch }, { storeId }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${categoryFragments.all}
          query category($storeId: Float!) {
            category(filters: { store_id: [$storeId] }) {
              entities {
                ...categoryFragmentAll
              }
            }
          }
        `,
        variables: {
          storeId,
        },
      })
      result.data.category.entities[0].banner_catalog =
        result.data?.category?.entities[0]?.banner_catalog?.filter(bannerWrapper => {
          if (
            bannerWrapper.banner_catalog_reference &&
            Array.isArray(bannerWrapper.banner_catalog_reference) &&
            bannerWrapper.banner_catalog_reference.length > 0
          ) {
            let banner = bannerWrapper.banner_catalog_reference[0].entity
            if (!banner.time_limited) {
              return true
            } else if (banner.time_limited && banner.time_limit_start !== null && banner.time_limit_end !== null) {
              return (
                new Date(banner.time_limit_start).valueOf() < Date.now() &&
                Date.now() < new Date(banner.time_limit_end).valueOf()
              )
            }
          }
          return false
        }) || []
      const data = result.data.category.entities[0]
      if (data) {
        data.content = await processContentBuilder(data.content, dispatch)
        return data
      }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_CANONICAL_CATEGORIES({ commit, dispatch }, { catalogState, categoryStore }) {
    try {
      const selectedParameters = catalogState.parameters.filter(parameter => parameter.isSelected)
      if (categoryStore && selectedParameters.length > 0) {
        const selectedParametersIds = selectedParameters.map(parameter => parameter.id)
        const selectedValuesIds = selectedParameters
          .reduce((acc, parameter) => [...acc, ...parameter.values.filter(value => value.isSelected)], [])
          .map(value => value.id)

        let client = this.app.apolloProvider.defaultClient
        const result = await client.query({
          query: gql`
            ${catalogDataFragment.all('filtered_category')}
            query($parameterIds: [String], $valueIds: [String], $storeCategoryId: String) {
              filtered_category(
                filters: {
                  sliced_categories: { entity_id: [$storeCategoryId] }
                  parameters: { entity_id: $parameterIds, values: $valueIds }
                }
              ) {
                entities {
                  parameters {
                    entityId
                    values
                  }
                  title
                  ...catalogDataFragment_filtered_category
                }
              }
              virtual_category(
                filters: {
                  sliced_categories: { entity_id: [$storeCategoryId] }
                  parameters: { entity_id: $parameterIds, values: $valueIds }
                }
              ) {
                entities {
                  title
                  sliced_categories {
                    entityId
                  }
                  page_meta {
                    slug
                  }
                  parameters {
                    entityId
                    values
                  }
                }
              }
            }
          `,
          variables: {
            parameterIds: selectedParametersIds,
            valueIds: selectedValuesIds,
            storeCategoryId: String(categoryStore.id),
          },
        })
        let filteredCategory = result.data.filtered_category.entities.find(filteredCategory => {
          const filteredCategoryParameterIds = filteredCategory.parameters.map(parameter => parameter.entityId)
          const filteredCategoryValuesIds = filteredCategory.parameters.reduce(
            (acc, parameter) => [...acc, ...parameter.values],
            [],
          )
          return (
            _.isEqual(selectedParametersIds.sort(), filteredCategoryParameterIds.sort()) &&
            _.isEqual(selectedValuesIds.sort(), filteredCategoryValuesIds.sort())
          )
        })
        if (filteredCategory) {
          filteredCategory.content = await processContentBuilder(filteredCategory.content, dispatch)
        }
        return {
          canonicalVirtualCategory: result.data.virtual_category.entities.find(virtualCategory => {
            const virtualCategorySlicedCategories = virtualCategory.sliced_categories.map(category =>
              Number(category.entityId),
            )
            const virtualCategoryParameterIds = virtualCategory.parameters.map(parameter => parameter.entityId)
            const virtualCategoryValuesIds = virtualCategory.parameters.reduce(
              (acc, parameter) => [...acc, ...parameter.values],
              [],
            )
            return (
              virtualCategorySlicedCategories.length === 1 &&
              virtualCategorySlicedCategories[0] === categoryStore.id &&
              _.isEqual(selectedParametersIds.sort(), virtualCategoryParameterIds.sort()) &&
              _.isEqual(selectedValuesIds.sort(), virtualCategoryValuesIds.sort())
            )
          }),
          filteredCategory,
        }
      }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_ALL_CATEGORIES() {
    try {
      let fetchMore = true
      let categories = []
      let page = 1
      while (fetchMore) {
        const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/product-categories', {
          params: { perPage: 200, page },
        })
        categories = [...categories, ...response.data]
        page += 1
        fetchMore = response.currentPage !== response.totalPages
      }
      return categories.sort((a, b) => a.name.localeCompare(b.name))
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  topCategories: [],
})

export const mutations = {
  SET_TOP_CATEGORIES(state, data) {
    if (data) {
      state.topCategories = data
    }
  },
}
