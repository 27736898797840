export default function itemTransformer(item, amount) {
  return item
    ? {
        name: item.product.name,
        id: item.shoppable.id,
        category: item.product.categoryFullname,
        variant: item.shoppable.name !== item.product.name ? item.shoppable.name : '',
        variantCode: item.shoppable.marketingCode,
        quantity: amount ? amount : item.amount ? item.amount : '',
        prices: {
          unitWithTax: item.prices.unitWithTax,
          unitWithoutTax: item.prices.unitWithoutTax,
        },
      }
    : null
}
