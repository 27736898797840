<script>
export default {
  methods: {
    getProductPath(product, query = {}) {
      let link = this.localePath({ name: 'product-slug', params: { slug: product.slug }, query })

      if (this.$contentSettings.functions.productWildcard.enable) {
        link = this.wildcardRoute(product, query)
      }

      return link
    },
    /**
     * Create slug combination for wildcard product path consisting of category slug and product slug
     * @param {{}} product
     * @param {string|number} product.categoryId
     * @param {string[]} product.categoryIds
     * @param {string[]} product.slug
     * @returns {string}
     */
    wildcardSlug(product = this.product) {
      const category = product.categoryId
        ? this.$store.getters['menu/menuItemsForCategory']([product.categoryId], 'mainMenu')[0]
        : null
      return [category?.path?.replace('/', ''), product.slug].join('/')
    },
    /**
     * Create path for wildcard product route
     * @param {{}} product
     * @param {string|number} product.categoryId
     * @param {string[]} product.categoryIds
     * @param {string[]} product.slug
     * @param {{}} query
     * @returns {string}
     */
    wildcardRoute(product = this.product, query = {}) {
      return this.localePath({
        name: this.$contentSettings.functions.productWildcard.link,
        params: [this.wildcardSlug(product)],
        query,
      })
    },
  },
}
</script>
