<template>
  <div class="WysiwygClearer"/>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
}
</script>

<style lang="scss">
.WysiwygClearer {
  clear: both;
}
</style>
