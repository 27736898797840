import _ from 'lodash'

export const actions = {
  async LOAD_CATALOG({ commit, dispatch, rootState }, { catalogScope, catalogSettings }) {
    try {
      const scopeString = encodeURI(JSON.stringify(catalogScope))
      const request = await this.$axios.$get(this.$env.CMS_URL + `/api/catalog/v2/state?scope=${scopeString}`)
      const catalogState = request

      // Append catalog settings data to scope
      catalogState.parameters.forEach(p => {
        if (catalogSettings) {
          const settingsParameter = _.find(catalogSettings.parameters, { entityId: p.id })
          p.importantFilter = settingsParameter.importantFilter
          p.closedFilter = settingsParameter.closedFilter
        }
      })

      return catalogState
    } catch (e) {
      console.log(e)
    }
  },
  async RESET_CATALOG({}) {
    this.$catalogEventBus.$emit('catalog.reset')
  },
  async UPDATE_FILTERS({}) {
    this.$catalogEventBus.$emit('catalog.updateFilters')
  },
}

export const state = () => ({
  loading: false,
  slug: '',
})

export const mutations = {
  START_LOADING(state) {
    state.loading = true
  },
  STOP_LOADING(state) {
    state.loading = false
  },
  SET_SLUG(state, slug) {
    state.slug = slug
  },
}
