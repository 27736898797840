import BigTitleBlock from '@theme/blocks/BigTitleBlock'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import TopCategory from '@theme/components/category/TopCategory'

export default {
  components: {
    BigTitleBlock,
    TopCategory,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'categories',
    title: {
      cs: 'Kategorie',
    },
    fields: [
      {
        embeddedType: 'top_category',
        multi: true,
        name: 'categories',
        title: {
          cs: 'Kategorie',
        },
        type: 'embedded',
      },
    ],
  },
  computed: {
    titleBlock() {
      return {
        background_color: this.block.block_title_background_color,
        text_color: this.block.block_title_text_color,
        text: this.block.block_title,
      }
    },
  },
  query() {
    return `
      ... on block_categories {
        uuid
        type
        block_title
        block_title_background_color
        block_title_text_color
        categories {
          ... on top_category {
            top_virtual_categories {
              entityId
              entity {
                ... on virtual_category {
                  title
                  page_meta {
                    slug
                  }
                }
              }
            }
            top_categories {
              entityId
              entity {
                ... on StoreCategory {
                  data
                }
              }
            }
            title
            top_category_item_type
            top_category_image {
              path
              alt
            }
            top_category_links {
              ... on link_embedded {
                title
                link_type
                custom_link
                category_link {
                  entity {
                    ... on StoreCategory {
                      data
                    }
                  }
                }
                virtual_category_link {
                  entity {
                    ... on virtual_category {
                      title
                      page_meta {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  },
}
