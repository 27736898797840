var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isSvg)?_c('img',{class:_vm.imageClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'eager',"src":_vm.svgSource,"alt":_vm.alt,"title":_vm.title,"width":_vm.width,"height":_vm.height}}):_c('span',{staticClass:"AppImage",class:[_vm.visible ? 'AppImage--imageLoaded' : null]},[_c('picture',[((_vm.visible || _vm.isIE11) && !_vm.isGif)?[(_vm.srcsetBreakpoints)?[(!_vm.webpDisabled)?_vm._l((_vm.srcsetBreakpoints),function(item){return _c('source',{key:`webp${item.viewportMinWidth}${_vm._uid}`,attrs:{"media":`(min-width: ${item.viewportMinWidth}px)`,"srcset":`${_vm.imageUrl({
              ..._vm.srcsetBreakpointsSettings,
              pixelRatio: 2,
              width: item.imageWidth,
              height: _vm.imageRatio ? Math.floor(_vm.imageRatio * item.imageWidth) : null,
              webP: true,
            })} 2x, ${_vm.imageUrl({
              ..._vm.srcsetBreakpointsSettings,
              width: item.imageWidth,
              height: _vm.imageRatio ? Math.floor(_vm.imageRatio * item.imageWidth) : null,
              webP: true,
            })} 1x`,"type":"image/webp"}})}):_vm._e(),_vm._l((_vm.srcsetBreakpoints),function(item){return _c('source',{key:`${item.viewportMinWidth}${_vm._uid}`,attrs:{"media":`(min-width: ${item.viewportMinWidth}px)`,"srcset":`${_vm.imageUrl({
            ..._vm.srcsetBreakpointsSettings,
            imageStyle: item.imageWidth ? 'custom' : 'original',
            pixelRatio: 2,
            width: item.imageWidth,
            height: _vm.imageRatio ? Math.floor(_vm.imageRatio * item.imageWidth) : null,
          })} 2x, ${_vm.imageUrl({
            ..._vm.srcsetBreakpointsSettings,
            imageStyle: item.imageWidth ? 'custom' : 'original',
            width: item.imageWidth,
            height: _vm.imageRatio ? Math.floor(_vm.imageRatio * item.imageWidth) : null,
        })}`,"type":"image/*|jpeg|png/*"}})})]:[(!_vm.webpDisabled)?[_c('source',{attrs:{"srcset":_vm.imageStyle === 'original' ? _vm.sourcesWebp.normal : `${_vm.sourcesWebp.retina} 2x, ${_vm.sourcesWebp.normal} 1x`,"type":"image/webp"}})]:_vm._e(),_c('source',{attrs:{"srcset":_vm.imageStyle === 'original' ? _vm.sources.normal : `${_vm.sources.retina} 2x, ${_vm.sources.normal} 1x`,"type":"image/*|jpeg|png/*"}})]]:_vm._e(),_c('img',{class:_vm.imageClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'eager',"alt":_vm.alt,"title":_vm.title,"width":_vm.width,"height":_vm.height,"image-aria-labelledby":_vm.imageAriaLabelledBy,"src":(_vm.visible || _vm.isIE11) ? (_vm.isGif || _vm.webpDisabled) ? _vm.sourcesOriginal.normal : _vm.sourcesWebp.normal : (_vm.lazyThumbnail ? _vm.lazyThumbnailSrc :'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }