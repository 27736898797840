<template>
  <AppTitle
    :heading-level="level"
    :class="{
      'Title--beta': level === 2,
      'Title--gama': level === 3,
      'Title--delta': level === 4,
      'Title--epsilon': level === 5 }">
    <slot/>
  </AppTitle>
</template>

<script>
import AppTitle from '@theme/components/atom/AppTitle'
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  components: {
    AppTitle,
  },
  mixins: [WysiwygNodeMixin],
  computed: {
    level() {
      return this.content.attrs ? Number(this.content.attrs.level) : null
    },
  },
}
</script>
