import Vue from 'vue'

export const notification = (text, type, options) => {
  const mergedOptions = Object.assign({ duration: 3000 }, options)
  Vue.notify({
    text:
      text +
      (mergedOptions.duration && mergedOptions.timer
        ? `<span class="vue-notification-timer" style="animation-duration: ${mergedOptions.duration}ms;"></span>`
        : ''),
    type: type,
    ...mergedOptions,
  })
}

export const defaultError = () => {
  // TODO: Make translation $t('globals.notification.general500.title') accessible here!
  Vue.notify({
    title: 'Něco se porouchalo',
    text: 'Omlouváme se, pokud se problém opakuje, kontaktujte nás.',
    type: 'danger',
  })
}

export const errorFromResponse = response => {
  if (response && response.data && response.data.errors && response.data.errors[0]) {
    const notificationText = response.data.errors[0].field
      ? `${response.data.errors[0].field} - ${response.data.errors[0].title}`
      : response.data.errors[0].title
    notification(notificationText, 'danger')
  } else {
    defaultError()
  }
}

export const notifications = {
  info(text) {
    notification(text, 'info')
  },
  success(text) {
    notification(text, 'success')
  },
  warning(text) {
    notification(text, 'warning')
  },
  danger(text) {
    notification(text, 'danger')
  },
}
