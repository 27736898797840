import Vue from 'vue'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

// Register all event listeners
import '~/events'
import eventGroups from '~/events/newIndex'

const EventBusPlugin = {
  install(Vue) {
    Vue.prototype.$eventBus = eventBus
  },
}

Vue.use(EventBusPlugin)

export default function(context) {
  const { app, store } = context
  eventBus.$store = store

  // TODO: Create EventBus instance here, once everything is moved to new event style
  app.$eventBus = eventBus
  if (store) store.$eventBus = eventBus

  // Register events with injected context
  eventGroups.forEach(eventGroup => {
    eventGroup.register({ eventBus, eventDefinitions, context })
  })
}
