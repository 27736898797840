export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PRODUCT.IMPRESSION, ({ product, positionInList, impressionList }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    window.dataLayer.push({
      event: 'productImpression',
      ecommerce: {
        currencyCode: product.prices.currencyCode,
        impressions: [
          {
            name: product.name,
            id: product.id,
            price: unitsWithTax ? product.prices.unitWithTax : product.prices.unitWithoutTax,
            category: product.categoryFullname,
            list: impressionList,
            position: positionInList,
          },
        ],
      },
    })
  })
}
