export default {
  catalog: {
    defaultSortType: 'DefaultSort',
    bestsellers: {
      enable: false,
      limit: 3,
    },
  },
  checkout: {
    methodsInputType: 'checkbox',
    cardStyle: true,
    cardStyleBordered: true,
    enableCartDataValidation: false,
    enablePromoCode: true,
    enableCustomOrderNumber: false,
  },
  global: {
    billingInputsAlwaysRequired: false,
    enableSeoKeywords: true,
    blacklistedCanonicalParameters: [
      'a_box',
      'a_cha',
      'a_chan',
      'gclid',
      'nf',
      'price',
      'sort',
      'stock',
      'tags',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ],
    gridBreakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    gridColumns: 24,
    searchInContent: false,
    searchAddsVirtualCategoriesToCategoryResults: false,
    showAvailability: true,
    showBillingInputs: true,
    showProductsRecommended: false,
    userFullName: true,
    userLinks: {
      type: 'link', // 'modal' || 'link' || 'dropdown'
      login: {
        enable: true,
      },
      register: {
        enable: false,
      },
    },
  },
  product: {
    defaultWarehouseShipperCode: null,
    price: {
      other: {
        withTax: true,
      },
      original: {
        withTax: true,
      },
      primary: {
        label: false,
        show: true,
        withTax: true,
      },
      secondary: {
        label: false,
        show: false,
        withTax: false,
      },
      totalPrimary: {
        label: false,
        show: true,
        withTax: true,
      },
      totalSecondary: {
        label: false,
        show: false,
        withTax: false,
      },
      summaryTotalPrimary: {
        label: false,
        show: true,
        withTax: true,
      },
      summaryTotalSecondary: {
        label: false,
        show: true,
        withTax: false,
      },
    },
    shipperListHiddenShipperCodes: [], // Hides selected shippers from the list, used in combination with warehouses
    tagFreeShippers: 'green',
    tagHasGift: 'purple',
    tagNotSaleableColor: 'gray',
    tagQuantityDiscountColor: 'red',
    tagMultipleVisibilityOptions: false,
  },
  selectBoxIcon: true,
  components: {
    AddToCartModalItemAdded: {
      alertAlwaysOnTop: false,
      buttonCheckoutIcon: true,
      buttonContinueShoppingIcon: true,
      buttonCheckoutIconSize: 'sm',
      buttonContinueShoppingIconSize: 'sm',
      displayedProducts: null, // accessory, related, recommended or null
      itemLayoutInOneLineMobile: true,
      modalWindowMaxWidth: 'lg',
      showAvailableGifts: true,
      showFreeDelivery: true,
      showChangedAmount: true,
      showChangeAmountInput: false,
    },
    AddToCartModalChooseVariant: {
      modalWindowMaxWidth: 'lg',
    },
    AppNumber: {
      maxValueLength: 4,
      maxUnitLength: 3,
      minusIcon: false,
      plusIcon: false,
    },
    AppSelect: {
      useMultiSelect: false,
    },
    ArticleBox: {
      articleInfo: {
        author: {
          enable: true,
          icon: true,
          showLink: false,
          image: {
            width: 25,
            height: 25,
            enable: false,
          },
        },
        category: {
          enable: true,
          icon: true,
          maxCount: 2,
        },
        date: {
          enable: true,
          icon: true,
        },
        enable: false,
        tag: {
          enable: true,
          icon: true,
          maxCount: 1,
        },
      },
      image: {
        crop: true,
        enable: true,
        height: 220, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 385, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'link', // link, primary or secondary
        enable: false,
      },
      perex: {
        enable: true,
      },
      showIcons: false,
    },
    ArticleList: {
      perLine: {
        xs: 1,
        lg: 2,
      },
      perPage: 15,
    },
    MenuHorizontal: {
      countPerLine: {
        md: 3,
        lg: 3,
        xl: 4,
      },
      fullViewportWidthWrapper: false,
      showHeading: false,
    },
    ArticleTeaser: {
      articleInfo: {
        date: {
          enable: true,
        },
      },
      image: {
        crop: true,
        enable: true,
        height: 140, // Change SASSs variable $ArticleTeaser-image-height
        position: 'top', // top, left
        width: 140, // Change SASSs variable $ArticleTeaser-image-width
      },
    },
    AuthorBox: {
      image: {
        crop: true,
        height: 80,
        width: 80,
      },
    },
    BranchBox: {
      showClosedWeekendDays: false,
      showExtraOpeningHours: true,
    },
    BranchDetailModal: {
      modalWindowMaxWidth: 'md',
    },
    BranchLine: {
      address: {
        street: true,
        city: true,
        postcode: true,
        country: true,
        openingHours: false,
      },
      modalDetail: {
        enable: false,
      },
    },
    BranchSelector: {
      modalWindowMaxWidth: 'md',
    },
    BranchSelectorInline: {
      showDeliveryDate: false,
    },
    BranchSelectorPPL: {
      countriesAvailable: 'cz,sk,pl,de',
      hiddenPoints: '',
      stripBranchCode: true, // Remove KM from branch code
    },
    BranchesMap: {
      filter: {
        enable: false,
        fullViewportWidthWrapper: true,
      },
      map: {
        fullViewportWidth: true,
      },
      markers: {
        displayOpeningHours: true,
      },
      branchesList: {
        enabled: true,
        fullViewportWidth: true,
        containerClass: '',
      },
    },
    Breadcrumbs: {
      autoHideHomeIcon: true,
      enable: true, // If disabled, consider disabling at PageProductDetail and PageArticleDetail.
      separatorIcon: false,
    },
    BreadcrumbFirstItem: {
      showIcon: true,
      showText: false,
    },
    CatalogFilters: {
      showInStockFilter: true,
      showPriceFilter: true,
      showTagFilter: true,
    },
    CatalogFiltersBox: {
      toggleIcon: {
        closed: false,
        opened: true,
      },
    },
    CatalogFiltersActive: {
      showParameterName: false,
    },
    CatalogFiltersMobile: {
      button: 'primary', // primary, secondary, cta
    },
    CatalogFiltersParameterDropdown: {
      dropdownFullWidth: false,
      dropdownTargetElementId: '',
    },
    CatalogFiltersWidgetCheckboxes: {
      showValuesCount: 7,
    },
    CatalogFiltersWidgetSelect: {
      showLabel: false,
    },
    CatalogSorting: {
      enable: true,
      options: [], // See available options in CatalogSorting.js
    },
    CartInfo: {
      showPrice: true,
    },
    CartInfoDropDownContent: {
      buttonCheckoutIcon: true,
      showBottomLine: true,
      showCartItemAmount: false,
      showCartItemAmountOutlined: true,
      showDeliveryDate: true,
      showFreeDelivery: true,
      showFreeGift: true,
    },
    Carousel: {
      showNavigation: true,
      showNavigationOnMobile: false,
      showPagination: false,
      showPartOfNextProduct: true,
    },
    CatalogSubCategories: {
      countPerLine: {
        md: 3,
        lg: 3,
        xl: 4,
      },
      enable: true,
      fullViewportWidthWrapper: false,
      image: {
        height: 64,
        width: 64,
      },
      showHeading: false,
    },
    CheckoutLoginLineHook: {
      loginLink: {
        type: 'link', // link, dropdown, modal
      },
    },
    CompareProduct: {
      ProductBox: {
        enable: true,
        breakpoint: 'lg',
        showCategory: true,
      },
      imageWidth: 255,
      imageHeight: 270,
    },
    ContactBox: {
      alignContent: 'left',
      button: {
        enable: true,
        style: 'primary',
      },
      sticky: {
        alwaysVisible: true,
        enable: false,
        stickyAt: 'vh/5',
      },
    },
    ContactBoxImage: {
      image: {
        width: 63,
        height: 63,
      },
    },
    ContentBuilder: {
      categories: {
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
        },
      },
      products: {
        carousel: false,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
        },
      },
    },
    ContentHeader: {
      image: {
        crop: true,
        enableAtBreakpoint: null, // null ~ always visible
        height: 480,
        required: true,
        width: 1920,
        upscale: false,
      },
      showBreadcrumbs: true,
      showDescription: true,
      showPerex: false,
    },
    CookieConsent: {
      enable: true,
      button: 'secondary',
      fullViewportWidthWrapper: true,
      position: 'bottom', // top, bottom
    },
    CheckoutHeader: {
      contentDirection: 'vertical', // horizontal, vertical
      showIcons: true,
    },
    CheckoutItemLine: {
      showItemAvailabilityAmount: true,
      showUnitPriceOnMobile: true,
      showPercentageDiscount: false,
      showDeliveryDate: true,
      showOriginalPrice: false,
    },
    CheckoutLoginDropdown: {
      position: 'bottom-start',
    },
    CheckoutMethodsPayment: {
      showPrice: true,
    },
    CheckoutMethodsShipper: {
      descriptionPosition: 'tooltip', // below, tooltip
      selectFirstBranchByDefault: false,
      showBranchSelector: true,
      showDeliveryDate: true,
      showPrice: true,
    },
    CheckoutRecapBox: {
      border: true,
      background: false,
      disableDiscountProductImage: true,
      showPrice: true,
      showTotalSecondaryPrice: false,
    },
    CurrencySwitcher: {
      displayNameType: 'symbol', // code, name, symbol
      dropdown: true,
      dropdownTriggerType: ['click', 'focus'], // click, focus, hover, keyup
      enable: false, // Set `$currencySwitcherEnable` in `_variables.scss`
      icon: false,
    },
    DisplayBothPricesSwitcher: {
      displayNameType: 'symbol', // code, name, symbol
      dropdown: true,
      dropdownTriggerType: ['hover', 'focus'], // click, focus, hover, keyup
      enable: false, // Set `$DisplayBothPricesSwitcher` in `_variables.scss`
      icon: false,
    },
    DisplayVATSwitcher: {
      displayNameType: 'symbol', // code, name, symbol
      dropdown: true,
      dropdownTriggerType: ['hover', 'focus'], // click, focus, hover, keyup
      enable: false, // Set `$currencySwitcherEnable` in `_variables.scss`
      icon: false,
    },
    EmployeeBox: {
      imageWidth: 200,
      imageHeight: 200,
      showIcons: false,
    },
    EmployeeList: {
      fullViewportWidthWrapper: false,
      perLine: 5,
      showHeading: true,
    },
    EmptyCart: {
      iconOnRight: false,
    },
    EntryModal: {
      enable: false,
      modalWindowMaxWidth: 'sm',
    },
    FacebookWidgetPage: {
      enable: false,
      enableOnMobile: false,
      src:
        'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Finspirumcz%2F&tabs=timeline&width={width}&height={height}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=2487479588147465',
      width: 340,
      height: 260,
    },
    FeaturedArticles: {
      type: 'ArticleBox', // ArticleBox, ArticleTeaser
      fullViewportWidthWrapper: false,
      image: {
        position: 'top', // top, left
      },
      linkAllArticles: {
        button: 'link', // link, primary or secondary
        enable: false,
      },
      perLine: {
        md: 2,
        lg: 3,
      },
    },
    FittingGuideModal: {
      modalWindowMaxWidth: 'md',
    },
    FooterBottom: {
      paymentMethods: {
        enable: true,
        items: [],
      },
      showContact: true,
      showCopyright: true,
      showSocialNetworkLinks: false,
    },
    FooterContact: {
      email: {
        icon: true,
        label: false,
      },
      phone: {
        icon: true,
        label: false,
      },
      showSocialNetworkLinks: false,
    },
    FooterMain: {
      alignContent: 'left', // left, center, right
      mobileMenuTogglerActive: false,
      mobileMenuTogglerIconClosed: 'plus',
      mobileMenuTogglerIconOpened: 'minus',
      showContact: false,
      showSocialNetworkLinks: true,
    },
    FrequentQuestionBox: {
      enableAccordion: false,
      showFaqCategories: true,
      showFaqCategoryIcon: false,
    },
    GoogleMap: {
      marker: {
        image: {
          crop: true,
          enable: false,
          height: 100,
          width: 200,
        },
      },
    },
    GiftModal: {
      modalWindowMaxWidth: 'lg',
    },
    HeaderMain: {
      cartInfoAlignment: 'align-items-md-stretch',
      searchSizeClass: 'col-md-11 col-lg-7',
      enableStickyOnMobile: false,
      fullViewportWidthWrapper: true,
      headerMainHookForceEnable: {
        enable: false,
        breakpoint: '',
      },
      loginLink: {
        enable: false,
        type: 'modal',
        showLabel: true,
      },
      logo: {
        enable: true,
        position: 'left',
      },
      mobileSearchAutocomplete: true, // Requires HeaderSearch enabled
      registerLink: {
        enable: false,
        type: 'modal',
        showLabel: true,
      },
      showContactInfo: false,
      showContentColumn: true,
      showHeaderTopMenu: false,
      showHeaderMainHook: true,
      showUserMenu: false,
      showCurrencySwitcher: false,
      showLanguageSwitcher: false,
      showSearchInput: true,
    },
    HeaderSearch: {
      enable: true,
      breakpoint: {
        hide: 'md',
        show: null,
      },
      close: {
        enable: true,
      },
      contactInfo: false,
    },
    HeaderTop: {
      fullViewportWidthWrapper: true,
      info: true,
      loginLink: {
        enable: true,
        type: 'link', // link, dropdown, modal
      },
      LogoutButton: {
        button: 'secondary', // 'transparent', utility class ['primary'/'secondary'...] or leave empty to display as link
      },
      showCurrencySwitcher: true,
      showRegistrationLink: true,
    },
    HomepageBrands: {
      brandBox: {
        imageClass: '',
        showTitle: false,
      },
      fullViewportWidthWrapper: false,
      linkAllBrands: {
        button: 'primary', // link, primary or secondary
        enable: false,
      },
      perLine: {
        xs: 2,
        md: 3,
        lg: 5,
      },
    },
    ImageZoomOnHover: {
      zoomImageWidth: 1600, // Number or null
      zoomImageHeight: null, // Number or null
    },
    IssueFormInModal: {
      modalWindowMaxWidth: 'md',
    },
    JavascriptRequired: {
      enable: true,
    },
    InstagramWidget: {
      headingClass: 'Title--beta',
      headingLevel: 2,
    },
    LanguageBanner: {
      enable: false,
      fullViewportWidthWrapper: true,
    },
    LanguageSwitcher: {
      dropdown: true,
      dropdownTriggerType: ['hover'], // click, focus, hover, keyup
      enable: false, // Set `$languageSwitcherEnable` in `_variables.scss`
      icon: {
        active: false,
        inactive: false,
      },
      type: 'name', // code, name, image
      selectedIcon: false,
      shortSelectedName: false,
      showActiveLanguage: false,
      useAlternativeLinks: false, // Option for multiple domains
      useCanonicalLinks: false, // Option for multiple domains
      useCanonicalLinksLocally: false, // Option for local multiple domains
      useHomepageLinks: false, // Option for multiple domains
    },
    LightboxGallery: {
      showCounterIndicator: true,
      thumbnails: {
        enable: true,
        height: 100,
        position: 'right',
        width: 100,
      },
    },
    MainMenu: {
      enableDropdown: true,
      fullViewportWidthWrapper: true,
      linkStyles: [], // stretched
      showDropdownIndicator: true,
    },
    MainMenuDropdownContent: {
      direction: 'row',
      content: {
        icon: false, // Visible for type `link` only
        type: 'SubCategoryBox', // SubCategoryBox, link
      },
      showHeading: true,
      subcategoriesCountPerLine: {
        md: 3,
        lg: 3,
        xl: 4,
      },
      subCategoryBoxStyle: '', // 'outlined'
    },
    MainMenuMobile: {
      breakpoint: 'md',
      showFooter: true,
      showSubLevelArrow: true,
      showSubLevelOpenEffect: 'slideToSide', // 'dropdown', 'slideToSide'
    },
    MainMenuMobileFooter: {
      contact: {
        showBranchesLink: true,
      },
    },
    Newsletter: {
      alignToCenter: true,
      iconVisible: true,
    },
    NewsletterForm: {
      button: 'primary',
    },
    OrderBox: {
      enablePaymentButton: false,
      showItemsAmount: false,
      variant: 'bordered', // bordered, filled
    },
    PageAboutPurchase: {
      showAfterPurchase: true,
      showBeforePurchase: true,
      showUniqueSellingPoints: true,
    },
    PageArticleDetail: {
      articleInfo: {
        author: {
          enable: true,
          icon: true,
          image: {
            width: 25,
            height: 25,
            enable: false,
          },
          showLink: false,
        },
        category: {
          enable: true,
          icon: true,
          classPrefix: '',
        },
        date: {
          enable: true,
          icon: true,
        },
        tags: {
          enable: true,
          icon: true,
        },
        enable: true,
      },
      backToArticles: {
        enable: false,
        button: 'light', // link, primary or secondary
      },
      breadcrumbs: {
        enable: true,
      },
      footer: {
        authorStyle: 'AuthorBox', // `AuthorBox`, `inline`
        enable: false,
      },
      header: {
        enable: false,
        fullViewportWidthWrapper: false,
      },
      latestArticles: {
        count: 0,
        perLine: null, // using defaults from `ArticleList.perLine`
      },
      perex: {
        enable: true,
      },
      showIcons: true,
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleList: {
      header: {
        enable: false,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleListAuthor: {
      header: {
        enable: false,
        fullViewportWidthWrapper: false,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
      image: {
        width: 150,
        height: 150,
        crop: false,
      },
    },
    PageArticleListCategory: {
      actionsOnBottom: false,
      disableBreadcrumbs: false,
      header: {
        enable: false,
        fullViewportWidthWrapper: false,
      },
      masterCategoryPrefix: '',
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageBrandList: {
      brandType: 'link', // `link`, `BrandBox`
      showAll: true,
      showLetters: true,
      showUniqueSellingPoints: true,
      visibleItems: 16, // Requires showAll set to false and showLetters to false
    },
    PageBranchDetail: {
      showUniqueSellingPoints: true,
    },
    PageBranchList: {
      showUniqueSellingPoints: true,
    },
    PageBulkOrder: {
      buttons: {
        addToCart: {
          enable: true,
          type: 'btn-cta',
        },
        removeAll: {
          enable: true,
          type: 'btn-cta',
        },
      },
      product: {
        catalogCode: true,
        image: {
          crop: false,
          height: 100,
          upscale: true,
          width: 100,
        },
        name: {
          key: 'fullname', // set key: fullname || name
        },
        price: {
          withTax: {
            enable: true,
            suffix: false,
          },
          withoutTax: {
            enable: false,
            suffix: false,
          },
        },
      },
      search: {
        dropdown: {
          events: ['click', 'focus'],
          placement: 'bottom-start',
        },
        product: {
          catalogCode: true,
          image: {
            crop: false,
            height: 50,
            upscale: true,
            width: 50,
          },
          name: {
            key: 'fullname', // set key: fullname || name
          },
          price: {
            withTax: {
              enable: true,
              suffix: false,
            },
            withoutTax: {
              enable: false,
              suffix: false,
            },
          },
        },
      },
      totalPrice: {
        enable: true,
        withoutTax: false,
      },
    },
    PageCatalog: {
      defaultContentType: 'products',
      Pagination: {
        bottom: {
          showButtonNextToPagination: false,
          showOnDesktop: true,
          showOnMobile: true,
        },
        button: {
          style: 'primary',
        },
        top: {
          showOnDesktop: true,
          showOnMobile: true,
        },
      },
      perLine: {
        md: 2,
        xl: 3,
      },
      perPage: 21,
      productsRecommendedPosition: 'above',
      searchThrough: ['faq_questions', 'articles'],
      showCatalogHeader: false,
      showCatalogHelp: true,
      showCategoryMenuInSidebar: true,
      showContactBox: true,
      showProductsRecommended: true,
      showUniqueSellingPoints: true,
      sidebar: {
        columnSize: {
          xs: 0,
          lg: 6,
        },
      },
      subCategories: {
        useContainerComponent: false,
        showInImportantFilteredCategory: true,
      },
    },
    PageCheckoutFinished: {
      alignContactBoxContent: 'center', // left, center
      boxStyle: {
        border: true,
        background: false,
      },
      checkoutRecapBoxProductsMinCountToScroll: 0,
      checkoutRecapBoxScrollable: false,
      contactBoxBreakBefore: true,
      headingIconSize: null, // i.e. 'lg'
      showCartPackageInformation: true,
      showDeliveryAddress: true,
    },
    PageCheckoutInfo: {
      billingTitleAlternativePosition: false,
      buttonBackEnable: true,
      buttonBackIcon: true,
      buttonBackIconSize: 'sm',
      buttonProceedCheckoutIcon: true,
      buttonProceedCheckoutIconSize: 'sm',
      checkoutRecapBoxProductsMinCountToScroll: 0,
      checkoutRecapBoxScrollable: false,
      disableBusinessInputsForLoggedUser: false,
      disableHeurekaCheckbox: false,
      disableVatinInputForCountries: [], //list of country codes
      enableTypoRecognition: true,
      formalTitlesInputsType: 'radio',
      input: {
        formalTitle: {
          enable: true,
        },
        fullName: {
          locked: false,
        },
        email: {
          locked: false,
        },
        phone: {
          locked: false,
        },
        birthDate: {
          locked: false,
        },
        businessName: {
          locked: false,
        },
        street: {
          locked: false,
        },
        city: {
          locked: false,
        },
        postCode: {
          locked: false,
        },
        country: {
          locked: false,
        },
        businessInfo: {
          locked: false,
        },
        billingAddress: {
          locked: false,
        },
        billingHouseNumber: {
          enable: false,
        },
        companyName: false,
        dateOfBirth: {
          enable: false,
        },
        houseNumber: {
          enable: false,
        },
        noteForShipper: {
          enable: false,
        },
        shippingName: {
          locked: false,
        },
        shippingBusinessName: {
          locked: false,
          enable: true,
          enableWithBusiness: true,
          enableOnlyInShippingInfo: false,
        },
        shippingPhone: true,
        customOrderNumber: {
          locked: false,
        },
        tin: {
          locked: false,
          validation: 'required|tin:{billingCountry}',
        },
        vatin: {
          locked: false,
          disableInputForCountries: [], //list of country codes
        },
        vatinsvk: {
          showForNonLoggedUser: true,
          showForLoggedUser: true,
        },
      },
      openTextPageLinksInModal: false,
      layout: 'LayoutCheckout', // LayoutBase, LayoutCheckout
      newsletter: {
        defaultValue: false,
        showCheckboxForLoggedUser: false,
      },
      privacyPolicyLink: {
        showRequiredSign: false,
      },
      showContactBox: true,
      showLoginLineHook: true,
      showShippingName: false,
      tooltipPlacement: 'top',
      orderPlaced: {
        parameters: {
          fetch: false,
          selectedParameters: [],
        },
      },
    },
    PageCheckoutItems: {
      buttonCheckoutIcon: true,
      buttonCheckoutIconSize: 'sm',
      buttonContinueShoppingIcon: true,
      buttonContinueShoppingIconSize: 'sm',
      layout: 'LayoutCheckout', // LayoutBase, LayoutCheckout
      showAvailableGifts: true,
      showEmptyCart: true,
      showLoginLineHook: false,
      showPricesBeforeDiscount: false,
      showPromoCodeInputOnPageLoad: false,
      showProductsRecommended: true,
      showUniqueSellingPoints: true,
      showTotalPriceWithoutTax: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageCheckoutMethods: {
      buttonBackEnable: true,
      buttonBackIcon: true,
      buttonBackIconSize: 'sm',
      buttonProceedCheckoutIcon: true,
      buttonProceedCheckoutIconSize: 'sm',
      enableInsurance: false,
      enableCountriesSwitcher: false,
      enableCurrencySwitcher: false,
      checkoutRecapBoxProductsMinCountToScroll: 0,
      checkoutRecapBoxScrollable: false,
      layout: 'LayoutCheckout', // LayoutBase, LayoutCheckout
      showContactBox: true,
    },
    PageCheckoutPaymentCallback: {
      alignContactBoxContent: 'center', // left, center
    },
    PageCompare: {
      showRemoveLinkTop: true,
      showRemoveLinkBottom: false,
    },
    PageContact: {
      showBranches: false,
      showBranchesMap: false,
      showEmployees: false,
      showFaqAboveForm: true,
      showPrimaryBranchMap: false,
      showUniqueSellingPoints: true,
    },
    PageContactBillingInformation: {
      enable: true,
    },
    PageFaq: {
      categories: {
        enable: true,
        showSidebar: true,
        showIconBoxes: false,
      },
      showUniqueSellingPoints: true,
    },
    PageForbidden: {
      defaultImage: true,
      imageWidth: 250,
      showProductsRecommended: true,
      topCategories: {
        enable: false,
        fullViewportWidthWrapper: false,
      },
    },
    PageHomepage: {
      featuredArticles: {
        enable: true,
        count: 3,
      },
      mainHeadingPosition: 'aboveSlider', // aboveSlider, belowSlider
      mostSoldProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 4,
        },
      },
      newestProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 4,
        },
      },
      showHomepageBranches: true,
      showHomepageBrands: false,
      showHomepageTextBlock: true,
      showProductsRecommended: true,
      showSlider: true,
      showUniqueSellingPoints: true,
      showVisitedProducts: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
        topCategoryHeadingLevel: 3,
      },
    },
    PageHomepageMainHeading: {
      enable: true,
      level: 1,
      style: 'alpha', // alpha, beta, gama, delta, epsilon, zeta
    },
    PageInternalError: {
      defaultImage: true,
      imageWidth: 250,
      showProductsRecommended: true,
      showUniqueSellingPoints: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageLogin: {
      registrationInfoButtonType: 'secondary',
      showContactBox: true,
      showRegistrationInfo: true,
    },
    PageNotFound: {
      defaultImage: true,
      imageWidth: 250,
      showHomeButton: true,
      showProductsRecommended: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageProductDetail: {
      accessoryProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          xl: 3,
        },
      },
      alternativeProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          xl: 3,
        },
      },
      countOfVariantsToShow: 4,
      enableBreadcrumbs: true,
      enableConfigurator: false,
      enableDeliveryDate: true,
      enableStickyTabs: false,
      files: {
        enable: false,
      },
      multiplyPricesByQuantity: false,
      overrideDescriptionByActiveVariantIfExists: false,
      overridePerexByActiveVariantIfExists: false,
      parametersTable: {
        enable: false,
        manufacturer: {
          code: true,
          enable: true,
        },
        parameterLabelSeparator: ':',
        prices: {
          discount: {
            enable: true,
            percentage: false,
            total: true,
          },
          originalPrice: {
            enable: true,
            enableExcludingVAT: true,
            enableIncludingVAT: true,
            enableSuffix: true,
          },
          price: {
            enable: true,
            enableExcludingVAT: true,
            enableIncludingVAT: true,
            enableSuffix: true,
          },
        },
        showCatalogCode: true,
        showDeliveryDate: true,
        showDiscount: true,
        showItemAvailability: true,
        showProductParameters: true,
      },
      showMetersCalculator: false,
      quantityPrices: {
        enableExcludingVAT: true,
        enableIncludingVAT: true,
      },
      recommendedProducts: {
        // ~ Related products from BE
        enable: true,
        forceEnable: false,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xl: 4,
        },
        useCategoryProductsFallback: false,
      },
      requireSelectedVariantToAddToCart: false,
      reviews: {
        enable: false,
        initialCount: 5,
        onlyWithReview: 0,
      },
      selectDefaultVariant: true,
      shortDescriptionPosition: 'bottom', // bottom, top
      showAvailability: true,
      showAvailabilityInWarehouses: true,
      showAvailabilityInWarehousesInDeliveryOptions: true,
      showCategoryMenuInSidebar: true,
      showColorVariants: false,
      showContactBoxInSidebar: true,
      showDateOfStorage: true,
      showDescription: true,
      showProductComments: false,
      showDeliveryOptions: true,
      showRecommendedCrossSell: false,
      showRelatedCategories: false,
      showShortDescription: true,
    },
    PageProxy: {
      showContactBox: true,
    },
    PageRegister: {
      disableVatinInputForCountries: [], //list of country codes
      enableBusinessTypeOption: false,
      enableToggleBusinessInputsOnB2bTypeSelection: true,
      enableTypoRecognition: true,
      houseNumber: {
        enable: false,
      },
      profession: {
        enable: false,
        validation: null,
      },
      newsletter: {
        defaultValue: false,
        showCheckbox: true,
      },
      note: {
        enable: false,
      },
      privacyPolicyLink: {
        showRequiredSign: true,
      },
      showAresLoading: true,
      showRegistrationBenefits: true,
      showSmsMarketingCheckbox: false,
      tin: {
        validation: 'required|tin:{billingCountry}',
      },
    },
    PageShoppingGuide: {
      showUniqueSellingPoints: true,
    },
    PageTermDetail: {
      showPerex: false,
      showUniqueSellingPoints: true,
    },
    PageTermsList: {
      showUniqueSellingPoints: true,
    },
    PageTextPage: {
      centerContent: false, // Works if sidebar is disabled
      contactBox: {
        enable: true,
        showButton: true,
      },
      sidebar: {
        enable: true,
        position: 'right', // right, left
      },
      showUniqueSellingPoints: true,
    },
    PageUserAddressList: {
      addressTypes: {
        billing: {
          enable: true,
          enableActions: {
            delete: true,
            edit: true,
          },
        },
        other: {
          enable: true,
          enableActions: {
            delete: true,
            edit: true,
          },
        },
        shipping: {
          enable: true,
          enableActions: {
            delete: true,
            edit: true,
          },
        },
      },
    },
    PageUserOrderDetail: {
      filesDownload: {
        csv: {
          enable: false,
          onlyForBusiness: false, // Allow only for business users
        },
        images: {
          enable: false,
          onlyForBusiness: false, // Allow only for business users
        },
      },
      enablePaymentButton: false,
      showTracking: false,
    },
    PageUserSettings: {
      lockCheckboxIsBusiness: false,
      input: {
        businessName: {
          locked: false,
        },
        city: {
          locked: false,
        },
        country: {
          locked: true,
        },
        email: {
          locked: false,
        },
        fullName: {
          locked: false,
        },
        firstName: {
          locked: false,
        },
        vatinsvk: {
          locked: false,
        },
        lastName: {
          locked: false,
        },
        phone: {
          locked: false,
        },
        postcode: {
          locked: false,
        },
        street: {
          locked: false,
        },
        TIN: {
          locked: false,
        },
        VATIN: {
          locked: false,
        },
      },
      newsletter: {
        showCheckbox: true,
      },
      smsMarketing: {
        showCheckbox: false,
      },
    },
    Pagination: {
      button: {
        enable: true,
        style: 'primary',
      },
      iconNext: true,
      iconPrevious: true,
      showPrevNextText: true,
    },
    ProductAddReview: {
      button: {
        size: 'default',
        style: 'primary',
      },
      enable: true,
      reviewText: {
        required: false,
      },
      showStarsForEmptyList: true,
    },
    ProductAvailability: {
      maximalShownAmountInStock: 5, // 0 ~ use value from backend
      showTextsFromBackend: false,
      unlimitedAvailableAmountDefaultValue: 5, // ~ checkStock is false from backend
    },
    ProductAvailabilityWatcher: {
      button: {
        icon: true,
        size: 'sm',
        style: 'primary',
      },
      enable: false,
    },
    ProductBox: {
      addToCartButton: 'cta', // primary, secondary, cta
      badge: {
        color: 'primary', // see tags color palette + primary color
        shape: 'circle', // circle only
      },
      contentOrder: {
        image: 0,
        name: 1,
        category: 2,
        availability: 3,
        price: 4,
        description: 5,
        variants: 6,
        colorVariants: 7,
      },
      enableSwitchVariant: false,
      forceDetailButton: false,
      imageWidth: 255,
      imageHeight: 270,
      showAddToCartButton: true,
      hideAddToCartButtonForUnavailableProduct: false,
      showAvailability: true,
      showB2CPriceForB2BPartner: false,
      showCategory: false,
      showColorVariants: false,
      showDateOfStorage: false,
      showDeliveryDate: true,
      showDescription: true,
      showStarRating: false,
      showVariantFullName: false,
      showVariants: true,
      showVariantsAsLink: false,
    },
    ProductBoxVariants: {
      enableActiveClass: false,
      enableBatchCart: false,
      enableInactiveVariants: true,
      visibleOnEvent: 'hover', // load, hover
    },
    ProductComments: {
      enable: true,
      showReplies: true,
    },
    ProductConfigurator: {
      addToCart: {
        enable: true,
        enableSticky: true,
        enablePrice: true,
        price: {
          withTax: true,
          withoutTax: true,
        },
      },
      sizes: {
        enable: false,
      },
    },
    ProductDateOfStorage: {
      dropdown: {
        enable: true,
        icon: true,
        amount: true,
      },
      showAmount: true,
    },
    ProductDetailImages: {
      badge: {
        color: 'primary', // see tags color palette + primary color
        shape: 'circle', // circle only
      },
      image: {
        height: 400,
        width: 300,
      },
      thumbnail: {
        height: 56,
        width: 56,
      },
    },
    ProductMetersCalculator: {
      decimalPosition: 0,
      image: {
        enable: false,
        height: 40,
        src: '',
        width: 40,
      },
      label: {
        selectWidth: true,
        selectLength: true,
      },
      price: {
        totalOriginalWithTax: false,
        totalWithTax: true,
        totalWithTaxSuffix: false,
        totalWithoutTax: true,
        totalWithoutTaxSuffix: true,
      },
    },
    ProductReviewBox: {
      reply: {
        showIcon: false,
      },
      reviewerName: 'short', // full, short
      starsRatingDecimalPlaces: 1,
    },
    ProductReviewList: {
      Pagination: {
        button: {
          style: 'primary',
        },
      },
      showHintAboveAddReviewButton: true,
      showStarsAboveAddReviewButton: true,
      starsRatingDecimalPlaces: 1,
    },
    ProductsRecommended: {
      categoryProductsEnable: false,
      quarticonEnable: true,
    },
    ProductTeaser: {
      image: {
        crop: false,
        height: 130,
        width: 130,
      },
      showAvailability: true,
      showBrand: false,
      showDateOfStorage: false,
      showPrices: true,
    },
    ProductsVisited: {
      align: 'default', // default, left
      component: 'ProductTeaser', // ProductTeaser, ProductBox
      enable: true,
    },
    RangeSlider: {
      sliderDotSize: 14,
      sliderHeight: 8,
    },
    RatingTable: {
      enable: false,
    },
    RegisterForm: {
      newsletter: {
        defaultValue: true,
      },
      openTextPageLinksInModal: true,
      privacyPolicyLink: {
        showRequiredSign: true,
      },
    },
    RemoveButton: {
      icon: 'bin',
    },
    RedirectModal: {
      enable: false,
      target: false,
      url: '',
      modalWindowMaxWidth: 'sm',
    },
    SearchDropdown: {
      categoryResultsCount: 5,
      linkAllCategories: {
        button: null, // primary, secondary, cta, null ~ not a button
        enable: true,
      },
      linkAllProducts: {
        button: null, // primary, secondary, cta, null ~ not a button
        enable: true,
      },
    },
    SearchInput: {
      enableDropdown: true,
      clearInputAfterSubmit: false,
      label: {
        icon: true,
      },
      button: {
        icon: false,
        show: false,
      },
    },
    StarRating: {
      activeColor: '#e8c517',
      inactiveColor: '#dee2e6',
      starsCount: 5,
    },
    Sticky: {
      alwaysVisible: false,
      alwaysVisibleMobile: false,
      enable: false,
    },
    Slider: {
      cmsTextContent: {
        enable: false,
        button: 'primary', // 'transparent', utility class ['primary'/'secondary'...] or leave empty to display as link
      },
      image: {
        width: 936, // Change SASSs variable $Slider-image-width
        height: 416, // Change SASSs variable $Slider-image-height
        srcsetBreakpoints: [],
      },
      navigation: {
        button: 'btn-primary',
        enable: false,
        nextText: '',
        prevText: '',
      },
      showCirclePagination: false,
      showNumericPaginationOnMobile: true,
      showTabs: true, // If false, truncate SASS variable $Slider-with-tabs-breakpoints
      showTimerIndicator: true,
    },
    SocialNetworkLinks: {
      showLabel: true,
      showNetworkName: true,
    },
    SubCategoryBox: {
      decorationType: 'image', // 'image', 'icon', null (category name only)
      image: {
        crop: false,
        height: 64,
        upscale: false,
        width: 64,
      },
      styleModifier: 'outlined',
    },
    TooltipOpener: {
      placement: 'top',
    },
    TopBanner: {
      alignContent: 'left',
      fullViewportWidthWrapper: true,
      showIcon: true,
      showCloseIcon: true,
    },
    TopCategories: {
      innerContainerClass: 'container',
      perLine: {
        sm: 3,
        md: 5,
      },
      showTopCategoriesHeading: true,
    },
    TopCategory: {
      headingClass: 'Title--epsilon',
      image: {
        crop: false,
        height: 100,
        width: 100,
      },
      showCategoriesOnMobile: true,
    },
    UniqueSellingPoints: {
      enable: true,
      itemClass: 'col-24 col-md-12 col-xl-6 px-2 py-1 p-md-2',
      parentClass: 'row',
    },
    UserAddressModalForm: {
      addressTypes: {
        billing: {
          enable: true,
        },
        shipping: {
          enable: true,
        },
        other: {
          enable: true,
        },
      },
      modalWindowMaxWidth: 'md',
    },
    UserOrderList: {
      perPage: 10,
    },
    WishListIcon: {
      icon: {
        active: 'heart',
        inactive: 'heart',
        loading: 'reload',
      },
    },
    WarrantyClaimsFormItem: {
      image: {
        height: 100,
        width: 100,
      },
    },
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: [],
      },
      // Make sure Sticky component is enabled in settings.
      headerStickyAt: 'vh/2', // 'vh', 'vh/x' where x is number (viewpoint height), number or null
      headerStickyEnable: false,
      showHeaderTop: true,
      showJavascriptRequired: true,
      showMainMenu: true,
      showNewsletter: true,
    },
    LayoutCheckout: {
      showFooterMain: false,
      showFooterBottom: true,
      showJavascriptRequired: true,
    },
    LayoutUser: {
      showContactForm: true,
      showNewsletter: true,
      showRegistrationBenefits: true,
      showUniqueSellingPoints: true,
      sidebarPosition: 'left',
      verticalMenuVariant: 'bordered', // bordered, borderless
      LogoutButton: {
        button: 'primary', // 'transparent', utility class ['primary'/'secondary'...] or leave empty to display as link
        inVerticalMenu: false, // show inside of VerticalMenu
      },
    },
  },
}
