import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import ProductBox from '@theme/components/product/ProductBox'

export default {
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'products',
  },
  components: {
    ProductBox,
  },
  data() {
    return {
      products: [],
    }
  },
  query() {
    return `
      ... on block_products {
        type
        uuid
        product_reference {
          entityId
        }
      }
    `
  },
  async mounted() {
    this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', {
      ids: this.block.product_reference.map(reference => reference.entityId),
    })
  },
}
