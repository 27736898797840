import _ from 'lodash'

export const getters = {
  GET_LAST_ID(state) {
    return state.lastId
  },
  GET_CURRENT_ITEMS(state) {
    const ids = _.pickBy(state.items, { isSticky: true, isDisabled: false })
    return ids ? Object.keys(ids) : null
  },
}

export const actions = {
  ADD_ITEM({ commit }) {
    commit('ADD_ITEM')
  },
  REMOVE_ITEM({ commit }, id) {
    commit('REMOVE_ITEM', id)
  },
  SET_ITEM_STATE({ commit }, { id, isSticky, isDisabled }) {
    commit('SET_ITEM_STATE', { id, isSticky, isDisabled })
  },
}

export const state = () => ({
  items: {},
  lastId: -1,
})

export const mutations = {
  ADD_ITEM(state) {
    state.lastId++
    state.items = { ...state.items, [state.lastId]: { isSticky: false, isDisabled: false } }
  },
  REMOVE_ITEM(state, id) {
    state.items = { ...delete state.items[id] }
  },
  SET_ITEM_STATE(state, { id, isSticky, isDisabled }) {
    state.items = { ...state.items, [id]: { isSticky, isDisabled } }
  },
}
