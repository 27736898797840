import searchPageView from './search.page_view'
import searchQuery from './search.query'

export default {
  register(eventContext) {
    ;[searchQuery, searchPageView].forEach(callback => {
      callback(eventContext)
    })
  },
}
