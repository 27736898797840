import UniqueSellingPoints from 'themes/base/components/shop/UniqueSellingPoints'

export default {
  extends: UniqueSellingPoints,
  data() {
    return {
      pointsIcons: {
        'box-check': require('@icon/box-check.svg?raw'),
        truck: require('@icon/truck.svg?raw'),
        'hand-bubble': require('@icon/hand-bubble.svg?raw'),
        headset: require('@icon/headset.svg?raw'),
      },
    }
  },
}
