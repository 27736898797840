import themeSettings from '@theme/settings'

export default ({ app }, inject) => {
  // Hotfix for multiple decimal settings in one theme
  if (app.$env.DOMAIN_CODE === 'ventilator-profis.de') {
    themeSettings.product.price.summaryTotalPrimary.decimal = 2
    themeSettings.product.price.summaryTotalSecondary.decimal = 2
  }
  // Inject themeSettings to context
  inject('themeSettings', themeSettings || {})
}
