import { get } from 'lodash'
import gql from 'graphql-tag'

export const actions = {
  async LOAD_CONFIGURATOR({}, { id }) {
    return {}
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query($id: String) {
            product(filters: { _id: [$id] }) {
              entities {
                product_configurations {
                  ... on product_configuration {
                    product_configuration_type_reference {
                      entity {
                        ... on product_configuration_type {
                          _id
                          title
                          description
                        }
                      }
                    }
                    product_configuration_value_reference {
                      entity {
                        ... on product_configuration_value {
                          _id
                          title
                          description
                          product_configuration_value_image {
                            path
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      })
      const { product_configurations } = get(query, 'data.product.entities[0]') || {}
      return { product_configurations }
    } catch (e) {
      console.error(e)
    }
  },
}
