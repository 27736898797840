import { hookDefinitions, hookRegister } from '~/hooks'
import brandFragments from '~/queries/fragments/brand'
import gql from 'graphql-tag'
import isArray from 'lodash/isArray'

hookRegister(hookDefinitions.PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROMISES, ({ context, data, product }) => {
  const parameter =
    product.content.parameters &&
    isArray(product.content.parameters) &&
    product.content.parameters.find(parameter => parameter.entityId === context.app.$contentSettings.brandParameterId)

  if (parameter && parameter.values && parameter.values.length) {
    let client = context.app.apolloProvider.defaultClient
    data.productMaterials = client
      .query({
        query: gql`
          ${brandFragments.all}
          query($ids: [String]) {
            brand(filters: { _id: $ids }) {
              entities {
                ...brandFragmentsAll
              }
            }
          }
        `,
        variables: {
          ids: parameter.values,
        },
      })
      .then(result => {
        context.store.commit('product/SET_PAGE_DATA', {
          field: 'productBrands',
          data: result.data.brand.entities,
          page: 'detail',
        })
      })
  }
})
