<template>
  <div>
    <component
      v-if="urlRewriteComponentName"
      :key="$store.state.globals.urlRewriteAsyncData.uid"
      :is="urlRewriteComponentName"
      v-bind="$store.state.globals.urlRewriteAsyncData" />
    <LayoutBase v-else>
      <template v-if="!isLoading">
        <PageInternalError v-if="error.statusCode === 500"/>
        <PageNotFound v-if="error.statusCode === 404"/>
        <PageForbidden v-if="error.statusCode === 403"/>
      </template>
    </LayoutBase>
  </div>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'

export default {
  name: 'ErrorLayout',
  scrollToTop: true,
  components: {
    LayoutBase,
    PageForbidden: () => import('@theme/components/shop/PageForbidden'),
    PageInternalError: () => import('@theme/components/shop/PageInternalError'),
    PageNotFound: () => import('@theme/components/shop/PageNotFound'),
    PageTextPage: () => import('@theme/components/content/PageTextPage'),
  },
  mixins: [AppRouteMixin],
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      urlRewriteComponentName: null,
    }
  },
  watch: {
    error: function() {
      this.$scrollTo(window.document.body, { duration: 0 })
      this.fetchUrlRewrite()
    },
  },
  destroyed() {
    this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteAsyncData', value: null })
    this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteSlug', value: null })
  },
  async fetch() {
    await this.fetchUrlRewrite()
  },
  async mounted() {
    await this.$store.dispatch('category/FETCH_TOP_CATEGORIES')
  },
  methods: {
    async fetchUrlRewrite() {
      const context = this.$store.app.context
      const path = context.route.path
      const lang = this.$store.app.context.app.i18n.locale
      let componentName, asyncData, replacementCompontent, urlRewriteContent
      try {
        urlRewriteContent = await this.$axios.$get(context.app.$env.CMS_URL + '/url-rewrite', {
          params: { lang, path },
        })
      } catch (e) {
        // Do nothing
      }
      if (urlRewriteContent && urlRewriteContent.page_meta && urlRewriteContent.page_meta.slug[lang]) {
        let slug = urlRewriteContent.page_meta.slug[lang]
        switch (urlRewriteContent.type) {
          case 'page':
            componentName = 'PageTextPage'
            replacementCompontent = (await import('~/sites/shop/pages/page/_slug')).default
            break
        }
        this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteSlug', value: slug })
        try {
          asyncData = await replacementCompontent.asyncData(context)
          this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteSlug', value: null })
        } catch (e) {
          // Do nothing
        }
        if (asyncData && !asyncData.statusCode) {
          asyncData.uid = new Date().getTime()
          this.urlRewriteComponentName = componentName
          this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteAsyncData', value: asyncData })
          this.error.statusCode = 200
        } else {
          this.$store.commit('globals/SET_FIELD', { field: 'urlRewriteSlug', value: null })
        }
      }
      this.isLoading = false
    },
  },
}
</script>
