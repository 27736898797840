<script>
export default {
  props: {
    titleHighlights: {
      type: Array | Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleHighlightsComponent: null,
    }
  },
  async mounted() {
    if (this.titleHighlights) {
      this.titleHighlightsComponent = (await import('vue-text-highlight')).default
    }
  },
}
</script>
