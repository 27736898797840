/*
  Documentation url:  https://github.com/seznam/zbozi-konverze
*/
import { get } from 'lodash'
import { isNotAlreadySent, logSent } from '~/events/duplicateHelpers'
export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const seznam_shop_id = get(context, 'store.state.globals.settings.integrations.seznam_shop_id')
    const zbozicz_allow = get(order, 'attributes.GDPR.zbozicz')
    if (zbozicz_allow && seznam_shop_id) {
      const ALREADY_SENT_KEY = 'ZBOZI_SENT_ORDER_IDS'
      if (isNotAlreadySent(ALREADY_SENT_KEY, order.code)) {
        logSent(ALREADY_SENT_KEY, order.code)
        console.log('LOG: Sending zbozi.cz data on eventDefinitions.ORDER.PLACED', order.code)

        window.seznam_zboziId = seznam_shop_id
        window.seznam_orderId = order.code

        // Add and execute script
        const ho = document.createElement('script')
        ho.type = 'text/javascript'
        ho.async = true
        ho.src = 'https://www.seznam.cz/rs/static/rc.js'
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(ho, s)
      }
    }
  })
}
