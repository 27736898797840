import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock],
  data() {
    return {
      isOpen: false,
    }
  },
  cmsSettings: {
    type: 'collapsible',
    title: {
      cs: 'Zavíratelný blok',
    },
    fields: [
      {
        name: 'title',
        title: {
          cs: 'Nadpis',
        },
        type: 'text',
      },
      {
        name: 'text',
        title: {
          cs: 'Text',
        },
        type: 'longtext',
        options: {
          wysiwyg: true,
        },
      },
    ],
  },
  query() {
    return `
      ... on block_collapsible {
        type
        uuid
        title
        text
      }
    `
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
  },
}
