import { render, staticRenderFns } from "./LazyComponent.vue?vue&type=template&id=b4bd9e6c&"
import script from "./LazyComponent.js?vue&type=script&lang=js&"
export * from "./LazyComponent.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports