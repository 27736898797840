import gql from 'graphql-tag'

export const actions = {
  async LOAD_TERMS({ commit, dispatch }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          query term {
            term(limit: 1000) {
              entities {
                title
                description
                page_meta {
                  slug
                }
                perex
              }
            }
          }
        `,
      })
      return result.data.term.entities
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_TERM({ commit, dispatch }, { slug }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          query term($slug: String) {
            term(filters: { page_meta: { slug: [$slug] } }) {
              entities {
                title
                page_title
                description
                page_meta {
                  seo_description
                  seo_keywords
                  seo_title
                  slug
                  share_image
                }
                perex
                category_reference {
                  entityId
                }
                product_reference {
                  entityId
                }
                virtual_category_reference {
                  entityId
                }
                term_reference {
                  entity {
                    ... on term {
                      title
                      page_meta {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          slug,
        },
      })
      return result.data.term.entities[0]
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
