export default function productTransformer(product) {
  return product
    ? {
        productDetail_product_id: product.id,
        productDetail_product_name: product.name,
        productDetail_product_category_name: product.categoryName,
        productDetail_product_category_fullname: product.categoryFullname,
        productDetail_product_code: product?.marketingCode,
      }
    : null
}
