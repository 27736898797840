<script>
export default {
  props: {
    headingLevel: {
      type: Number,
      default: null,
    },
  },
}
</script>
