<script>
export default {
  props: {
    mark: {
      type: Object,
      required: true,
    },
  },
}
</script>
