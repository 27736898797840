<template>
  <p :class="classObject"><slot/></p>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
  computed: {
    classObject() {
      return {
        'WysiwygParagraph--fullWidth': this.content.attrs && this.content.attrs.maxWidth === 'full',
        'WysiwygParagraph--alignLeft': this.content.attrs && this.content.attrs.textAlign === 'left',
        'WysiwygParagraph--alignCenter': this.content.attrs && this.content.attrs.textAlign === 'center',
        'WysiwygParagraph--alignRight': this.content.attrs && this.content.attrs.textAlign === 'right',
      }
    },
  },
}
</script>
