<script>
export default {
  data() {
    return {
      isIE11: false,
    }
  },
  mounted() {
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  },
}
</script>
