import { errorFromResponse, notifications } from '@/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import ProvideValidator from '~/app/ProvideValidator'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

// TODO: @GF - loading se pousti az pri kliku na button
// TODO: Rozdelit LoadingMixin a component loading mixin

export default {
  components: {
    AppButton,
    AppInput,
    AppTitle,
  },
  mixins: [LoadingMixin, ProvideValidator],
  data() {
    return {
      email: '',
      slugPrivacyPolicy: this.$t('globals.pageSlugs.privacyPolicy'),
      submitted: false,
    }
  },
  methods: {
    async submit() {
      this.startLoading()
      if (this.email) {
        const response = await this.$store.dispatch('user/SUBSCRIBE_NEWSLETTER', {
          callbackUrl: window.location.origin + this.localePath({ name: 'user-activate' }),
          email: this.email,
        })
        if (response) {
          if (response.data && response.data.errors && response.data.errors[0]) {
            if (response.status === 401) {
              notifications.success(response.data.errors[0].title)
            } else {
              errorFromResponse(response)
            }
          } else {
            notifications.success(this.$t('NewsletterForm.notifications.success'))
            eventBus.$emit(eventDefinitions.NEWSLETTER.SUBSCRIBED, {
              email: this.email,
            })
            this.email = ''
          }
        }
      } else {
        notifications.danger(this.$t('NewsletterForm.notifications.empty'))
      }
      this.stopLoading()
    },
  },
}
