export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    limitedAmount() {
      let value = 0
      if (this.product.availability.checkStock === false) {
        value = this.$themeSettings.components.ProductAvailability.unlimitedAvailableAmountDefaultValue
      } else if (this.$themeSettings.components.ProductAvailability.maximalShownAmountInStock === 0) {
        value = this.product.availability.stock
      } else if (
        this.product.availability.stock >= this.$themeSettings.components.ProductAvailability.maximalShownAmountInStock
      ) {
        value = this.$themeSettings.components.ProductAvailability.maximalShownAmountInStock
      } else {
        value = this.product.availability.stock
      }
      return value < 0 ? 0 : value
    },
    text() {
      if (!this.product) return ''
      let availableIn =
        this.product.availability.delayDays > 0
          ? ' ' + this.$tc('ProductAvailability.availableIn', this.product.availability.delayDays)
          : ''

      return this.product.availability.isAvailable
        ? this.$themeSettings.components.ProductAvailability.showTextsFromBackend
          ? this.product.availability.availability
          : this.$tc('ProductAvailability.availability', this.limitedAmount)
        : this.$themeSettings.components.ProductAvailability.showTextsFromBackend
          ? this.product.availability.soldAvailability
          : this.$tc('ProductAvailability.availability', 0) + availableIn
    },
  },
}
