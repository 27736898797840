<template>
  <a
    v-if="href && (isExternal || target)"
    :href="href"
    :target="target"
    :rel="isExternal ? 'noopener noreferrer' : null"
    :title="mark.attrs.title ? mark.attrs.title[$store.state.locale.contentLocale] : null"
    :class="{'WysiwygLink--hasImage': hasImage}"
    class="link link-underlined"
    @click="clicked()">
    <slot/>
  </a>
  <nuxt-link
    v-else-if="href"
    :to="href"
    :title="mark.attrs.title ? mark.attrs.title[$store.state.locale.contentLocale] : null"
    :class="{'WysiwygLink--hasImage': hasImage}"
    class="link link-underlined"
    @click.native="clicked()">
    <slot/>
  </nuxt-link>
  <span
    v-else
    @click="clicked()"><slot/></span>
</template>

<script>
import FileMixin from '~/mixins/FileMixin'
import WysiwygMarkMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygMarkMixin'

export default {
  mixins: [FileMixin, WysiwygMarkMixin],
  data() {
    return {
      hasImage: false,
    }
  },
  computed: {
    href() {
      if (this.mark.attrs.file && this.mark.attrs.file[0]) {
        return this.cmsFileUrl(this.mark.attrs.file[0].fileKey)
      }
      return this.mark.attrs ? this.mark.attrs.href[this.$store.state.locale.contentLocale] : null
    },
    isExternal() {
      return this.href.match(/((mailto|tel|sms|geo|market):)|:\/\//)
    },
    target() {
      return this.mark.attrs && this.mark.attrs.link_target_blank ? '_blank' : null
    },
  },
  methods: {
    clicked() {
      setTimeout(() => {
        if (this.$route.hash.length > 0) {
          this.$scrollTo(this.$route.hash)
        }
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.WysiwygLink--hasImage {
  display: inline-block;
}
</style>
