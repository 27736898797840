import { get } from 'lodash'

export default {
  components: {
    Dropdown: () => import('@theme/components/utils/Dropdown'),
  },
  props: {
    displayedVariant: {
      type: Object,
      required: true,
    },
    forceNearest: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
    },
  },
  computed: {
    dateOfStorage() {
      return get(this.displayedVariant, 'content.date_of_storage') || get(this.product, 'content.date_of_storage') || []
    },
    expecting() {
      const today = this.$moment.utc().valueOf()
      const future = this.dateOfStorage
        .filter(d => d.date_of_storage && this.$moment.utc(d.date_of_storage).valueOf() >= today)
        .sort((a, b) => {
          return this.$moment.utc(a.date_of_storage).valueOf() - this.$moment.utc(b.date_of_storage).valueOf()
        })
      return future
    },
    nearest() {
      return get(this.expecting, '[0]')
    },
  },
}
