import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10179989 = () => interopDefault(import('../sites/cvb-de/pages/user/activate.vue' /* webpackChunkName: "sites/cvb-de/pages/user/activate" */))
const _b31844fa = () => interopDefault(import('../sites/cvb-de/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/cvb-de/pages/aboutPurchase/index" */))
const _1b3a28ee = () => interopDefault(import('../sites/cvb-de/pages/articleList/index.vue' /* webpackChunkName: "sites/cvb-de/pages/articleList/index" */))
const _06f59067 = () => interopDefault(import('../sites/cvb-de/pages/checkout/index.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/index" */))
const _e228f792 = () => interopDefault(import('../sites/cvb-de/pages/compare.vue' /* webpackChunkName: "sites/cvb-de/pages/compare" */))
const _7c67c216 = () => interopDefault(import('../sites/cvb-de/pages/contact/index.vue' /* webpackChunkName: "sites/cvb-de/pages/contact/index" */))
const _0473db3b = () => interopDefault(import('../sites/cvb-de/pages/login.vue' /* webpackChunkName: "sites/cvb-de/pages/login" */))
const _e17b7336 = () => interopDefault(import('../sites/cvb-de/pages/maintenance.vue' /* webpackChunkName: "sites/cvb-de/pages/maintenance" */))
const _ed9e617a = () => interopDefault(import('../sites/cvb-de/pages/branch/index.vue' /* webpackChunkName: "sites/cvb-de/pages/branch/index" */))
const _c247d8de = () => interopDefault(import('../sites/cvb-de/pages/passwordForgotten.vue' /* webpackChunkName: "sites/cvb-de/pages/passwordForgotten" */))
const _6dd92074 = () => interopDefault(import('../sites/cvb-de/pages/passwordReset.vue' /* webpackChunkName: "sites/cvb-de/pages/passwordReset" */))
const _36b3a07e = () => interopDefault(import('../sites/cvb-de/pages/register.vue' /* webpackChunkName: "sites/cvb-de/pages/register" */))
const _94eee1ee = () => interopDefault(import('../sites/cvb-de/pages/sitemapconfig.vue' /* webpackChunkName: "sites/cvb-de/pages/sitemapconfig" */))
const _a46f806e = () => interopDefault(import('../sites/cvb-de/pages/search/index.vue' /* webpackChunkName: "sites/cvb-de/pages/search/index" */))
const _0efb3fac = () => interopDefault(import('../sites/cvb-de/pages/user/index.vue' /* webpackChunkName: "sites/cvb-de/pages/user/index" */))
const _062c9636 = () => interopDefault(import('../sites/cvb-de/pages/auth/proxy.vue' /* webpackChunkName: "sites/cvb-de/pages/auth/proxy" */))
const _e4a54226 = () => interopDefault(import('../sites/cvb-de/pages/checkout/finished.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/finished" */))
const _4d1135fe = () => interopDefault(import('../sites/cvb-de/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/importOrder/index" */))
const _086522ae = () => interopDefault(import('../sites/cvb-de/pages/checkout/info.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/info" */))
const _4f028ff2 = () => interopDefault(import('../sites/cvb-de/pages/checkout/methods.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/methods" */))
const _b47757e8 = () => interopDefault(import('../sites/cvb-de/pages/user/addressList.vue' /* webpackChunkName: "sites/cvb-de/pages/user/addressList" */))
const _6abbdf6e = () => interopDefault(import('../sites/cvb-de/pages/user/dashboard.vue' /* webpackChunkName: "sites/cvb-de/pages/user/dashboard" */))
const _5a9e843e = () => interopDefault(import('../sites/cvb-de/pages/user/orders/index.vue' /* webpackChunkName: "sites/cvb-de/pages/user/orders/index" */))
const _791a6b31 = () => interopDefault(import('../sites/cvb-de/pages/user/password.vue' /* webpackChunkName: "sites/cvb-de/pages/user/password" */))
const _772768b9 = () => interopDefault(import('../sites/cvb-de/pages/user/settings.vue' /* webpackChunkName: "sites/cvb-de/pages/user/settings" */))
const _678e1faa = () => interopDefault(import('../sites/cvb-de/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/cvb-de/pages/user/shoppingLists/index" */))
const _3f6703a9 = () => interopDefault(import('../sites/cvb-de/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/cvb-de/pages/checkout/payment/callback" */))
const _3acc3824 = () => interopDefault(import('../sites/cvb-de/pages/index.vue' /* webpackChunkName: "sites/cvb-de/pages/index" */))
const _a04703b4 = () => interopDefault(import('../sites/cvb-de/pages/user/orders/_id.vue' /* webpackChunkName: "sites/cvb-de/pages/user/orders/_id" */))
const _0c940492 = () => interopDefault(import('../sites/cvb-de/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/cvb-de/pages/user/shoppingLists/_id" */))
const _1ea76b7e = () => interopDefault(import('../sites/cvb-de/pages/articleList/_slug.vue' /* webpackChunkName: "sites/cvb-de/pages/articleList/_slug" */))
const _da1ad9fa = () => interopDefault(import('../sites/cvb-de/pages/article/_slug.vue' /* webpackChunkName: "sites/cvb-de/pages/article/_slug" */))
const _f10ba40a = () => interopDefault(import('../sites/cvb-de/pages/branch/_slug.vue' /* webpackChunkName: "sites/cvb-de/pages/branch/_slug" */))
const _5ba6afbc = () => interopDefault(import('../sites/cvb-de/pages/product/_slug.vue' /* webpackChunkName: "sites/cvb-de/pages/product/_slug" */))
const _5e7f7028 = () => interopDefault(import('../sites/cvb-de/pages/page/_slug.vue' /* webpackChunkName: "sites/cvb-de/pages/page/_slug" */))
const _e937239e = () => interopDefault(import('../sites/cvb-de/pages/_.vue' /* webpackChunkName: "sites/cvb-de/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivieren",
    component: _10179989,
    name: "user-activate___de"
  }, {
    path: "/alle-hilfethemen",
    component: _b31844fa,
    name: "aboutPurchase___de"
  }, {
    path: "/artikel",
    component: _1b3a28ee,
    name: "articleList___de"
  }, {
    path: "/checkout",
    component: _06f59067,
    name: "checkout___de"
  }, {
    path: "/compare",
    component: _e228f792,
    name: "compare___de"
  }, {
    path: "/impressum",
    component: _7c67c216,
    name: "contact___de"
  }, {
    path: "/login",
    component: _0473db3b,
    name: "login___de"
  }, {
    path: "/maintenance",
    component: _e17b7336,
    name: "maintenance___de"
  }, {
    path: "/niederlassungen",
    component: _ed9e617a,
    name: "branch___de"
  }, {
    path: "/passwordForgotten",
    component: _c247d8de,
    name: "passwordForgotten___de"
  }, {
    path: "/passwordReset",
    component: _6dd92074,
    name: "passwordReset___de"
  }, {
    path: "/register",
    component: _36b3a07e,
    name: "register___de"
  }, {
    path: "/sitemapconfig",
    component: _94eee1ee,
    name: "sitemapconfig___de"
  }, {
    path: "/suche",
    component: _a46f806e,
    name: "search___de"
  }, {
    path: "/user",
    component: _0efb3fac,
    name: "user___de"
  }, {
    path: "/auth/proxy",
    component: _062c9636,
    name: "auth-proxy___de"
  }, {
    path: "/checkout/finished",
    component: _e4a54226,
    name: "checkout-finished___de"
  }, {
    path: "/checkout/importOrder",
    component: _4d1135fe,
    name: "checkout-importOrder___de"
  }, {
    path: "/checkout/info",
    component: _086522ae,
    name: "checkout-info___de"
  }, {
    path: "/checkout/methods",
    component: _4f028ff2,
    name: "checkout-methods___de"
  }, {
    path: "/mein-konto/addressliste",
    component: _b47757e8,
    name: "user-addressList___de"
  }, {
    path: "/user/dashboard",
    component: _6abbdf6e,
    name: "user-dashboard___de"
  }, {
    path: "/user/orders",
    component: _5a9e843e,
    name: "user-orders___de"
  }, {
    path: "/user/password",
    component: _791a6b31,
    name: "user-password___de"
  }, {
    path: "/user/settings",
    component: _772768b9,
    name: "user-settings___de"
  }, {
    path: "/user/shoppingLists",
    component: _678e1faa,
    name: "user-shoppingLists___de"
  }, {
    path: "/checkout/payment/callback",
    component: _3f6703a9,
    name: "checkout-payment-callback___de"
  }, {
    path: "/",
    component: _3acc3824,
    name: "index___de"
  }, {
    path: "/user/orders/:id",
    component: _a04703b4,
    name: "user-orders-id___de"
  }, {
    path: "/user/shoppingLists/:id",
    component: _0c940492,
    name: "user-shoppingLists-id___de"
  }, {
    path: "/articleList/:slug",
    component: _1ea76b7e,
    name: "articleList-slug___de"
  }, {
    path: "/artikel/:slug",
    component: _da1ad9fa,
    name: "article-slug___de"
  }, {
    path: "/niederlassung/:slug",
    component: _f10ba40a,
    name: "branch-slug___de"
  }, {
    path: "/produkt/:slug",
    component: _5ba6afbc,
    name: "product-slug___de"
  }, {
    path: "/seite/:slug",
    component: _5e7f7028,
    name: "page-slug___de"
  }, {
    path: "/*",
    component: _e937239e,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
