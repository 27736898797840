export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.SEARCH.QUERY, ({ query, location }) => {
    if (query && query.length >= context.app.$contentSettings.events.dataLayerBasic.searchQuery.minimumCharacters) {
      window.dataLayer.push({
        event: eventDefinitions.SEARCH.QUERY,
        location: location,
        query: query,
      })
    }
  })
}
