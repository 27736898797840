export default ({ app }) => {
  app.i18n.pluralizationRules = {
    cs: pluralizationRulesWesternSlavic,
    sk: pluralizationRulesWesternSlavic,
  }
}

function pluralizationRulesWesternSlavic(number, choicesLength) {
  let option = null

  if (number === 0) {
    option = 0
  }

  if (number === 1) {
    option = 1
  }

  if (number >= 2 && number <= 4) {
    option = 2
  }

  if (number >= 5) {
    option = 3
  }

  return option > choicesLength ? choicesLength : option
}
