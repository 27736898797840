import { notifications } from '~/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import LoadingMixin from '~/mixins/LoadingMixin'
import ProductMixin from '~/mixins/ProductMixin'

export default {
  name: 'AddToCart',
  components: {
    AppButton,
  },
  mixins: [LoadingMixin, ProductMixin],
  props: {
    accessoryProducts: {
      type: Array,
      default: () => [],
    },
    addToCartCustomData: {
      type: [Boolean, Object],
      default: false,
    },
    addToCartNewLine: {
      type: Boolean,
      default: false,
    },
    appButtonClass: {
      type: String,
      default: '',
    },
    appButtonTag: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forCredits: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      required: true,
    },
    quantityMultiplier: {
      type: Number,
      default: null,
    },
    relatedProducts: {
      type: Array,
      default: () => [],
    },
    quantity: {
      type: Number,
      default: 1,
    },
    enableModalToSelectVariant: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default() {
        return this.$t('AddToCart.button')
      },
    },
    unit: {
      type: String,
      required: true,
    },
    variant: {
      type: Object,
      default: null,
    },
    variantRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addedItem: null,
      addToCartModalChooseVariant: null,
      addToCartModalItemAdded: null,
      changedAmount: null,
    }
  },
  computed: {
    enabled() {
      const product = this.variant ? this.variant : this.product
      return !(product.prices.unitWithTax <= 0 || !product.availability.isAvailable)
    },
    tooltipEnabled() {
      return !!this.tooltipText
    },
    tooltipText() {
      if (!this.variant && this.variantRequired) {
        return this.$t('AddToCart.variantRequired')
      }
      if (!this.enabled) {
        return this.$t('AddToCart.notAvailable')
      }
    },
  },
  methods: {
    async addToCart(variantId) {
      if (this.$store.state.globals.cartConfiguration.shopping_disabled) {
        notifications.warning(this.$store.state.globals.cartConfiguration.shopping_disabled_text)
        return
      }

      this.startLoading()
      const dataToSent = {
        id: variantId,
        quantity: this.quantity,
        forCredits: this.forCredits,
        ...(this.addToCartCustomData ? { custom: this.addToCartCustomData } : {}),
        ...(this.addToCartNewLine ? { newLine: this.addToCartNewLine } : {}),
      }
      const data = await this.$store.dispatch('cart/ADD_TO_CART', {
        id: variantId,
        quantity: this.quantity,
        forCredits: this.forCredits,
        ...(this.addToCartCustomData ? { custom: this.addToCartCustomData } : {}),
        ...(this.addToCartNewLine ? { newLine: this.addToCartNewLine } : {}),
      })
      if (data && data.addedItem) {
        this.addedItem = data.addedItem
        this.changedAmount = data.changedAmount
        this.$refs.addToCartModalChooseVariant && this.$refs.addToCartModalChooseVariant.hide()
        this.$store.dispatch('cart/FETCH_AVAILABLE_GIFTS')
        this.openAddToCartModalItemAdded()
      }
      this.stopLoading()
    },
    click() {
      if ((!this.variant && this.variantRequired) || !this.enabled) {
        return
      }
      this.$emit('click')
      if (this.enableModalToSelectVariant && this.product.variants.length > 1) {
        this.openAddToCartModalChooseVariant()
      } else {
        this.addToCart(this.variant ? this.variant.id : this.product.defaultVariantId)
      }
    },
    openAddToCartModalItemAdded() {
      import('@theme/components/cart/AddToCartModalItemAdded').then(component => {
        this.addToCartModalItemAdded = component.default
        this.$nextTick(() => {
          this.$refs.addToCartModalItemAdded.show()
        })
      })
    },
    openAddToCartModalChooseVariant() {
      import('@theme/components/cart/AddToCartModalChooseVariant').then(component => {
        this.addToCartModalChooseVariant = component.default
        this.$nextTick(() => {
          this.$refs.addToCartModalChooseVariant.show()
        })
      })
    },
  },
}
