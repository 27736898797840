import gql from 'graphql-tag'

export default {
  all: gql`
    fragment linkEmbeddedFragmentsAll on link_embedded {
      title
      link_type
      custom_link
      category_link {
        entity {
          ... on StoreCategory {
            data
          }
        }
      }
      article_category_link {
        entity {
          ... on article_category {
            page_meta {
              slug
            }
            title
            perex
          }
        }
      }
      article_tag_link {
        entity {
          ... on article_tag {
            page_meta {
              slug
            }
            title
            perex
          }
        }
      }
      virtual_category_link {
        entity {
          ... on virtual_category {
            title
            page_meta {
              slug
            }
          }
        }
      }
    }
  `,
  base: gql`
    fragment linkEmbeddedFragmentsBase on link_embedded {
      title
      link_type
      custom_link
      category_link {
        entity {
          ... on StoreCategory {
            data
          }
        }
      }
      virtual_category_link {
        entity {
          ... on virtual_category {
            title
            page_meta {
              slug
            }
          }
        }
      }
    }
  `,
}
