<script>
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  mixins: [ScrollToEventMixin],
  props: {
    positionInList: Number,
    impressionList: String,
  },
  mounted() {
    this.$on('scrolledTo', () => {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.IMPRESSION, {
        product: this.product,
        positionInList: this.positionInList,
        impressionList: this.impressionList,
      })
    })
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.CLICK, {
        product: this.product,
        positionInList: this.positionInList,
        impressionList: this.impressionList,
      })
    },
  },
}
</script>
