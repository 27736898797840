<script>
export default {
  data() {
    return {
      loading: false,
      loaded: false,
    }
  },
  methods: {
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
      this.loaded = true
    },
  },
}
</script>
