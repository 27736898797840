import AddToCart from '~/themes/base/components/cart/AddToCart'

export default {
  extends: AddToCart,
  computed: {
    enabled() {
      const product = this.variant ? this.variant : this.product
      return !(product.prices.unitWithTax <= 0)
    },
  },
}
