import catalogDataFragment from './catalogDataFragment'
import catalogSettingsFragments from './catalogSettings'
import gql from 'graphql-tag'

export default {
  all: gql`
    ${catalogDataFragment.all('category')}
    ${catalogSettingsFragments.all}
    fragment categoryFragmentAll on category {
      _id
      catalog_settings {
        ...catalogSettingsFragmentAll
      }
      ...catalogDataFragment_category
    }
  `,
}
