import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  mixins: [HeadingLevel],
  components: {
    AppTitle,
  },
  props: {
    point: {
      type: Object,
      default: () => [],
    },
  },
}
