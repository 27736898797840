import { errorFromResponse, notifications } from '~/app/notifications'
import jsCookie from 'js-cookie'

export const actions = {
  async LOGIN({ dispatch, commit }, options) {
    try {
      const { email, password, redirect, token } = options || {}
      if (token) {
        await dispatch('SET_AUTH', token)
      } else {
        const request = await this.$axios.$post(this.$env.STORE_URL + '/api/oauth/login', {
          email,
          password,
        })
        await dispatch('SET_AUTH', request.data.access_token)
      }
      await dispatch('SET_AXIOS_TOKEN')
      await dispatch('user/FETCH_USER', { forceReload: true }, { root: true })
      if (await dispatch('RESTRICTED_GROUP')) {
        return false
      }
      await dispatch('cart/FETCH_CART', { forceReload: true }, { root: true })
      commit(
        'globals/ADD_FLASH_MESSAGE',
        { type: 'success', text: this.app.i18n.t('globals.login.successNotificationText') },
        { root: true },
      )
      window.location = redirect ? redirect : this.app.localePath({ name: 'index' })
      return true
    } catch (e) {
      errorFromResponse(e.response)
      return false
    }
  },
  async LOGIN_AS_USER({ commit, state, dispatch }, options) {
    try {
      const { redirect, token } = options || {}
      if (token) {
        if (!!state.token) {
          await dispatch('LOGOUT', { redirect: false })
        }
        if (!(await dispatch('LOGIN', { redirect, token }))) {
          return false
        }
        commit(
          'globals/ADD_FLASH_MESSAGE',
          { type: 'info', text: this.app.i18n.t('globals.loginAsUser.success') },
          { root: true },
        )
      } else {
        commit(
          'globals/ADD_FLASH_MESSAGE',
          { type: 'danger', text: this.app.i18n.t('globals.loginAsUser.error') },
          { root: true },
        )
        window.location = '/'
      }
      return true
    } catch (e) {
      errorFromResponse(e.response)
      return false
    }
  },
  async LOGOUT({ dispatch }, options) {
    try {
      const { redirect } = options || {}
      const request = await this.$axios.$post(this.$env.STORE_URL + '/api/oauth/logout')
      await dispatch('REMOVE_AUTH')
      await dispatch('SET_AXIOS_TOKEN')
      await dispatch('SET_VARIANT')
      await dispatch('SET_USER_GROUPS')
      await dispatch('user/RESET_USER', {}, { root: true })
      await dispatch('cart/FETCH_CART', { forceReload: true }, { root: true })
      if (redirect !== false) {
        window.location = redirect ? redirect : this.app.localePath({ name: 'index' })
      }
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async FETCH_TOKEN({ commit }) {
    const token = jsCookie.get('X-Inspishop-User-Token')
    if (token) {
      commit('SET_TOKEN', token)
    } else {
      commit('SET_TOKEN', null)
    }
  },
  async RESTRICTED_GROUP({ commit, dispatch, getters, rootState }) {
    try {
      const { groups } = rootState.user.userData
      if (getters.isRestrictedGroup(groups)) {
        await dispatch('LOGOUT', { redirect: false })
        commit('globals/SET_REDIRECT_MODAL_STATE', true, { root: true })
        return true
      }
      return false
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async SET_AXIOS_TOKEN({ state }) {
    if (state.token) {
      this.$axios.setToken(state.token, 'Bearer')
    } else {
      this.$axios.setToken(false)
    }
  },
  SET_AUTH({ state, commit }, token) {
    commit('SET_TOKEN', token)
  },
  SET_VARIANT({ state, commit }, variant) {
    commit('SET_VARIANT', variant)
  },
  SET_USER_GROUPS({ state, commit }, groups) {
    commit('SET_USER_GROUPS', groups)
  },
  REMOVE_AUTH({ state, commit }) {
    commit('SET_TOKEN', null)
    jsCookie.remove('X-Inspishop-User-Token')
  },
}

export const state = () => ({
  defaultVariant: null,
  token: null,
  variant: null,
  groups: null,
  restrictedGroups: [],
})

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    if (token) {
      jsCookie.set('X-Inspishop-User-Token', token, { expires: 7 })
    } else {
      jsCookie.remove('X-Inspishop-User-Token')
    }
  },
  SET_VARIANT(state, variant) {
    if (!variant) {
      variant = state.defaultVariant
    }
    state.variant = variant
    if (variant) {
      jsCookie.set('X-Inspishop-User-Variant', variant, { expires: 7 })
    } else {
      jsCookie.remove('X-Inspishop-User-Variant')
    }
  },
  SET_DEFAULT_VARIANT(state, defaultVariant) {
    state.defaultVariant = defaultVariant
  },
  SET_USER_GROUPS(state, groups) {
    state.groups = groups
    if (groups) {
      jsCookie.set('X-Inspishop-User-Groups', groups.join(','), { expires: 7 })
    } else {
      jsCookie.remove('X-Inspishop-User-Groups')
    }
  },
}

export const getters = {
  isRestrictedGroup: state => groups => {
    return groups.length && state.restrictedGroups.length && state.restrictedGroups.some(r => groups.includes(r))
  },
}
