const original_location = () => {
  window.dataLayer.push({
    // See https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
    original_location:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
}

export { original_location }
