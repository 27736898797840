import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  catalog: {
    bestsellers: {
      enable: true,
    },
  },
  checkout: {
    enableCustomOrderNumber: true,
  },
  global: {
    showProductsRecommended: true,
  },
  product: {
    price: {
      secondary: {
        label: true,
        show: true,
      },
      summaryTotalSecondary: {
        label: true,
        show: true,
      },
    },
  },
  components: {
    ArticleBox: {
      articleInfo: {
        enable: true,
        author: {
          enable: false,
        },
        category: {
          enable: true,
          maxCount: 2,
        },
        date: {
          enable: true,
        },
      },
      linkReadMore: {
        enable: true,
        button: 'tertiary',
      },
      perex: {
        enable: true,
      },
    },
    CheckoutItemLine: {
      showDeliveryDate: false,
    },
    CheckoutMethodsShipper: {
      descriptionPosition: 'below', // below, tooltip
    },
    CookieConsent: {
      enable: false,
    },
    ContentHeader: {
      image: {
        crop: false,
      },
    },
    FeaturedArticles: {
      linkAllArticles: {
        enable: true,
        button: 'tertiary--white',
      },
      perLine: {
        sm: 1,
        md: 2,
        lg: 3,
      },
    },
    FooterBottom: {
      showContact: false,
      paymentMethods: {
        items: [
          'mastercard-secure-code',
          'mastercard',
          'maestro',
          'verified-by-visa',
          'visa-electron-dark',
          'visa',
          'gopay',
        ],
      },
    },
    FooterMain: {
      showContact: true,
    },
    HeaderMain: {
      cartInfoAlignment: 'align-items-md-center',
      searchSizeClass: 'col-md-11 col-lg-12',
      showContactInfo: true,
      showContentColumn: false,
    },
    HeaderSearch: {
      close: {
        enable: false,
      },
    },
    HeaderTop: {
      info: false,
    },
    MainMenu: {
      showDropdownIndicator: false,
    },
    Newsletter: {
      alignToCenter: false,
      iconVisible: false,
    },
    PageContact: {
      showBranches: true,
      showEmployees: true,
      showPrimaryBranchMap: true,
    },
    PageCatalog: {
      productsRecommendedPosition: 'below',
      showCatalogHeader: true,
      showProductsRecommended: true,
    },
    PageCheckoutInfo: {
      input: {
        formalTitle: {
          enable: false,
        },
      },
    },
    PageCheckoutItems: {
      showProductsRecommended: true,
    },
    PageHomepage: {
      showProductsRecommended: false,
      featuredArticles: {
        count: 9,
      },
      mostSoldProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 4,
        },
      },
      newestProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 4,
        },
      },
      saledProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 4,
        },
      },
    },
    PageProductDetail: {
      enableDeliveryDate: true,
      files: {
        enable: true,
      },
      parametersTable: {
        enable: true,
        prices: {
          originalPrice: {
            enable: true,
          },
        },
        showDeliveryDate: false,
      },
      recommendedProducts: {
        enable: false,
      },
      reviews: {
        enable: true,
      },
      shortDescriptionPosition: 'top',
      showAvailabilityInWarehouses: false,
      showAvailabilityInWarehousesInDeliveryOptions: false,
      showDeliveryOptions: true,
    },
    ProductAvailability: {
      showTextsFromBackend: true,
    },
    ProductBox: {
      showDeliveryDate: true,
    },
    ProductDateOfStorage: {
      dropdown: {
        enable: false,
        icon: true,
        amount: true,
      },
      showAmount: false,
    },
    ProductRow: {
      addToCartButton: 'cta',
      imageHeight: 80,
      imageWidth: 80,
      showAvailability: true,
      showDateOfStorage: false,
      showAddToCartButton: true,
    },
    SearchInput: {
      button: {
        show: true,
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
