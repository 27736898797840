<script>
import { hookDefinitions, hookDispatch } from '~/hooks'
import DynamicStylesMixin from '~/mixins/DynamicStylesMixin'
import FoxentryRouteMixin from '~/mixins/FoxentryRouteMixin'

export default {
  scrollToTop: true,
  mixins: [DynamicStylesMixin, FoxentryRouteMixin],
  async beforeMount() {
    this.$store.commit('user/FETCH_SESSION_UUID')
    await this.$store.dispatch('cart/FETCH_GUEST_CART_TOKEN')
    await this.$store.dispatch('auth/FETCH_TOKEN')
    await this.$store.dispatch('auth/SET_AXIOS_TOKEN')
    await this.$store.dispatch('cart/SET_AXIOS_GUEST_CART_TOKEN')
    this.$store.commit('cart/SET_CONDITIONS_PAGE', this.$t('globals.pageSlugs.termsAndConditions'))

    if (this.$store.state.auth.token) {
      await this.$store.dispatch('user/FETCH_USER', {})
      const restricted = await this.$store.dispatch('auth/RESTRICTED_GROUP')
      if (!restricted && this.$store.state.globals.settings.functions.loyalty_credits) {
        await this.$store.dispatch('user/FETCH_USER_CREDITS', {})
      }
    } else {
      this.$store.dispatch('auth/SET_VARIANT')
      this.$store.dispatch('auth/SET_USER_GROUPS')
    }
    this.$emit('AppRouteMixin.user.fetched')
    this.$store.commit('user/SET_USER_FETCHED', true)
    this.$store.commit('globals/FIRE_FLASH_MESSAGES')
    this.$store.commit('globals/FIRE_FLASH_MESSAGES')

    this.$store.dispatch('theme/SET_USER_PRICES')

    await this.$store.dispatch('cart/FETCH_CART', {})
    // Init foxentry after all data load
    this.foxentryInit()
    const { addToCart } = this.$route.query
    if (addToCart) {
      this.$nuxt.$emit('addToCartFromUrl')
    }
    hookDispatch(hookDefinitions.APP_ROUTE_MIXIN.BEFORE_MOUNT, { component: this })

    // Add access to change route from outside (e.g. for SAIMON.ai)
    window.changeRoute = path => {
      this.$router.push(path)
    }
  },
}
</script>
