import BigTitleBlock from '@theme/blocks/BigTitleBlock'
import CategoriesBlock from '@theme/blocks/CategoriesBlock'
import CollapsibleBlock from '@theme/blocks/CollapsibleBlock'
import NewsletterBlock from '@theme/blocks/NewsletterBlock'
import ProductBlock from '@theme/blocks/ProductsBlock'
import QuoteBlock from '@theme/blocks/QuoteBlock'
import TextBlock from '@theme/blocks/TextBlock'
import TextBoxesBlock from '@theme/blocks/TextBoxesBlock'
import UspBlock from '@theme/blocks/UspBlock'

export default {
  BigTitleBlock,
  CategoriesBlock,
  CollapsibleBlock,
  NewsletterBlock,
  ProductBlock,
  QuoteBlock,
  TextBlock,
  TextBoxesBlock,
  UspBlock,
}
