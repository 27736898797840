// Swap with index.js ones all events are moved to here

import dataLayerBasic from './dataLayerBasic/newIndex'
import enhancedEcommerce from './enhancedEcommerce/newIndex'
import heurekaConversions from './heureka/conversions'
import heurekaShopCertification from './heureka/shopCertification'
import zbozicz from './zbozicz'

const events = [dataLayerBasic, enhancedEcommerce, heurekaConversions, heurekaShopCertification, zbozicz]
export default events
