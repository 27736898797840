import { errorFromResponse, notifications } from '~/app/notifications'
import Vue from 'vue'
import _ from 'lodash'
import gql from 'graphql-tag'

export const actions = {
  async ADD_AVAILABILITY_WATCHER({ commit, dispatch }, { variantId, email }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/products/${variantId}/watchers`
      const response = await this.$axios.$post(url, { email: email })
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async ADD_REVIEW({ commit, dispatch }, { productId, review }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/products/${productId}/reviews`
      const reviewCleaned = _.pickBy(review)
      const response = await this.$axios.$post(url, reviewCleaned)
      return response.data
    } catch (e) {
      if (e.response && e.response.status === 422) {
        notifications.danger(this.app.i18n.t('globals.review.error.empty'))
      } else {
        errorFromResponse(e.response)
      }
    }
  },
  async LOAD_REVIEWS(
    { commit, dispatch },
    { page = 1, perPage = 5, productId, onlyWithReview = 0, sort = 'time', sortDirection = 'desc' },
  ) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/products/${productId}/reviews`
      const response = await this.$axios.$get(url, {
        params: {
          page: page,
          perPage: perPage,
          onlyWithReview: onlyWithReview,
          sort: sort,
          sortDirection: sortDirection,
        },
      })
      return response
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async UPDATE_PRODUCT_ATTRIBUTES({ commit, dispatch }, { id, config }) {
    try {
      let queries = [`include=tags,variants,alternatives`]
      const { width, height, configuration, extras } = config || {}
      let params = [{ width }, { height }, ...configuration]
      for (const param of params) {
        for (const key in param) {
          const value = param[key]
          if (value) {
            queries.push(`configuration[${key}]=${value}`)
          }
        }
      }
      if (extras) {
        for (const key in extras) {
          const value = extras[key]
          if (value) {
            queries.push(`${key}=${value}`)
          }
        }
      }
      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/products/${id}?${queries.join('&')}`)
      return response
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async LOAD_PRODUCT({ commit, dispatch }, { slug }) {
    try {
      const response = await this.$axios.$get(
        this.$env.CMS_URL + '/catalog/product?checkPermissions=true&slugs=' + slug,
      )
      if (response.error) {
        return { error: response.error }
      }
      if (response[0]) {
        // Replaces content with data formatted by graphql
        const client = this.app.apolloProvider.defaultClient
        const storeId = response[0].id
        const query = await client.query({
          query: gql`
            query getProductContent($store_id: [Float]) {
              product(filters: { store_id: $store_id }) {
                entities {
                  end_text
                  store_id
                  type
                  _id
                }
              }
            }
          `,
          variables: {
            store_id: storeId,
          },
        })
        const content = _.get(query, 'data.product.entities[0]', {})
        if (!response[0].content) {
          response[0].content = {}
        }
        if (_.get(response, '[0].content.end_text') && _.get(content, 'end_text')) {
          response[0].content.end_text = content.end_text
        }
      }
      return response[0]
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_PRODUCTS({ commit, dispatch }, { ids, codes, categoryIds, eans, variantCodes, variantEans, random }) {
    try {
      const isIdFilter = ids && ids.length > 0
      const isCodeFilter = codes && codes.length > 0
      const isCategoryFilter = categoryIds && categoryIds.length > 0
      const isEanFilter = eans && eans.length > 0
      const isVariantCodeFilter = variantCodes && variantCodes.length > 0
      const isVariantEanFilter = variantEans && variantEans.length > 0
      if (
        !isIdFilter &&
        !isCodeFilter &&
        !isCategoryFilter &&
        !isEanFilter &&
        !isVariantCodeFilter &&
        !isVariantEanFilter &&
        !random
      ) {
        return []
      }

      let query
      if (random && categoryIds) {
        query = `categoryIds=${categoryIds.join(',')}&random=${random}`
      } else if (random) {
        query = `random=${random}`
      } else if (isIdFilter) {
        query = `ids=${ids.join(',')}`
      } else if (isCodeFilter) {
        query = `codes=${codes.join(',')}`
      } else if (isEanFilter) {
        query = `eans=${eans.join(',')}`
      } else if (isVariantCodeFilter) {
        query = `variantCodes=${variantCodes.join(',')}`
      } else if (categoryIds) {
        query = `categoryIds=${categoryIds.join(',')}`
      } else {
        query = `variantEans=${variantEans.join(',')}`
      }
      const response = await this.$axios.$get(`${this.$env.CMS_URL}/catalog/product?${query}`)
      return response
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_FEATURED_PRODUCTS({}, { type, size }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            configuration_products_${type} {
              product_reference {
                entity {
                  ... on StoreProduct {
                    data
                  }
                }
              }
            }
          }
        `,
      })
      return query.data[`configuration_products_${type}`] &&
        query.data[`configuration_products_${type}`].product_reference
        ? query.data[`configuration_products_${type}`].product_reference
            .filter(value => value.entity.data)
            .map(value => value.entity.data)
            .filter(value => value.prices)
            .filter(value => value.visibility && value.visibility.catalog)
            .slice(0, size)
        : null
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_PRODUCT_COMMENTS({ commit, dispatch }, { productId }) {
    try {
      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/products/${productId}/comments`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  async GET_PARAMETERS({}, { product }) {
    try {
      const parameters = []
      const parameterIds = []
      const valueIds = []

      // Acquire content ids that will be loaded
      parameters.push(...(product.content.parameters || []))
      product.variants.forEach(variant => {
        if (variant.content && variant.content.parameters) {
          parameters.push(...variant.content.parameters)
        }
      })
      parameters.forEach(parameter => {
        parameterIds.push(parameter.entityId)
        if (parameter.values && Array.isArray(parameter.values)) {
          parameter.values.forEach(valueId => {
            valueIds.push(valueId)
          })
        }
      })

      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query($parameterIds: [String], $valueIds: [String]) {
            virtual_category(filters: { parameters: { entity_id: $parameterIds, values: $valueIds } }) {
              entities {
                title
                page_meta {
                  slug
                }
                parameters {
                  entityId
                  values
                }
              }
            }
            ParameterValues(filters: { _id: $valueIds }) {
              entities {
                _id
                title
                description_short
                description_long
                link_url
                parameter_value_image {
                  alt
                  path
                  title
                }
                hide_product_detail
              }
            }
            parameter(filters: { _id: $parameterIds }) {
              entities {
                _id
                title
                parameter_type
                parameter_unit
                link_to_virtual_category
                description
                parameter_hide_product_detail
              }
            }
          }
        `,
        variables: {
          parameterIds,
          valueIds,
        },
      })

      // Assign entities to parameters and values
      for (let parameter of parameters) {
        parameter.entity = query.data.parameter.entities.filter(p => p._id === parameter.entityId)[0]
        parameter.valueEntities = []
        if (parameter.values && Array.isArray(parameter.values)) {
          parameter.values.forEach(valueId => {
            const value = query.data.ParameterValues.entities.filter(v => v._id === valueId)[0]
            if (value && value.hide_product_detail !== true) {
              // Append virtual category to the value if exists and has only one parameter and value sliced.
              value.virtualCategory = query.data.virtual_category.entities.find(category => {
                const virtualCategoryParameter = category.parameters.find(
                  parameter => parameter.entityId === parameter.entityId,
                )
                return (
                  virtualCategoryParameter &&
                  virtualCategoryParameter.values.length === 1 &&
                  virtualCategoryParameter.values[0] === valueId &&
                  (!virtualCategoryParameter.sliced_categories ||
                    virtualCategoryParameter.sliced_categories.length === 0)
                )
              })
              parameter.valueEntities.push(value)
            }
          })
        }
      }

      // Filter empty parameters
      return parameters.filter(
        p =>
          p.entity &&
          p.valueEntities &&
          ((p.entity.parameter_type[0] === 'number' && p.values > 0) || p.valueEntities.length > 0) &&
          !p.entity.parameter_hide_product_detail,
      )
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_PARAMETERS({ dispatch }, { product }) {
    try {
      product.content.parameters = await dispatch('GET_PARAMETERS', { product })
    } catch (e) {
      console.error(e)
    }
  },
  async ADD_LAST_VISITED({ commit, dispatch }, productId) {
    try {
      commit('ADD_LAST_VISITED_ID', productId)
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_LAST_VISITED({ commit, dispatch, state }) {
    try {
      const data = await dispatch('LOAD_PRODUCTS', { ids: state.lastVisitedIds })
      commit('SET_LAST_VISITED_PRODUCTS', data.filter(value => value.visibility && value.visibility.catalog))
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  lastVisitedIds: [],
  lastVisitedProducts: [],
  pageData: {
    detail: {},
  },
})

export const mutations = {
  ADD_LAST_VISITED_ID(state, productId) {
    _.remove(state.lastVisitedIds, p => p === productId)
    state.lastVisitedIds.unshift(productId)

    // Store up to 16 products
    state.lastVisitedIds.splice(8)
  },
  SET_LAST_VISITED_IDS(state, data) {
    state.lastVisitedIds = data
  },
  SET_LAST_VISITED_PRODUCTS(state, data) {
    state.lastVisitedProducts = data
  },
  SET_PAGE_DATA(state, { page, field, data }) {
    Vue.set(state.pageData[page], field, data)
  },
  RESET_PAGE_DATA(state, { page }) {
    state.pageData[page] = {}
  },
}
