import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Newsletter from '@theme/components/shop/Newsletter'
import NewsletterForm from '@theme/components/shop/NewsletterForm'

export default {
  components: {
    Newsletter,
    NewsletterForm,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'newsletter',
    title: {
      cs: 'Newsletter komponenta',
    },
    fields: [
      {
        name: 'newsletter_type',
        title: {
          cs: '',
        },
        type: 'option',
      },
    ],
  },
  query() {
    return `
      ... on block_newsletter {
        type
        uuid
        newsletter_type
      }
    `
  },
}
