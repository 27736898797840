export const actions = {
  async LOAD_PRODUCTS({ dispatch }, { placement, size, context }) {
    try {
      const recommendations = await dispatch('quarticon/GET_RECOMMENDATION', { placement, context }, { root: true })
      let products = await dispatch(
        'product/LOAD_PRODUCTS',
        { codes: (recommendations || []).map(item => item.code) },
        { root: true },
      )
      if (!Array.isArray(products)) {
        products = []
      }
      products = products.filter(product => product.availability.isAvailable)
      products.forEach(product => {
        const recommendationItem = (recommendations || []).find(
          recommendation => recommendation.code === product?.marketingCode,
        )
        product.recommendation = recommendationItem && recommendationItem.recommendation
      })
      return products.slice(0, size)
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
