import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.CATALOG.PAGE_VIEW, ({ categoryStore, virtualCategoryCms, categoryCms }) => {
  const category = categoryStore || virtualCategoryCms || categoryCms
  window.dataLayer.push({
    category_id: categoryStore ? categoryStore.id : virtualCategoryCms ? virtualCategoryCms._id : undefined,
    category_name: category?.name,
    event: eventDefinitions.CATALOG.PAGE_VIEW,
  })
})
