<script>
import Anchor from '@theme/components/utils/Wysiwyg/mark/Anchor'
import Bold from '@theme/components/utils/Wysiwyg/mark/Bold'
import Em from '@theme/components/utils/Wysiwyg/mark/Em'
import Italic from '@theme/components/utils/Wysiwyg/mark/Italic'
import Link from '@theme/components/utils/Wysiwyg/mark/Link'
import Strike from '@theme/components/utils/Wysiwyg/mark/Strike'
import Subscript from '@theme/components/utils/Wysiwyg/mark/Subscript'
import Superscript from '@theme/components/utils/Wysiwyg/mark/Superscript'
import Underline from '@theme/components/utils/Wysiwyg/mark/Underline'

export default {
  components: {
    anchorMark: Anchor,
    boldMark: Bold,
    emMark: Em,
    italicMark: Italic,
    linkMark: Link,
    strikeMark: Strike,
    subscriptMark: Subscript,
    superscriptMark: Superscript,
    underlineMark: Underline,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  render: function(h) {
    let result = this.$slots.default
    const marks = this.content.marks || []
    marks.forEach(mark => {
      result = h(mark.type + 'Mark', { props: { mark } }, [result])
    })
    return result
  },
}
</script>
