import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.SITE.PAGE_VIEW, ({ page_route_name, page_url }) => {
  window.dataLayer.push({
    event: eventDefinitions.SITE.PAGE_VIEW,
    page_route_name,
    page_url,
  })
})
