import productTransformer from './transformers/product.transformer'

export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.CART.ITEM.REMOVE, ({ item }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    if (item.shoppable && item.product) {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [productTransformer(item, unitsWithTax)],
          },
        },
      })
    }
  })
}
