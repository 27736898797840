export default function variantTransformer(variant) {
  return variant
    ? {
        productDetail_variant_id: variant.id,
        productDetail_variant_name: variant.name,
        productDetail_variant_availability_isAvailable: variant.availability.isAvailable,
        productDetail_variant_availability_code: variant.availability.isAvailable
          ? variant.availability.availabilityCode
          : variant.availability.soldAvailabilityCode,
        productDetail_variant_prices_currencyCode: variant.prices.currencyCode,
        productDetail_variant_prices_unitWithTax: variant.prices.unitWithTax,
        productDetail_variant_prices_unitWithoutTax: variant.prices.unitWithoutTax,
        productDetail_variant_code: variant.marketingCode ? variant.marketingCode : '',
        productDetail_variant_ean: variant.ean ? variant.ean : '',
      }
    : null
}
